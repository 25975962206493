import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ExternalUrlGuard implements CanActivate {
  canActivate({ data }: ActivatedRouteSnapshot): boolean {
    window.location.href = data.externalUrl as string;
    return false;
  }
}
