import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  /** The `Upload` scalar type represents a multipart file upload. */
  Upload: any;
};








export type AccessDeniedError = ServiceErrorInterface & {
  __typename?: 'AccessDeniedError';
  message: Scalars['String'];
  action: Action;
};

export enum Action {
  UPLOAD_BLOCK_MODEL_IMAGE = 'UPLOAD_BLOCK_MODEL_IMAGE',
  VIEW_BLOCK_MODEL_ASSET = 'VIEW_BLOCK_MODEL_ASSET',
  CREATE_BLOCK_MODEL_ASSET = 'CREATE_BLOCK_MODEL_ASSET',
  UPDATE_BLOCK_MODEL_ASSET = 'UPDATE_BLOCK_MODEL_ASSET',
  DELETE_BLOCK_MODEL_ASSET = 'DELETE_BLOCK_MODEL_ASSET',
  VIEW_BLOCK_MODEL_ITEM = 'VIEW_BLOCK_MODEL_ITEM',
  CREATE_BLOCK_MODEL_ITEM = 'CREATE_BLOCK_MODEL_ITEM',
  UPDATE_BLOCK_MODEL_ITEM = 'UPDATE_BLOCK_MODEL_ITEM',
  DELETE_BLOCK_MODEL_ITEM = 'DELETE_BLOCK_MODEL_ITEM',
  VIEW_BLOCK_LAYOUT = 'VIEW_BLOCK_LAYOUT',
  CREATE_BLOCK_LAYOUT = 'CREATE_BLOCK_LAYOUT',
  UPDATE_BLOCK_LAYOUT = 'UPDATE_BLOCK_LAYOUT',
  DELETE_BLOCK_LAYOUT = 'DELETE_BLOCK_LAYOUT',
  VIEW_BLOCK_LAYOUT_PLACEMENT = 'VIEW_BLOCK_LAYOUT_PLACEMENT',
  VIEW_BLOCK_LAYOUT_PLACEMENTS = 'VIEW_BLOCK_LAYOUT_PLACEMENTS',
  CREATE_BLOCK_LAYOUT_PLACEMENT = 'CREATE_BLOCK_LAYOUT_PLACEMENT',
  UPDATE_BLOCK_LAYOUT_PLACEMENT = 'UPDATE_BLOCK_LAYOUT_PLACEMENT',
  DELETE_BLOCK_LAYOUT_PLACEMENT = 'DELETE_BLOCK_LAYOUT_PLACEMENT',
  VIEW_BLOCKS = 'VIEW_BLOCKS',
  CREATE_BLOCK = 'CREATE_BLOCK',
  UPDATE_BLOCK = 'UPDATE_BLOCK',
  DELETE_BLOCK = 'DELETE_BLOCK',
  GENERATE_BLOCKS = 'GENERATE_BLOCKS',
  CREATE_DICTIONARY_VALUE = 'CREATE_DICTIONARY_VALUE',
  UPDATE_DICTIONARY_VALUE = 'UPDATE_DICTIONARY_VALUE',
  DELETE_DICTIONARY_VALUE = 'DELETE_DICTIONARY_VALUE',
  VIEW_PROJECTS = 'VIEW_PROJECTS',
  CREATE_PROJECT = 'CREATE_PROJECT',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',
  VIEW_IMPORT_MAPPING = 'VIEW_IMPORT_MAPPING',
  CREATE_IMPORT_MAPPING = 'CREATE_IMPORT_MAPPING',
  UPDATE_IMPORT_MAPPING = 'UPDATE_IMPORT_MAPPING',
  DELETE_IMPORT_MAPPING = 'DELETE_IMPORT_MAPPING',
  BULK_ENTITY_ID_UPDATE_IMPORT_MAPPING = 'BULK_ENTITY_ID_UPDATE_IMPORT_MAPPING',
  CLEAR_BAD_IMPORT_MAPPINGS = 'CLEAR_BAD_IMPORT_MAPPINGS',
  VIEW_USERS = 'VIEW_USERS',
  CREATE_USER = 'CREATE_USER',
  UPDATE_USER = 'UPDATE_USER',
  DELETE_USER = 'DELETE_USER',
  VIEW_PAGES = 'VIEW_PAGES',
  CREATE_PAGE = 'CREATE_PAGE',
  UPDATE_PAGE = 'UPDATE_PAGE',
  DELETE_PAGE = 'DELETE_PAGE',
  VIEW_FAQ_CATEGORIES = 'VIEW_FAQ_CATEGORIES',
  CREATE_FAQ_CATEGORY = 'CREATE_FAQ_CATEGORY',
  UPDATE_FAQ_CATEGORY = 'UPDATE_FAQ_CATEGORY',
  DELETE_FAQ_CATEGORY = 'DELETE_FAQ_CATEGORY',
  VIEW_FAQ = 'VIEW_FAQ',
  CREATE_FAQ = 'CREATE_FAQ',
  UPDATE_FAQ = 'UPDATE_FAQ',
  DELETE_FAQ = 'DELETE_FAQ',
  VIEW_COUNTRIES = 'VIEW_COUNTRIES',
  CREATE_COUNTRY = 'CREATE_COUNTRY',
  UPDATE_COUNTRY = 'UPDATE_COUNTRY',
  DELETE_COUNTRY = 'DELETE_COUNTRY',
  VIEW_MAKES = 'VIEW_MAKES',
  CREATE_MAKE = 'CREATE_MAKE',
  UPDATE_MAKE = 'UPDATE_MAKE',
  DELETE_MAKE = 'DELETE_MAKE',
  MERGE_MAKES = 'MERGE_MAKES',
  VIEW_MODELS = 'VIEW_MODELS',
  CREATE_MODEL = 'CREATE_MODEL',
  UPDATE_MODEL = 'UPDATE_MODEL',
  DELETE_MODEL = 'DELETE_MODEL',
  MERGE_MODELS = 'MERGE_MODELS',
  VIEW_MODIFICATIONS = 'VIEW_MODIFICATIONS',
  CREATE_MODIFICATION = 'CREATE_MODIFICATION',
  UPDATE_MODIFICATION = 'UPDATE_MODIFICATION',
  DELETE_MODIFICATION = 'DELETE_MODIFICATION',
  MERGE_MODIFICATIONS = 'MERGE_MODIFICATIONS',
  VIEW_CHASSIS = 'VIEW_CHASSIS',
  CREATE_CHASSIS = 'CREATE_CHASSIS',
  UPDATE_CHASSIS = 'UPDATE_CHASSIS',
  DELETE_CHASSIS = 'DELETE_CHASSIS',
  MERGE_CHASSIS = 'MERGE_CHASSIS',
  VIEW_LOCAL_INFO = 'VIEW_LOCAL_INFO',
  CREATE_LOCAL_INFO = 'CREATE_LOCAL_INFO',
  UPDATE_LOCAL_INFO = 'UPDATE_LOCAL_INFO',
  DELETE_LOCAL_INFO = 'DELETE_LOCAL_INFO',
  CREATE_BID = 'CREATE_BID',
  UPDATE_BID = 'UPDATE_BID',
  VIEW_BID = 'VIEW_BID',
  CREATE_OFFER_TRANSLATION = 'CREATE_OFFER_TRANSLATION',
  UPDATE_OFFER_TRANSLATION = 'UPDATE_OFFER_TRANSLATION',
  VIEW_OFFER_TRANSLATION = 'VIEW_OFFER_TRANSLATION',
  DELETE_OFFER_TRANSLATION = 'DELETE_OFFER_TRANSLATION',
  /**  Создать проект  */
  CREATE_PROJECTS = 'CREATE_PROJECTS',
  UPDATE_PROJECTS = 'UPDATE_PROJECTS',
  DELETE_PROJECTS = 'DELETE_PROJECTS',
  /**  Создать правило импорта предложений  */
  VIEW_IMPORT_PRIORITY_RULES = 'VIEW_IMPORT_PRIORITY_RULES',
  CREATE_IMPORT_PRIORITY_RULE = 'CREATE_IMPORT_PRIORITY_RULE',
  UPDATE_IMPORT_PRIORITY_RULE = 'UPDATE_IMPORT_PRIORITY_RULE',
  DELETE_IMPORT_PRIORITY_RULE = 'DELETE_IMPORT_PRIORITY_RULE',
  /**  Просмотр источников импорта  */
  VIEW_IMPORT_RESOURCES = 'VIEW_IMPORT_RESOURCES'
}

export enum ActionEntityType {
  BLOCK_MODEL_ASSET = 'BLOCK_MODEL_ASSET',
  BLOCK_MODEL_ITEM = 'BLOCK_MODEL_ITEM',
  BLOCK_LAYOUT = 'BLOCK_LAYOUT',
  BLOCK_LAYOUT_PLACEMENT = 'BLOCK_LAYOUT_PLACEMENT',
  BLOCK = 'BLOCK',
  DICTIONARY_VALUE = 'DICTIONARY_VALUE',
  PAGE = 'PAGE',
  FAQ_CATEGORY = 'FAQ_CATEGORY',
  FAQ = 'FAQ',
  COUNTRY = 'COUNTRY',
  MAKE = 'MAKE',
  MODEL = 'MODEL',
  MODIFICATION = 'MODIFICATION',
  CHASSIS = 'CHASSIS',
  LOCAL_INFO = 'LOCAL_INFO',
  USER = 'USER',
  IMPORT_MAPPING = 'IMPORT_MAPPING',
  BID = 'BID',
  PROJECT = 'PROJECT',
  OFFER_TRANSLATION = 'OFFER_TRANSLATION',
  IMPORT_PRIORITY_RULE = 'IMPORT_PRIORITY_RULE'
}

export type ActionIsImpossibleError = ServiceErrorInterface & {
  __typename?: 'ActionIsImpossibleError';
  message: Scalars['String'];
  id: Scalars['String'];
};

export type AddUserFromLegacyInput = {
  user: LegacyUserData;
};

export type AddUserFromLegacyOutput = AddUserFromLegacyResult | AccessDeniedError | ValidationErrors | AlreadyExistsError | ServiceError;

export type AddUserFromLegacyResult = {
  __typename?: 'AddUserFromLegacyResult';
  userId: Scalars['Int'];
};

export type AlreadyExistsError = ServiceErrorInterface & {
  __typename?: 'AlreadyExistsError';
  message: Scalars['String'];
  entity: Scalars['String'];
};

export type AuctionsDate = {
  __typename?: 'AuctionsDate';
  auctionAt: Scalars['Time'];
};

export type AuctionsMonth = {
  __typename?: 'AuctionsMonth';
  auctionAt: Scalars['String'];
};

export type Bid = {
  __typename?: 'Bid';
  bidRequest: Scalars['Int'];
  id: Scalars['String'];
  userId: Scalars['Int'];
  offerId: Scalars['String'];
  currency: Currency;
  typeId: Scalars['Int'];
  text: Scalars['String'];
  groupId: Scalars['Int'];
  isAgree: Scalars['Boolean'];
  offer?: Maybe<BidOfferResult>;
  type: PropertyDictionary;
  group: PropertyDictionary;
  createdAt: Scalars['Time'];
  user: User;
  endPrice: Scalars['Int'];
  cvrPrice?: Maybe<Scalars['Int']>;
  auctionAt: Scalars['Time'];
  auctionId: Scalars['Int'];
  status: BidStatus;
  isAuctionOver: Scalars['Boolean'];
  isConfirmed: Scalars['Boolean'];
  isCanceledByUser: Scalars['Boolean'];
  isCanceledByManager: Scalars['Boolean'];
  isWin: Scalars['Boolean'];
  soldStatus?: Maybe<SoldStatus>;
};

export type BidFilter = {
  __typename?: 'BidFilter';
  auctionDates: Array<BidFilterAuctionDates>;
  auctionNames: Array<BidFilterAuctionNames>;
  userNames: Array<BidFilterUserNames>;
  carModels: Array<BidFilterCarModels>;
};

export type BidFilterAuctionDates = {
  __typename?: 'BidFilterAuctionDates';
  auctionAt: Scalars['Time'];
  count: Scalars['Int'];
};

export type BidFilterAuctionNames = {
  __typename?: 'BidFilterAuctionNames';
  auctionId: Scalars['Int'];
  auctionName: Scalars['String'];
  count: Scalars['Int'];
};

export type BidFilterCarModels = {
  __typename?: 'BidFilterCarModels';
  modelId: Scalars['Int'];
  modelName: Scalars['String'];
  count: Scalars['Int'];
};

export type BidFilterUserNames = {
  __typename?: 'BidFilterUserNames';
  userId: Scalars['Int'];
  userName: Scalars['String'];
  userEmail: Scalars['String'];
  count: Scalars['Int'];
};

export type BidGroup = {
  __typename?: 'BidGroup';
  id: Scalars['Int'];
  bidGroupName: Scalars['String'];
};

export enum BidGroupField {
  TITLE = 'TITLE'
}

export type BidGroupTranslation = {
  __typename?: 'BidGroupTranslation';
  locale: Locale;
  field: BidGroupField;
  other: Scalars['String'];
};

export type BidOfferResult = OfferAuction | OfferNotFound;

export enum BidStatus {
  NEW = 'NEW',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  WIN = 'WIN',
  CANCELED_BY_MANAGER = 'CANCELED_BY_MANAGER'
}

export type BidType = {
  __typename?: 'BidType';
  id: Scalars['Int'];
  bidTypeName: Scalars['String'];
};

export enum BidTypeField {
  TITLE = 'TITLE'
}

export type BidTypeTranslation = {
  __typename?: 'BidTypeTranslation';
  locale: Locale;
  field: BidTypeField;
  other: Scalars['String'];
};

export type BidsGroupNotFoundError = ServiceErrorInterface & {
  __typename?: 'BidsGroupNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type BidsOfferNotFoundError = ServiceErrorInterface & {
  __typename?: 'BidsOfferNotFoundError';
  message: Scalars['String'];
  id: Scalars['String'];
};

export type BidsTypeNotFoundError = ServiceErrorInterface & {
  __typename?: 'BidsTypeNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type Block = {
  __typename?: 'Block';
  id: Scalars['Int'];
  type: BlockType;
  view: BlockView;
  name: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
  isActive: Scalars['Boolean'];
  translations: Array<BlockTranslation>;
  options?: Maybe<BlockOptionsOutput>;
};

export type BlockFaq = {
  __typename?: 'BlockFaq';
  name: Scalars['String'];
  view: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
  items: Array<Faq>;
};

export type BlockFastFilters = {
  __typename?: 'BlockFastFilters';
  name: Scalars['String'];
  view: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
  items: Array<BlockPriceLinksItem>;
};

export type BlockFeedbackItemsByLang = {
  __typename?: 'BlockFeedbackItemsByLang';
  en: Array<FeedbackItem>;
  ru: Array<FeedbackItem>;
  de: Array<FeedbackItem>;
};

export type BlockFeedbacks = {
  __typename?: 'BlockFeedbacks';
  name: Scalars['String'];
  view: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
  items: Array<FeedbackItem>;
};

export enum BlockField {
  HEADER = 'HEADER',
  SUBHEADER = 'SUBHEADER'
}

export type BlockFilterByBodyTypes = {
  __typename?: 'BlockFilterByBodyTypes';
  name: Scalars['String'];
  view: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
  items: Array<BlockFilterByBodyTypesItem>;
};

export type BlockFilterByBodyTypesItem = {
  __typename?: 'BlockFilterByBodyTypesItem';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type BlockMakesByCountry = {
  __typename?: 'BlockMakesByCountry';
  name: Scalars['String'];
  view: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
  items: Array<BlockMakesByCountryItem>;
};

export type BlockMakesByCountryItem = {
  __typename?: 'BlockMakesByCountryItem';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  makes?: Maybe<Array<OfferMake>>;
};

export type BlockModel = {
  __typename?: 'BlockModel';
  position: Scalars['Int'];
  make: OfferMake;
  model: OfferModel;
  offersTotal: Scalars['Int'];
  images: BlockModelImages;
  url: Url;
};

export type BlockModelAsset = {
  __typename?: 'BlockModelAsset';
  id: Scalars['Int'];
  make: CarMake;
  model: CarModel;
  bigImage: Scalars['String'];
  middleImage: Scalars['String'];
  smallImage: Scalars['String'];
};

export type BlockModelAssetAlreadyExistsError = ServiceErrorInterface & {
  __typename?: 'BlockModelAssetAlreadyExistsError';
  message: Scalars['String'];
};

export type BlockModelAssetNotFoundError = ServiceErrorInterface & {
  __typename?: 'BlockModelAssetNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

/** The `File` type, represents the response of uploading a file. */
export type BlockModelImage = {
  __typename?: 'BlockModelImage';
  id: Scalars['Int'];
  url: Scalars['String'];
};

export enum BlockModelImageKind {
  BIG = 'BIG',
  MIDDLE = 'MIDDLE',
  SMALL = 'SMALL'
}

export type BlockModelImageNotFoundError = ServiceErrorInterface & {
  __typename?: 'BlockModelImageNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type BlockModelImages = {
  __typename?: 'BlockModelImages';
  big: Scalars['String'];
  middle: Scalars['String'];
  small: Scalars['String'];
};

export type BlockModelItem = {
  __typename?: 'BlockModelItem';
  id: Scalars['Int'];
  blockId: Scalars['Int'];
  asset: BlockModelAsset;
  position: Scalars['Int'];
  offerKind: OfferKind;
};

export type BlockModelItemAlreadyExistsError = ServiceErrorInterface & {
  __typename?: 'BlockModelItemAlreadyExistsError';
  message: Scalars['String'];
};

export type BlockModelItemNotFoundError = ServiceErrorInterface & {
  __typename?: 'BlockModelItemNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type BlockModels = {
  __typename?: 'BlockModels';
  name: Scalars['String'];
  view: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
  items: Array<BlockModel>;
  options: BlockModelsOptions;
};

export type BlockModelsOptions = {
  __typename?: 'BlockModelsOptions';
  shuffleSize: Scalars['Int'];
  limit: Scalars['Int'];
};

export type BlockModelsOptionsInput = {
  shuffleSize: Scalars['Int'];
  limit: Scalars['Int'];
};

export type BlockNotFoundError = ServiceErrorInterface & {
  __typename?: 'BlockNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type BlockOffer = {
  __typename?: 'BlockOffer';
  position: Scalars['Int'];
  make: OfferMake;
  model: OfferModel;
  year: Scalars['Int'];
  image: Scalars['String'];
  imagesTotal: Scalars['Int'];
  url: Url;
  engineVolume: Scalars['Int'];
  mileage: Scalars['Int'];
  power: Scalars['Int'];
  transmission: PropertyDictionary;
  price: OfferPrice;
};

export type BlockOffers = {
  __typename?: 'BlockOffers';
  name: Scalars['String'];
  view: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
  items: Array<BlockOffer>;
  options: BlockOffersOptions;
};

export type BlockOffersOptions = {
  __typename?: 'BlockOffersOptions';
  offerKind: OfferKind;
  makeIDAnyOf?: Maybe<Array<Scalars['Int']>>;
  modelIDAnyOf?: Maybe<Array<Scalars['Int']>>;
  bodyTypeIDAnyOf?: Maybe<Array<Scalars['Int']>>;
  yearMin?: Maybe<Scalars['Int']>;
  yearMax?: Maybe<Scalars['Int']>;
  engineVolumeMin?: Maybe<Scalars['Int']>;
  engineVolumeMax?: Maybe<Scalars['Int']>;
  mileageMin?: Maybe<Scalars['Int']>;
  mileageMax?: Maybe<Scalars['Int']>;
  shuffleSize: Scalars['Int'];
  limit: Scalars['Int'];
};

export type BlockOffersOptionsInput = {
  offerKind: OfferKind;
  makeIDAnyOf?: Maybe<Array<Scalars['Int']>>;
  modelIDAnyOf?: Maybe<Array<Scalars['Int']>>;
  bodyTypeIDAnyOf?: Maybe<Array<Scalars['Int']>>;
  yearMin?: Maybe<Scalars['Int']>;
  yearMax?: Maybe<Scalars['Int']>;
  engineVolumeMin?: Maybe<Scalars['Int']>;
  engineVolumeMax?: Maybe<Scalars['Int']>;
  mileageMin?: Maybe<Scalars['Int']>;
  mileageMax?: Maybe<Scalars['Int']>;
  shuffleSize: Scalars['Int'];
  limit: Scalars['Int'];
};

export type BlockOptionsInput = {
  models?: Maybe<BlockModelsOptionsInput>;
  offers?: Maybe<BlockOffersOptionsInput>;
};

export type BlockOptionsOutput = BlockModelsOptions | BlockOffersOptions;

export type BlockPriceLinksItem = {
  __typename?: 'BlockPriceLinksItem';
  title: Scalars['String'];
  queryParams: Array<QueryParam>;
  currency: Currency;
};

export type BlockStatic = {
  __typename?: 'BlockStatic';
  name: Scalars['String'];
  view: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
};

export type BlockTranslation = {
  __typename?: 'BlockTranslation';
  locale: Locale;
  field: BlockField;
  other: Scalars['String'];
};

export type BlockTranslationInput = {
  locale: Locale;
  field: BlockField;
  other: Scalars['String'];
};

export enum BlockType {
  STATIC = 'STATIC',
  MODELS = 'MODELS',
  OFFERS = 'OFFERS',
  MAKES_BY_COUNTRY = 'MAKES_BY_COUNTRY',
  FAST_FILTERS = 'FAST_FILTERS',
  FILTER_BY_BODY_TYPES = 'FILTER_BY_BODY_TYPES',
  FEEDBACKS = 'FEEDBACKS',
  FAQ = 'FAQ'
}

export type BlockView = {
  __typename?: 'BlockView';
  id: Scalars['Int'];
  type: BlockType;
  view: Scalars['String'];
  displayName: Scalars['String'];
};

export type BlockViewNotFoundError = ServiceErrorInterface & {
  __typename?: 'BlockViewNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type Blocks = BlockMakesByCountry | BlockModels | BlockOffers | BlockStatic | BlockFastFilters | BlockFilterByBodyTypes | BlockFeedbacks | BlockFaq;

export type BodyType = {
  __typename?: 'BodyType';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  position: Scalars['Int'];
  translations: Array<DictValueTranslation>;
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  title: Scalars['String'];
  url: Url;
};

export type BulkUpdateEntityIdInput = {
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
  entityType: EntityType;
  field: FieldMapping;
};

export type BulkUpdateEntityIdOutput = BulkUpdateEntityIdResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type BulkUpdateEntityIdResult = {
  __typename?: 'BulkUpdateEntityIDResult';
  ok: Scalars['Boolean'];
};

export type BulkUpdateImportMappingsInput = {
  bulkInput: Array<UpdateImportMappingInput>;
};

export type BulkUpdateImportMappingsOutput = BulkUpdateImportMappingsResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type BulkUpdateImportMappingsResult = {
  __typename?: 'BulkUpdateImportMappingsResult';
  ok: Scalars['Boolean'];
};

export type CancelBidByOwnerInput = {
  id: Scalars['String'];
};

export type CancelBidByOwnerOutput = CancelBidByOwnerResult | ValidationErrors | ServiceError | NotFoundError;

export type CancelBidByOwnerResult = {
  __typename?: 'CancelBidByOwnerResult';
  bid: Bid;
};

export type CarCondition = {
  __typename?: 'CarCondition';
  isNonSmokingCar: PropertyBoolean;
  isOneOwner: PropertyBoolean;
  isMaintenanceRecordAvailable: PropertyBoolean;
};

export type CarMake = {
  __typename?: 'CarMake';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type CarModel = {
  __typename?: 'CarModel';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type CheckOfferAuctionDataReadyInput = {
  id: Scalars['String'];
};

export type CheckOfferAuctionDataReadyOutput = OfferAuctionDataReady | NotFoundError | ValidationErrors;

export type CheckUserEventsOutput = CheckUserEventsResult | UserNotFoundError;

export type CheckUserEventsResult = {
  __typename?: 'CheckUserEventsResult';
  events: Array<UserEvents>;
};

export type ClearBadImportMappingsInput = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type ClearBadImportMappingsOutput = ClearBadImportMappingsResult | AccessDeniedError | ServiceError;

export type ClearBadImportMappingsResult = {
  __typename?: 'ClearBadImportMappingsResult';
  ok: Scalars['Boolean'];
};

export type Color = {
  __typename?: 'Color';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  group: ColorGroup;
  position: Scalars['Int'];
  translations: Array<DictValueTranslation>;
};

export type ColorGroup = {
  __typename?: 'ColorGroup';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type ColorGroupNotFoundError = ServiceErrorInterface & {
  __typename?: 'ColorGroupNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type ConvertPriceInput = {
  value: Scalars['Int'];
  from: Currency;
  to: Currency;
};

export type ConvertPriceOutput = {
  __typename?: 'ConvertPriceOutput';
  ok: Scalars['Boolean'];
  value: Scalars['Int'];
};

export enum CountryField {
  TITLE = 'TITLE'
}

export type CountryTranslation = {
  __typename?: 'CountryTranslation';
  locale: Locale;
  field: CountryField;
  other: Scalars['String'];
};

export type CountryTranslationInput = {
  locale: Locale;
  field: CountryField;
  other: Scalars['String'];
};

export type CreateBidInput = {
  offerId: Scalars['String'];
  currency: Currency;
  typeId: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  groupId: Scalars['Int'];
  bidRequest: Scalars['Int'];
  isAgree: Scalars['Boolean'];
};

export type CreateBidOutput = CreateBidResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError | BidsGroupNotFoundError | BidsTypeNotFoundError | BidsOfferNotFoundError;

export type CreateBidResult = {
  __typename?: 'CreateBidResult';
  bid: Bid;
};

export type CreateBlockInput = {
  type: BlockType;
  viewId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  subheader?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  translations?: Maybe<Array<BlockTranslationInput>>;
  options?: Maybe<BlockOptionsInput>;
};

export type CreateBlockLayoutInput = {
  projectId: Scalars['Int'];
  isVisible: Scalars['Boolean'];
  slug: Scalars['String'];
  displayName: Scalars['String'];
};

export type CreateBlockLayoutOutput = CreateBlockLayoutResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type CreateBlockLayoutPlacementInput = {
  layoutId: Scalars['Int'];
  blockId: Scalars['Int'];
  isVisible: Scalars['Boolean'];
  position: Scalars['Int'];
};

export type CreateBlockLayoutPlacementOutput = CreateBlockLayoutPlacementResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type CreateBlockLayoutPlacementResult = {
  __typename?: 'CreateBlockLayoutPlacementResult';
  blockLayoutPlacement: FindBlockLayoutPlacement;
};

export type CreateBlockLayoutResult = {
  __typename?: 'CreateBlockLayoutResult';
  blockLayout: FindBlockLayout;
};

export type CreateBlockModelAssetInput = {
  makeId: Scalars['Int'];
  modelId: Scalars['Int'];
  bigImage: Scalars['String'];
  middleImage: Scalars['String'];
  smallImage: Scalars['String'];
};

export type CreateBlockModelAssetOutput = BlockModelAsset | AccessDeniedError | ValidationErrors | BlockModelImageNotFoundError | BlockModelAssetAlreadyExistsError | MakeNotFoundError | ModelNotFoundError | ServiceError;

export type CreateBlockModelItemInput = {
  blockId: Scalars['Int'];
  assetId: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
  offerKind: OfferKind;
};

export type CreateBlockModelItemOutput = CreateBlockModelItemResult | AccessDeniedError | ValidationErrors | BlockNotFoundError | BlockModelItemAlreadyExistsError | BlockModelAssetNotFoundError | ServiceError;

export type CreateBlockModelItemResult = {
  __typename?: 'CreateBlockModelItemResult';
  item: BlockModelItem;
};

export type CreateBlockOutput = Block | BlockViewNotFoundError | AccessDeniedError | ValidationErrors | ServiceError;

export type CreateBodyTypeInput = {
  title: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type CreateBodyTypeOutput = CreateBodyTypeResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateBodyTypeResult = {
  __typename?: 'CreateBodyTypeResult';
  bodyType: BodyType;
};

export type CreateChassisInput = {
  modelId: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type CreateChassisOutput = CreateChassisResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateChassisResult = {
  __typename?: 'CreateChassisResult';
  chassis: FindChassis;
};

export type CreateColorInput = {
  title: Scalars['String'];
  groupId: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type CreateColorOutput = CreateColorResult | ValidationErrors | AccessDeniedError | ColorGroupNotFoundError | AlreadyExistsError | ServiceError;

export type CreateColorResult = {
  __typename?: 'CreateColorResult';
  color: Color;
};

export type CreateCountryInput = {
  slug: Scalars['String'];
  iso2: Scalars['String'];
  iso3: Scalars['String'];
  iso_numeric: Scalars['String'];
  phone_code: Scalars['String'];
  continent: Scalars['String'];
  capital: Scalars['String'];
  capital_time_zone: Scalars['String'];
  translations: Array<CountryTranslationInput>;
  position?: Maybe<Scalars['Int']>;
  is_active: Scalars['Boolean'];
  is_local_info_active: Scalars['Boolean'];
  title: Scalars['String'];
};

export type CreateCountryOutput = CreateCountryResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateCountryResult = {
  __typename?: 'CreateCountryResult';
  country: ManageCountry;
};

export type CreateDictValueInput = {
  groupName: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type CreateDictValueOutput = CreateDictValueResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateDictValueResult = {
  __typename?: 'CreateDictValueResult';
  value: DictionaryValue;
};

export type CreateFaqCategoryInput = {
  slug: Scalars['String'];
  isActive: Scalars['Boolean'];
  position: Scalars['Int'];
  translations: Array<FaqCategoryTranslationInput>;
};

export type CreateFaqCategoryOutput = CreateFaqCategoryResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateFaqCategoryResult = {
  __typename?: 'CreateFaqCategoryResult';
  faqCategory: FaqCategory;
};

export type CreateFaqInput = {
  categoryId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  inBlock: Scalars['Boolean'];
  position: Scalars['Int'];
  translations: Array<FaqTranslationInput>;
};

export type CreateFaqOutput = CreateFaqResult | ValidationErrors | NotFoundError | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateFaqResult = {
  __typename?: 'CreateFaqResult';
  faq: Faq;
};

export type CreateGearInput = {
  title: Scalars['String'];
  typeId: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type CreateGearOutput = CreateGearResult | ValidationErrors | AccessDeniedError | GearTypeNotFoundError | AlreadyExistsError | ServiceError;

export type CreateGearResult = {
  __typename?: 'CreateGearResult';
  gear: Gear;
};

export type CreateImportMakePriorityInput = {
  makeId: Scalars['Int'];
  resourceId: Scalars['Int'];
  priority: Scalars['Int'];
};

export type CreateImportMakePriorityOutput = CreateImportMakePriorityResult | ValidationErrors | AccessDeniedError | MakeNotFoundError | AlreadyExistsError | ServiceError;

export type CreateImportMakePriorityResult = {
  __typename?: 'CreateImportMakePriorityResult';
  id: Scalars['Int'];
  makeId: Scalars['Int'];
  resourceId: Scalars['Int'];
  priority: Scalars['Int'];
};

export type CreateImportModelPriorityInput = {
  modelId: Scalars['Int'];
  resourceId: Scalars['Int'];
  priority: Scalars['Int'];
};

export type CreateImportModelPriorityOutput = CreateImportModelPriorityResult | ValidationErrors | AccessDeniedError | ModelNotFoundError | AlreadyExistsError | ServiceError;

export type CreateImportModelPriorityResult = {
  __typename?: 'CreateImportModelPriorityResult';
  id: Scalars['Int'];
  modelId: Scalars['Int'];
  resourceId: Scalars['Int'];
  priority: Scalars['Int'];
};

export type CreateMakeInput = {
  slug: Scalars['String'];
  title: Scalars['String'];
  countryId: Scalars['Int'];
};

export type CreateMakeOutput = CreateMakeResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateMakeResult = {
  __typename?: 'CreateMakeResult';
  make: FindMake;
};

export type CreateModelInput = {
  slug: Scalars['String'];
  title: Scalars['String'];
  MakeID: Scalars['Int'];
  Position: Scalars['Int'];
};

export type CreateModelOutput = CreateModelResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateModelResult = {
  __typename?: 'CreateModelResult';
  model: FindModel;
};

export type CreateModificationInput = {
  modelId: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type CreateModificationOutput = CreateModificationResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateModificationResult = {
  __typename?: 'CreateModificationResult';
  modification: FindModification;
};

export type CreateOfferTranslationRequestInput = {
  offerId: Scalars['String'];
};

export type CreateOfferTranslationRequestOutput = CreateOfferTranslationRequestResult | ValidationErrors | ServiceError;

export type CreateOfferTranslationRequestResult = {
  __typename?: 'CreateOfferTranslationRequestResult';
  ok: Scalars['Boolean'];
};

export type CreatePageInput = {
  urlPath: Scalars['String'];
  type: PageType;
  displayName: Scalars['String'];
  canonical: Scalars['String'];
  isActive: Scalars['Boolean'];
  payload: PagePayloadInput;
  translations: Array<PageTranslationInput>;
  parentId: Scalars['Int'];
};

export type CreatePageOutput = CreatePageResult | InvalidUrlError | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreatePageResult = {
  __typename?: 'CreatePageResult';
  page: FindPage;
};

export type CreateProjectInput = {
  projectKey: Scalars['String'];
  displayName: Scalars['String'];
  countries: Array<Scalars['String']>;
  currency: Currency;
  localeId: Scalars['Int'];
};

export type CreateProjectOutput = CreateProjectResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type CreateProjectResult = {
  __typename?: 'CreateProjectResult';
  project: Project;
};

export type CreateRateInput = {
  title: Scalars['String'];
  groupId: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type CreateRateOutput = CreateRateResult | ValidationErrors | AccessDeniedError | RateGroupNotFoundError | AlreadyExistsError | ServiceError;

export type CreateRateResult = {
  __typename?: 'CreateRateResult';
  rate: Rate;
};

export type CreateTransmissionInput = {
  title: Scalars['String'];
  typeId: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type CreateTransmissionOutput = CreateTransmissionResult | ValidationErrors | AccessDeniedError | TransmissionTypeNotFoundError | AlreadyExistsError | ServiceError;

export type CreateTransmissionResult = {
  __typename?: 'CreateTransmissionResult';
  transmission: Transmission;
};

export type CreateUserSavedSearchInput = {
  data: Scalars['String'];
};

export type CreateUserSavedSearchOutput = CreateUserSavedSearchResult | ValidationErrors | ServiceError;

export type CreateUserSavedSearchResult = {
  __typename?: 'CreateUserSavedSearchResult';
  savedSearch: UserSavedSearch;
};

export enum Currency {
  USD = 'USD',
  RUB = 'RUB',
  EUR = 'EUR',
  JPY = 'JPY'
}

export type DeleteBlockInput = {
  id: Scalars['Int'];
};

export type DeleteBlockLayoutInput = {
  id: Scalars['Int'];
};

export type DeleteBlockLayoutOutput = DeleteBlockLayoutResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteBlockLayoutPlacementInput = {
  layoutId: Scalars['Int'];
  blockId: Scalars['Int'];
};

export type DeleteBlockLayoutPlacementOutput = DeleteBlockLayoutPlacementResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteBlockLayoutPlacementResult = {
  __typename?: 'DeleteBlockLayoutPlacementResult';
  ok: Scalars['Boolean'];
};

export type DeleteBlockLayoutResult = {
  __typename?: 'DeleteBlockLayoutResult';
  ok: Scalars['Boolean'];
};

export type DeleteBlockModelAssetInput = {
  id: Scalars['Int'];
};

export type DeleteBlockModelAssetOutput = BlockModelAssetNotFoundError | AccessDeniedError | ValidationErrors | ServiceError;

export type DeleteBlockModelItemInput = {
  id: Scalars['Int'];
};

export type DeleteBlockModelItemOutput = DeleteBlockModelItemResult | AccessDeniedError | ValidationErrors | BlockModelItemNotFoundError | ServiceError;

export type DeleteBlockModelItemResult = {
  __typename?: 'DeleteBlockModelItemResult';
  ok: Scalars['Boolean'];
};

export type DeleteBlockOutput = BlockNotFoundError | AccessDeniedError | ValidationErrors | ServiceError;

export type DeleteBodyTypeInput = {
  id: Scalars['Int'];
};

export type DeleteBodyTypeOutput = DeleteBodyTypeResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteBodyTypeResult = {
  __typename?: 'DeleteBodyTypeResult';
  ok: Scalars['Boolean'];
};

export type DeleteChassisInput = {
  id: Scalars['Int'];
};

export type DeleteChassisOutput = DeleteChassisResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteChassisResult = {
  __typename?: 'DeleteChassisResult';
  ok: Scalars['Boolean'];
};

export type DeleteColorInput = {
  id: Scalars['Int'];
};

export type DeleteColorOutput = DeleteColorResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteColorResult = {
  __typename?: 'DeleteColorResult';
  ok: Scalars['Boolean'];
};

export type DeleteCountryInput = {
  id: Scalars['Int'];
};

export type DeleteCountryOutput = CreateCountryResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError | NotFoundError | DeleteCountryResult;

export type DeleteCountryResult = {
  __typename?: 'DeleteCountryResult';
  ok: Scalars['Boolean'];
};

export type DeleteDictValueInput = {
  id: Scalars['Int'];
  groupName: Scalars['String'];
};

export type DeleteDictValueOutput = DeleteDictValueResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteDictValueResult = {
  __typename?: 'DeleteDictValueResult';
  ok: Scalars['Boolean'];
};

export type DeleteFaqCategoryInput = {
  id: Scalars['Int'];
};

export type DeleteFaqCategoryOutput = DeleteFaqCategoryResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteFaqCategoryResult = {
  __typename?: 'DeleteFaqCategoryResult';
  ok: Scalars['Boolean'];
};

export type DeleteFaqInput = {
  id: Scalars['Int'];
};

export type DeleteFaqOutput = DeleteFaqResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteFaqResult = {
  __typename?: 'DeleteFaqResult';
  ok: Scalars['Boolean'];
};

export type DeleteGearInput = {
  id: Scalars['Int'];
};

export type DeleteGearOutput = DeleteGearResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteGearResult = {
  __typename?: 'DeleteGearResult';
  ok: Scalars['Boolean'];
};

export type DeleteImportMakePriorityInput = {
  id: Scalars['Int'];
};

export type DeleteImportMakePriorityOutput = DeleteImportMakePriorityResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteImportMakePriorityResult = {
  __typename?: 'DeleteImportMakePriorityResult';
  ok: Scalars['Boolean'];
};

export type DeleteImportMappingInput = {
  id: Scalars['Int'];
};

export type DeleteImportMappingOutput = DeleteImportMappingResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteImportMappingResult = {
  __typename?: 'DeleteImportMappingResult';
  ok: Scalars['Boolean'];
};

export type DeleteImportModelPriorityInput = {
  id: Scalars['Int'];
};

export type DeleteImportModelPriorityOutput = DeleteImportModelPriorityResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteImportModelPriorityResult = {
  __typename?: 'DeleteImportModelPriorityResult';
  ok: Scalars['Boolean'];
};

export type DeleteMakeInput = {
  id: Scalars['Int'];
};

export type DeleteMakeOutput = DeleteMakeResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteMakeResult = {
  __typename?: 'DeleteMakeResult';
  ok: Scalars['Boolean'];
};

export type DeleteModelInput = {
  id: Scalars['Int'];
  MakeID: Scalars['Int'];
};

export type DeleteModelOutput = DeleteModelResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteModelResult = {
  __typename?: 'DeleteModelResult';
  ok: Scalars['Boolean'];
};

export type DeleteModificationInput = {
  id: Scalars['Int'];
};

export type DeleteModificationOutput = DeleteModificationResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteModificationResult = {
  __typename?: 'DeleteModificationResult';
  ok: Scalars['Boolean'];
};

export type DeletePageInput = {
  id: Scalars['Int'];
};

export type DeletePageOutput = DeletePageResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeletePageResult = {
  __typename?: 'DeletePageResult';
  ok: Scalars['Boolean'];
};

export type DeleteProjectInput = {
  id: Scalars['Int'];
};

export type DeleteProjectOutput = DeleteProjectResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteProjectResult = {
  __typename?: 'DeleteProjectResult';
  ok: Scalars['Boolean'];
};

export type DeleteRateInput = {
  id: Scalars['Int'];
};

export type DeleteRateOutput = DeleteRateResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteRateResult = {
  __typename?: 'DeleteRateResult';
  ok: Scalars['Boolean'];
};

export type DeleteTransmissionInput = {
  id: Scalars['Int'];
};

export type DeleteTransmissionOutput = DeleteTransmissionResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type DeleteTransmissionResult = {
  __typename?: 'DeleteTransmissionResult';
  ok: Scalars['Boolean'];
};

export type DeleteUserSavedSearchInput = {
  id: Scalars['Int'];
};

export type DeleteUserSavedSearchOutput = DeleteUserSavedSearchResult | ValidationErrors | NotFoundError | ServiceError;

export type DeleteUserSavedSearchResult = {
  __typename?: 'DeleteUserSavedSearchResult';
  ok: Scalars['Boolean'];
};

export enum DictValueField {
  TITLE = 'TITLE'
}

export type DictValueTranslation = {
  __typename?: 'DictValueTranslation';
  locale: Locale;
  field: DictValueField;
  other: Scalars['String'];
};

export type DictValueTranslationInput = {
  locale: Locale;
  field: DictValueField;
  other: Scalars['String'];
};

export type Dictionary = {
  __typename?: 'Dictionary';
  groupName: Scalars['String'];
  values?: Maybe<Array<DictionaryValue>>;
};

export type DictionaryGroup = {
  __typename?: 'DictionaryGroup';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type DictionaryValue = {
  __typename?: 'DictionaryValue';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  translations: Array<DictValueTranslation>;
};

export enum EntityType {
  DICTIONARY_VALUE = 'DICTIONARY_VALUE',
  MAKE = 'MAKE',
  MODEL = 'MODEL',
  CHASSIS = 'CHASSIS',
  MODIFICATION = 'MODIFICATION',
  PAGE = 'PAGE',
  BREADCRUMB = 'BREADCRUMB',
  BLOCK = 'BLOCK',
  FAQ_CATEGORY = 'FAQ_CATEGORY',
  FAQ = 'FAQ',
  COUNTRY = 'COUNTRY',
  LOCAL_INFO = 'LOCAL_INFO'
}

export type Equipment = {
  __typename?: 'Equipment';
  hasDriverSeatAirbag: PropertyBoolean;
  hasPassengerSeatAirbag: PropertyBoolean;
  hasSideAirbag: PropertyBoolean;
  hasSunMoonRoof: PropertyBoolean;
  hasABS: PropertyBoolean;
  hasAirConditioner: PropertyBoolean;
  hasDoubleAirConditioner: PropertyBoolean;
  hasLiftUp: PropertyBoolean;
  hasDownhillAssistControl: PropertyBoolean;
  hasPowerSteering: PropertyBoolean;
  hasPowerWindow: PropertyBoolean;
  hasAntiTheftDevice: PropertyBoolean;
  hasIdlingStop: PropertyBoolean;
  hasDriveRecorder: PropertyBoolean;
  hasUSBInputTerminal: PropertyBoolean;
  hasBluetoothConnection: PropertyBoolean;
  has100VPowerSupply: PropertyBoolean;
  hasCleanDiesel: PropertyBoolean;
  hasCenterDifferentialLock: PropertyBoolean;
  hasElectricRetractableMirror: PropertyBoolean;
  rearDoor?: Maybe<PropertyDictionary>;
};

export type EquipmentField = {
  __typename?: 'EquipmentField';
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type Expired = {
  __typename?: 'Expired';
  offers: Array<OfferAuction>;
};

export type Faq = {
  __typename?: 'Faq';
  id: Scalars['Int'];
  categoryId: Scalars['Int'];
  question: Scalars['String'];
  answer: Scalars['String'];
  isActive: Scalars['Boolean'];
  inBlock: Scalars['Boolean'];
  position: Scalars['Int'];
  translations: Array<FaqTranslation>;
};

export type FaqActive = {
  __typename?: 'FaqActive';
  id: Scalars['Int'];
  categoryId: Scalars['Int'];
  question: Scalars['String'];
  answer: Scalars['String'];
  inBlock: Scalars['Boolean'];
};

export type FaqCategory = {
  __typename?: 'FaqCategory';
  id: Scalars['Int'];
  title: Scalars['String'];
  slug: Scalars['String'];
  isActive: Scalars['Boolean'];
  position: Scalars['Int'];
  translations: Array<FaqCategoryTranslation>;
};

export type FaqCategoryActive = {
  __typename?: 'FaqCategoryActive';
  id: Scalars['Int'];
  title: Scalars['String'];
  slug: Scalars['String'];
};

export enum FaqCategoryField {
  TITLE = 'TITLE'
}

export type FaqCategoryTranslation = {
  __typename?: 'FaqCategoryTranslation';
  locale: Locale;
  field: FaqCategoryField;
  other: Scalars['String'];
};

export type FaqCategoryTranslationInput = {
  locale: Locale;
  field: FaqCategoryField;
  other: Scalars['String'];
};

export enum FaqField {
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER'
}

export type FaqTranslation = {
  __typename?: 'FaqTranslation';
  locale: Locale;
  field: FaqField;
  other: Scalars['String'];
};

export type FaqTranslationInput = {
  locale: Locale;
  field: FaqField;
  other: Scalars['String'];
};

export type FeedbackInput = {
  id: Scalars['Int'];
};

export type FeedbackItem = {
  __typename?: 'FeedbackItem';
  id: Scalars['String'];
  locale: Scalars['String'];
  title: Scalars['String'];
  user: Scalars['String'];
  date: Scalars['String'];
  content: Scalars['String'];
  files: Array<Scalars['String']>;
  video: Array<Scalars['String']>;
};

export type FeedbackOutput = FeedbackItem | ValidationErrors | ServiceError;

export type FeedbacksOutput = FeedbacksResult | ValidationErrors | ServiceError;

export type FeedbacksReqInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  sort: Scalars['String'];
  languageCode: Locale;
};

export type FeedbacksResult = {
  __typename?: 'FeedbacksResult';
  total: Scalars['Int'];
  offset: Scalars['Int'];
  limit: Scalars['Int'];
  data: Array<FeedbackItem>;
};

export enum FieldMapping {
  AUCTION = 'AUCTION',
  MAKE = 'MAKE',
  MODEL = 'MODEL',
  CHASSIS = 'CHASSIS',
  COLOR = 'COLOR',
  TRANSMISSION = 'TRANSMISSION',
  GEAR = 'GEAR',
  RATE = 'RATE',
  FUEL = 'FUEL',
  STEERING = 'STEERING',
  LOCATIONS = 'LOCATIONS',
  BODY_TYPE = 'BODY_TYPE',
  MODIFICATION = 'MODIFICATION',
  RATE_EXT_INT = 'RATE_EXT_INT',
  RATE_ENGINE = 'RATE_ENGINE',
  RATE_BODY = 'RATE_BODY',
  DOORS = 'DOORS',
  REAR_DOOR = 'REAR_DOOR',
  OPTION_NAVIGATION = 'OPTION_NAVIGATION',
  OPTION_TV = 'OPTION_TV',
  OPTION_AUDIO = 'OPTION_AUDIO',
  OPTION_VISUAL = 'OPTION_VISUAL'
}

export type FindBidsFilter = {
  auctionAtAnyOf?: Maybe<Array<Scalars['Time']>>;
  auctionIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  userIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  carModelIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  status?: Maybe<FindBidsFilterStatus>;
};

export enum FindBidsFilterStatus {
  ACTIVE = 'ACTIVE',
  TRANSLATE_ONLY = 'TRANSLATE_ONLY',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  WIN = 'WIN'
}

export type FindBidsInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  countTotal?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<FindBidsSort>;
  filter?: Maybe<Array<FindBidsFilter>>;
  buildFilter?: Maybe<Scalars['Boolean']>;
  scope?: Maybe<FindBidsScope>;
};

export type FindBidsOfOwnerInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  countTotal?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<FindBidsFilterStatus>;
  scope?: Maybe<FindBidsScope>;
};

export type FindBidsOfOwnerOutput = FindBidsResult | ValidationErrors;

export type FindBidsOutput = FindBidsResult | ValidationErrors;

export type FindBidsResult = {
  __typename?: 'FindBidsResult';
  bids: Array<Bid>;
  total: Scalars['Int'];
  filter?: Maybe<BidFilter>;
};

export type FindBidsScope = {
  isActive?: Maybe<Scalars['Boolean']>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isCanceledByUser?: Maybe<Scalars['Boolean']>;
  isCanceledByManager?: Maybe<Scalars['Boolean']>;
  isWin?: Maybe<Scalars['Boolean']>;
};

export enum FindBidsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  AUCTION_AT_ASC = 'AUCTION_AT_ASC',
  AUCTION_AT_DESC = 'AUCTION_AT_DESC',
  AUCTION_NAME_ASC = 'AUCTION_NAME_ASC',
  AUCTION_NAME_DESC = 'AUCTION_NAME_DESC',
  GROUP_ASC = 'GROUP_ASC',
  GROUP_DESC = 'GROUP_DESC'
}

export type FindBlockLayout = {
  __typename?: 'FindBlockLayout';
  id: Scalars['Int'];
  projectId: Scalars['Int'];
  isVisible: Scalars['Boolean'];
  slug: Scalars['String'];
  displayName: Scalars['String'];
};

export type FindBlockLayoutPlacement = {
  __typename?: 'FindBlockLayoutPlacement';
  layoutId: Scalars['Int'];
  blockId: Scalars['Int'];
  isVisible: Scalars['Boolean'];
  position: Scalars['Int'];
};

export type FindBlockLayoutPlacementsInput = {
  layoutIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  blockIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  isVisible?: Maybe<Scalars['Boolean']>;
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindBlockLayoutPlacementsSort>;
};

export type FindBlockLayoutPlacementsOutput = FindBlockLayoutPlacementsResult | ValidationErrors | AccessDeniedError;

export type FindBlockLayoutPlacementsResult = {
  __typename?: 'FindBlockLayoutPlacementsResult';
  blockLayoutPlacements: Array<FindBlockLayoutPlacement>;
  total: Scalars['Int'];
};

export enum FindBlockLayoutPlacementsSort {
  LAYOUT_ID_POSITION_ASC = 'LAYOUT_ID_POSITION_ASC',
  LAYOUT_ID_POSITION_DESC = 'LAYOUT_ID_POSITION_DESC'
}

export type FindBlockLayoutsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  projectIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  isVisible?: Maybe<Scalars['Boolean']>;
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindBlockLayoutsSort>;
};

export type FindBlockLayoutsOutput = FindBlockLayoutsResult | ValidationErrors | AccessDeniedError;

export type FindBlockLayoutsResult = {
  __typename?: 'FindBlockLayoutsResult';
  blockLayouts: Array<FindBlockLayout>;
  total: Scalars['Int'];
};

export enum FindBlockLayoutsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  SLUG_ASC = 'SLUG_ASC',
  SLUG_DESC = 'SLUG_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC'
}

export type FindBlockModelAssetsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindBlockModelAssetsSort>;
};

export type FindBlockModelAssetsOutput = FindBlockModelAssetsResult | AccessDeniedError | ValidationErrors | ServiceError;

export type FindBlockModelAssetsResult = {
  __typename?: 'FindBlockModelAssetsResult';
  assets: Array<BlockModelAsset>;
  total: Scalars['Int'];
};

export enum FindBlockModelAssetsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  MAKE_MODEL_ASC = 'MAKE_MODEL_ASC',
  MAKE_MODEL_DESC = 'MAKE_MODEL_DESC'
}

export type FindBlockModelItemsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  blockIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindBlockModelItemsSort>;
};

export type FindBlockModelItemsOutput = FindBlockModelItemsResult | AccessDeniedError | ValidationErrors | ServiceError;

export type FindBlockModelItemsResult = {
  __typename?: 'FindBlockModelItemsResult';
  items: Array<BlockModelItem>;
  total: Scalars['Int'];
};

export enum FindBlockModelItemsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  POSITION_ASC = 'POSITION_ASC',
  POSITION_DESC = 'POSITION_DESC'
}

export enum FindBlockViewsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC'
}

export type FindBlocksInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  nameAnyOf?: Maybe<Array<Scalars['String']>>;
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindBlocksSort>;
};

export type FindBlocksOutput = FindBlocksResult | AccessDeniedError | ValidationErrors | ServiceError;

export type FindBlocksResult = {
  __typename?: 'FindBlocksResult';
  blocks: Array<Block>;
  total: Scalars['Int'];
};

export enum FindBlocksSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  NEXT_REBUILD_AT_ASC = 'NEXT_REBUILD_AT_ASC'
}

export type FindBodyTypesInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindBodyTypesSort>;
};

export type FindBodyTypesOutput = FindBodyTypesResult | ValidationErrors;

export type FindBodyTypesResult = {
  __typename?: 'FindBodyTypesResult';
  bodyTypes: Array<BodyType>;
  total: Scalars['Int'];
};

export enum FindBodyTypesSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC'
}

export type FindChassis = {
  __typename?: 'FindChassis';
  id: Scalars['Int'];
  modelId: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  offersTotal: Scalars['Int'];
};

export type FindChassisInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  modelIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  makeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  modelSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  countTotal?: Maybe<Scalars['Boolean']>;
  activeOffersCountTotalOfKind?: Maybe<OfferKind>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindChassisSort>;
  autocomplete?: Maybe<Scalars['String']>;
};

export type FindChassisOutput = FindChassisResult | ValidationErrors | AccessDeniedError;

export type FindChassisResult = {
  __typename?: 'FindChassisResult';
  chassis: Array<FindChassis>;
  total: Scalars['Int'];
};

export enum FindChassisSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  POSITION_TITLE_ASC = 'POSITION_TITLE_ASC'
}

export type FindColorsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  groupIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  groupSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindColorsSort>;
};

export type FindColorsOutput = FindColorsResult | ValidationErrors;

export type FindColorsResult = {
  __typename?: 'FindColorsResult';
  Colors: Array<Color>;
  total: Scalars['Int'];
};

export enum FindColorsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  GROUP_TITLE_ASC = 'GROUP_TITLE_ASC',
  GROUP_TITLE_DESC = 'GROUP_TITLE_DESC'
}

export type FindCountriesInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindCountriesSort>;
  countTotal?: Maybe<Scalars['Boolean']>;
};

export type FindCountriesOfLocalInfoInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type FindCountriesOfLocalInfoOutput = FindCountriesOfLocalInfoResult | ValidationErrors;

export type FindCountriesOfLocalInfoResult = {
  __typename?: 'FindCountriesOfLocalInfoResult';
  countries: Array<FindCountry>;
  total: Scalars['Int'];
};

export type FindCountriesOutput = FindCountriesResult | ValidationErrors;

export type FindCountriesResult = {
  __typename?: 'FindCountriesResult';
  countries: Array<FindCountry>;
  total: Scalars['Int'];
};

export enum FindCountriesSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC'
}

export type FindCountry = {
  __typename?: 'FindCountry';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  phoneCode: Scalars['String'];
  iso?: Maybe<Scalars['String']>;
};

export type FindDictGroupsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  nameAnyOf?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type FindDictGroupsOutput = FindDictGroupsResult | ValidationErrors;

export type FindDictGroupsResult = {
  __typename?: 'FindDictGroupsResult';
  dictGroups: Array<DictionaryGroup>;
  total: Scalars['Int'];
};

export type FindDictValuesInput = {
  groupName: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  countTotal?: Maybe<Scalars['Boolean']>;
};

export type FindDictValuesOutput = FindDictValuesResult | ValidationErrors;

export type FindDictValuesResult = {
  __typename?: 'FindDictValuesResult';
  dictValues: Array<DictionaryValue>;
  total: Scalars['Int'];
};

export type FindFaqActiveInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindFaqSort>;
};

export type FindFaqActiveOutput = FindFaqActiveResult | ValidationErrors;

export type FindFaqActiveResult = {
  __typename?: 'FindFaqActiveResult';
  faq: Array<FaqActive>;
};

export type FindFaqCategoriesActiveInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindFaqCategoriesSort>;
};

export type FindFaqCategoriesActiveOutput = FindFaqCategoriesActiveResult | ValidationErrors;

export type FindFaqCategoriesActiveResult = {
  __typename?: 'FindFaqCategoriesActiveResult';
  faqCategories: Array<FaqCategoryActive>;
};

export type FindFaqCategoriesInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<FindFaqCategoriesSort>;
};

export type FindFaqCategoriesOutput = FindFaqCategoriesResult | ValidationErrors;

export type FindFaqCategoriesResult = {
  __typename?: 'FindFaqCategoriesResult';
  faqCategories: Array<FaqCategory>;
};

export enum FindFaqCategoriesSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  POSITION_ASC = 'POSITION_ASC',
  POSITION_DESC = 'POSITION_DESC'
}

export type FindFaqInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  categoryIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  isActive?: Maybe<Scalars['Boolean']>;
  inBlock?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<FindFaqSort>;
};

export type FindFaqOutput = FindFaqResult | ValidationErrors;

export type FindFaqResult = {
  __typename?: 'FindFaqResult';
  faq: Array<Faq>;
};

export enum FindFaqSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  POSITION_ASC = 'POSITION_ASC',
  POSITION_DESC = 'POSITION_DESC'
}

export type FindGearsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  typeIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  typeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindGearsSort>;
};

export type FindGearsOutput = FindGearsResult | ValidationErrors;

export type FindGearsResult = {
  __typename?: 'FindGearsResult';
  gears: Array<Gear>;
  total: Scalars['Int'];
};

export enum FindGearsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  TYPE_TITLE_ASC = 'TYPE_TITLE_ASC',
  TYPE_TITLE_DESC = 'TYPE_TITLE_DESC'
}

export type FindImportMappingsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  resourceAnyOf?: Maybe<Array<Resource>>;
  fieldAnyOf?: Maybe<Array<FieldMapping>>;
  entityTypeAnyOf?: Maybe<Array<EntityType>>;
  entityIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  rawValue?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindImportMappingsSort>;
};

export type FindImportMappingsOutput = FindImportMappingsResult | ValidationErrors | AccessDeniedError;

export type FindImportMappingsResult = {
  __typename?: 'FindImportMappingsResult';
  mapping: Array<ImportMapping>;
  total: Scalars['Int'];
};

export enum FindImportMappingsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  RAW_VALUE_ASC = 'RAW_VALUE_ASC',
  RAW_VALUE_DESC = 'RAW_VALUE_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MAKE_TITLE_ASC = 'MAKE_TITLE_ASC',
  MAKE_TITLE_DESC = 'MAKE_TITLE_DESC'
}

export type FindLocalInfoOfCountry = {
  __typename?: 'FindLocalInfoOfCountry';
  id: Scalars['Int'];
  countryName: Scalars['String'];
  restriction: Scalars['String'];
  steeringWheelPosition: Scalars['String'];
  destinationPort: Scalars['String'];
  inspection: Scalars['String'];
  documents: Scalars['String'];
  taxes: Scalars['String'];
  registration: Scalars['String'];
  nb: Scalars['String'];
};

export type FindLocalInfoOfCountryInput = {
  countryId?: Maybe<Scalars['Int']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type FindLocalInfoOfCountryOutput = FindLocalInfoOfCountryResult | ValidationErrors | NotFoundError;

export type FindLocalInfoOfCountryResult = {
  __typename?: 'FindLocalInfoOfCountryResult';
  localInfo: FindLocalInfoOfCountry;
};

export type FindLocale = {
  __typename?: 'FindLocale';
  id: Scalars['Int'];
  name: Locale;
  position: Scalars['Int'];
};

export enum FindLocaleSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  POSITION_ASC = 'POSITION_ASC',
  POSITION_DESC = 'POSITION_DESC'
}

export type FindLocalesInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  sort?: Maybe<FindLocaleSort>;
};

export type FindLocalesOutput = FindLocalesResult | ValidationErrors;

export type FindLocalesResult = {
  __typename?: 'FindLocalesResult';
  locales: Array<FindLocale>;
  total: Scalars['Int'];
};

export type FindMake = {
  __typename?: 'FindMake';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  offersTotal: Scalars['Int'];
  countryId: Scalars['Int'];
  importOptions: MakeImportOptions;
};

export type FindMakesFavoritesInput = {
  projectKey: Scalars['String'];
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type FindMakesFavoritesOutput = FindMakesFavoritesResult | ValidationErrors;

export type FindMakesFavoritesResult = {
  __typename?: 'FindMakesFavoritesResult';
  makes: Array<FindMake>;
  total: Scalars['Int'];
};

export type FindMakesInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  countTotal?: Maybe<Scalars['Boolean']>;
  activeOffersCountTotalOfKind?: Maybe<OfferKind>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindMakesSort>;
};

export type FindMakesOutput = FindMakesResult | ValidationErrors | AccessDeniedError;

export type FindMakesResult = {
  __typename?: 'FindMakesResult';
  makes: Array<FindMake>;
  total: Scalars['Int'];
};

export enum FindMakesSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  POSITION_TITLE_ASC = 'POSITION_TITLE_ASC'
}

export type FindModel = {
  __typename?: 'FindModel';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  offersTotal: Scalars['Int'];
  MakeID: Scalars['Int'];
  Position: Scalars['Int'];
  isImportant: Scalars['Boolean'];
  importOptions: ModelImportOptions;
};

export type FindModelsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  makeIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  makeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  countTotal?: Maybe<Scalars['Boolean']>;
  activeOffersCountTotalOfKind?: Maybe<OfferKind>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindModelsSort>;
  autocomplete?: Maybe<Scalars['String']>;
  isImportant?: Maybe<Scalars['Boolean']>;
};

export type FindModelsOutput = FindModelsResult | ValidationErrors | AccessDeniedError;

export type FindModelsResult = {
  __typename?: 'FindModelsResult';
  models: Array<FindModel>;
  total: Scalars['Int'];
};

export enum FindModelsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  POSITION_TITLE_ASC = 'POSITION_TITLE_ASC',
  POSITION_TITLE_DESC = 'POSITION_TITLE_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC'
}

export type FindModification = {
  __typename?: 'FindModification';
  id: Scalars['Int'];
  modelId: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  offersTotal: Scalars['Int'];
};

export type FindModificationsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  modelIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  makeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  modelSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  countTotal?: Maybe<Scalars['Boolean']>;
  activeOffersCountTotalOfKind?: Maybe<OfferKind>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindModificationsSort>;
  autocomplete?: Maybe<Scalars['String']>;
};

export type FindModificationsOutput = FindModificationsResult | ValidationErrors | AccessDeniedError;

export type FindModificationsResult = {
  __typename?: 'FindModificationsResult';
  modifications: Array<FindModification>;
  total: Scalars['Int'];
};

export enum FindModificationsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  POSITION_TITLE_ASC = 'POSITION_TITLE_ASC'
}

export type FindOffersAuctionsInput = {
  query?: Maybe<Scalars['String']>;
  idAnyOf?: Maybe<Array<Scalars['String']>>;
  statusAnyOf?: Maybe<Array<OfferAuctionStatus>>;
  makeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  modelSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  chassisSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  colorGroupSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  transmissionTypeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  gearTypeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  fuelSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  steeringSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  auctionSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  equipmentSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  bodyTypeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  rateGroupSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  auctionDateAnyOf?: Maybe<Array<Scalars['Time']>>;
  auctionMonthAnyOf?: Maybe<Array<Scalars['String']>>;
  offerTypeAnyOf?: Maybe<Array<OfferTypeAuction>>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  minMileage?: Maybe<Scalars['Int']>;
  maxMileage?: Maybe<Scalars['Int']>;
  minEngineVolume?: Maybe<Scalars['Int']>;
  maxEngineVolume?: Maybe<Scalars['Int']>;
  minSeats?: Maybe<Scalars['Int']>;
  maxSeats?: Maybe<Scalars['Int']>;
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindOffersAuctionsSort>;
  withImages?: Maybe<Scalars['Boolean']>;
  checkDataReady?: Maybe<Scalars['Boolean']>;
};

export type FindOffersAuctionsOutput = FindOffersAuctionsResult | Expired | MovedToStat | NotFoundError | ValidationError;

export type FindOffersAuctionsResult = {
  __typename?: 'FindOffersAuctionsResult';
  offers: Array<OfferAuction>;
  total: Scalars['Int'];
};

export enum FindOffersAuctionsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  AUCTION_AT_ASC = 'AUCTION_AT_ASC',
  AUCTION_AT_DESC = 'AUCTION_AT_DESC',
  RATE_ASC = 'RATE_ASC',
  RATE_DESC = 'RATE_DESC',
  CAR_PRICE_ASC = 'CAR_PRICE_ASC',
  CAR_PRICE_DESC = 'CAR_PRICE_DESC',
  TOTAL_PRICE_ASC = 'TOTAL_PRICE_ASC',
  TOTAL_PRICE_DESC = 'TOTAL_PRICE_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  MILEAGE_ASC = 'MILEAGE_ASC',
  MILEAGE_DESC = 'MILEAGE_DESC'
}

export type FindOffersStocksInput = {
  query?: Maybe<Scalars['String']>;
  idAnyOf?: Maybe<Array<Scalars['String']>>;
  statusAnyOf?: Maybe<Array<OfferStockStatus>>;
  makeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  modelSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  chassisSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  modificationSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  colorGroupSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  transmissionTypeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  gearTypeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  fuelSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  steeringSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  equipmentSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  bodyTypeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  rateExteriorSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  rateInteriorSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  minYear?: Maybe<Scalars['Int']>;
  maxYear?: Maybe<Scalars['Int']>;
  minPrice?: Maybe<Scalars['Int']>;
  maxPrice?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  minMileage?: Maybe<Scalars['Int']>;
  maxMileage?: Maybe<Scalars['Int']>;
  minEngineVolume?: Maybe<Scalars['Int']>;
  maxEngineVolume?: Maybe<Scalars['Int']>;
  minSeats?: Maybe<Scalars['Int']>;
  maxSeats?: Maybe<Scalars['Int']>;
  isCertified?: Maybe<Scalars['Boolean']>;
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindOffersStocksSort>;
  withImages?: Maybe<Scalars['Boolean']>;
};

export type FindOffersStocksOutput = FindOffersStocksResult | NotFoundError | ValidationError;

export type FindOffersStocksResult = {
  __typename?: 'FindOffersStocksResult';
  offers: Array<OfferStock>;
  total: Scalars['Int'];
};

export enum FindOffersStocksSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  CAR_PRICE_ASC = 'CAR_PRICE_ASC',
  CAR_PRICE_DESC = 'CAR_PRICE_DESC',
  TOTAL_PRICE_ASC = 'TOTAL_PRICE_ASC',
  TOTAL_PRICE_DESC = 'TOTAL_PRICE_DESC',
  YEAR_ASC = 'YEAR_ASC',
  YEAR_DESC = 'YEAR_DESC',
  MILEAGE_ASC = 'MILEAGE_ASC',
  MILEAGE_DESC = 'MILEAGE_DESC'
}

export type FindOffersTranslationsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  translatorUserIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  offerID?: Maybe<Scalars['String']>;
  status?: Maybe<OfferTranslationStatus>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindOffersTranslationsSort>;
  createdAtLowerThan?: Maybe<Scalars['Time']>;
  createdAtGreaterThan?: Maybe<Scalars['Time']>;
};

export type FindOffersTranslationsOfOwnerInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  countTotal?: Maybe<Scalars['Boolean']>;
};

export type FindOffersTranslationsOfOwnerOutput = FindOffersTranslationsResult | ValidationErrors;

export type FindOffersTranslationsOutput = FindOffersTranslationsResult | ServiceError | ValidationErrors;

export type FindOffersTranslationsResult = {
  __typename?: 'FindOffersTranslationsResult';
  translations: Array<OfferTranslation>;
  total: Scalars['Int'];
};

export enum FindOffersTranslationsSort {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type FindPage = {
  __typename?: 'FindPage';
  id: Scalars['Int'];
  urlPath: Scalars['String'];
  type: PageType;
  displayName: Scalars['String'];
  canonical: Scalars['String'];
  isActive: Scalars['Boolean'];
  userId: Scalars['Int'];
  payload: PagePayload;
  translations: Array<PageTranslation>;
  parentId: Scalars['Int'];
};

export type FindPagesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindPagesSort>;
};

export type FindPagesOutput = ValidationErrors | PageNotFoundError | InvalidUrlError | FindPagesResult;

export type FindPagesResult = {
  __typename?: 'FindPagesResult';
  pages: Array<FindPage>;
};

export enum FindPagesSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  URL_PATH_ASC = 'URL_PATH_ASC',
  URL_PATH_DESC = 'URL_PATH_DESC'
}

export type FindPort = {
  __typename?: 'FindPort';
  id: Scalars['Int'];
  slug: Scalars['String'];
  country: FindCountry;
  title: Scalars['String'];
  inspection?: Maybe<PortInspection>;
};

export type FindPortsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  countryIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  inspectionAnyOf?: Maybe<Array<PortInspection>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindPortsSort>;
};

export type FindPortsOutput = FindPortsResult | ValidationErrors;

export type FindPortsResult = {
  __typename?: 'FindPortsResult';
  ports: Array<FindPort>;
  total: Scalars['Int'];
};

export enum FindPortsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC'
}

export type FindProjectsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  projectKeyAnyOf?: Maybe<Array<Scalars['String']>>;
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindProjectsSort>;
};

export type FindProjectsOutput = FindProjectsResult | ValidationErrors | AccessDeniedError;

export type FindProjectsResult = {
  __typename?: 'FindProjectsResult';
  projects: Array<Project>;
  total: Scalars['Int'];
};

export enum FindProjectsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  DISPLAY_NAME_ASC = 'DISPLAY_NAME_ASC',
  DISPLAY_NAME_DESC = 'DISPLAY_NAME_DESC'
}

export type FindRatesInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  groupIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  groupSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindRatesSort>;
};

export type FindRatesOutput = FindRatesResult | ValidationErrors;

export type FindRatesResult = {
  __typename?: 'FindRatesResult';
  rates: Array<Rate>;
  total: Scalars['Int'];
};

export enum FindRatesSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  GROUP_TITLE_ASC = 'GROUP_TITLE_ASC',
  GROUP_TITLE_DESC = 'GROUP_TITLE_DESC'
}

export type FindTranslatorsOutput = FindTranslatorsResult | ServiceError;

export type FindTranslatorsResult = {
  __typename?: 'FindTranslatorsResult';
  translators: Array<Translator>;
};

export type FindTransmissionsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  typeIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  typeSlugAnyOf?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindTransmissionsSort>;
};

export type FindTransmissionsOutput = FindTransmissionsResult | ValidationErrors;

export type FindTransmissionsResult = {
  __typename?: 'FindTransmissionsResult';
  transmissions: Array<Transmission>;
  total: Scalars['Int'];
};

export enum FindTransmissionsSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  TYPE_TITLE_ASC = 'TYPE_TITLE_ASC',
  TYPE_TITLE_DESC = 'TYPE_TITLE_DESC'
}

export type FindUserGroup = {
  __typename?: 'FindUserGroup';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export enum FindUserGroupSort {
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC'
}

export type FindUserGroupsInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  slugAnyOf?: Maybe<Array<Scalars['String']>>;
  sort?: Maybe<FindUserGroupSort>;
};

export type FindUserGroupsOutput = FindUserGroupsResult | AccessDeniedError | ValidationErrors;

export type FindUserGroupsResult = {
  __typename?: 'FindUserGroupsResult';
  groups: Array<FindUserGroup>;
  total: Scalars['Int'];
};

export type FindUserSavedSearchesInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type FindUserSavedSearchesOutput = FindUserSavedSearchesResult | ValidationErrors;

export type FindUserSavedSearchesResult = {
  __typename?: 'FindUserSavedSearchesResult';
  savedSearches: Array<UserSavedSearch>;
  total: Scalars['Int'];
};

export type FreeInquiryInput = {
  userId: Scalars['Int'];
  question: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  countryId: Scalars['Int'];
  utm: Scalars['String'];
};

export type FreeInquiryOutput = FreeInquiryResult | ValidationErrors | ServiceError | NotFoundError;

export type FreeInquiryResult = {
  __typename?: 'FreeInquiryResult';
  ok: Scalars['Boolean'];
};

export type Gear = {
  __typename?: 'Gear';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  type: GearType;
  position: Scalars['Int'];
  translations: Array<DictValueTranslation>;
};

export type GearType = {
  __typename?: 'GearType';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type GearTypeNotFoundError = ServiceErrorInterface & {
  __typename?: 'GearTypeNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type GenerateBlocksInput = {
  ok?: Maybe<Scalars['Boolean']>;
};

export type GenerateBlocksOutput = GenerateBlocksResult | AccessDeniedError | ServiceError;

export type GenerateBlocksResult = {
  __typename?: 'GenerateBlocksResult';
  ok: Scalars['Boolean'];
};

export type GetDictionariesInput = {
  groupNameAnyOf: Array<GetDictionariesItemInput>;
};

export type GetDictionariesItemInput = {
  name: Scalars['String'];
  sort: GetDictionariesSort;
};

export type GetDictionariesOutput = GetDictionariesResult | AccessDeniedError | ValidationErrors;

export type GetDictionariesResult = {
  __typename?: 'GetDictionariesResult';
  dictionaries: Array<Dictionary>;
};

export enum GetDictionariesSort {
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC'
}

export type GetEquipmentsFieldsInput = {
  limit?: Maybe<Scalars['Int']>;
};

export type GetEquipmentsFieldsOutput = {
  __typename?: 'GetEquipmentsFieldsOutput';
  fields: Array<EquipmentField>;
};

export type GetImportResourcesOutput = GetImportResourcesResult | ValidationErrors | AccessDeniedError;

export type GetImportResourcesResult = {
  __typename?: 'GetImportResourcesResult';
  resources: Array<ImportResource>;
  total: Scalars['Int'];
};

export type GetLayoutBlock = {
  __typename?: 'GetLayoutBlock';
  slug: Scalars['String'];
  blocks: Array<Blocks>;
};

export type GetLayoutBlocksInput = {
  projectKey: Scalars['String'];
  layoutSlugAnyOf: Array<Scalars['String']>;
};

export type GetLayoutBlocksOutput = {
  __typename?: 'GetLayoutBlocksOutput';
  layouts: Array<GetLayoutBlock>;
};

export type GetNewUserContextInput = {
  userIp?: Maybe<Scalars['String']>;
};

export type GetNewUserContextOutput = {
  __typename?: 'GetNewUserContextOutput';
  ok: Scalars['Boolean'];
  userContext: UserContext;
};

export type GetPageInput = {
  url: Scalars['String'];
};

export type GetPageOutput = ValidationErrors | PageNotFoundError | InvalidUrlError | Page;

export type GetProjectContextInput = {
  projectKey?: Maybe<ProjectKey>;
  userIp?: Maybe<Scalars['String']>;
};

export type GetProjectContextOutput = {
  __typename?: 'GetProjectContextOutput';
  ok: Scalars['Boolean'];
  projectContext: ProjectContext;
};

export type GetStartsAuctionsDatesOutput = {
  __typename?: 'GetStartsAuctionsDatesOutput';
  ok: Scalars['Boolean'];
  dates: Array<AuctionsDate>;
};

export type GetStartsAuctionsStatsDatesOutput = {
  __typename?: 'GetStartsAuctionsStatsDatesOutput';
  ok: Scalars['Boolean'];
  dates: Array<AuctionsDate>;
  months: Array<AuctionsMonth>;
};

export type GetUserBalanceInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type GetUserBalanceOutput = GetUserBalanceResult | ValidationErrors | ServiceError;

export type GetUserBalanceResult = {
  __typename?: 'GetUserBalanceResult';
  items: Array<UserBalanceItem>;
  total: Scalars['Int'];
};

export type GetUserContextOutput = UserContext;

export type GetUserOrdersInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type GetUserOrdersOutput = GetUserOrdersResult | ValidationErrors | ServiceError;

export type GetUserOrdersResult = {
  __typename?: 'GetUserOrdersResult';
  orders: Array<UserOrderItem>;
  total: Scalars['Int'];
};

export type GetUserProfileOutput = GetUserProfileResult | AccessDeniedError | UserNotFoundError | ValidationErrors;

export type GetUserProfileResult = {
  __typename?: 'GetUserProfileResult';
  userProfile: UserProfile;
};

export enum ImageMode {
  CROP = 'CROP',
  RESIZE = 'RESIZE'
}

export type ImportMapping = {
  __typename?: 'ImportMapping';
  id: Scalars['Int'];
  rawValue: Scalars['String'];
  resource: Resource;
  field: FieldMapping;
  entityType: EntityType;
  entitiesIds: Array<Scalars['Int']>;
  entities: Array<ImportMappingEntity>;
  isValid: Scalars['Boolean'];
  createdAt: Scalars['Time'];
};

export type ImportMappingEntity = Transmission | Color | Gear | Rate | ImportMappingEntityNotFoundError;

export type ImportMappingEntityNotFoundError = ServiceErrorInterface & {
  __typename?: 'ImportMappingEntityNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type ImportPriority = {
  __typename?: 'ImportPriority';
  id: Scalars['Int'];
  resource: ImportResource;
  priority: Scalars['Int'];
};

export type ImportResource = {
  __typename?: 'ImportResource';
  id: Scalars['Int'];
  displayName: Scalars['String'];
};

export type InteriorExterior = {
  __typename?: 'InteriorExterior';
  navigation: Array<PropertyDictionary>;
  tv: Array<PropertyDictionary>;
  audio: Array<PropertyDictionary>;
  visual: Array<PropertyDictionary>;
  alloyWheelInch?: Maybe<Scalars['Int']>;
  hasAlloyWheel: PropertyBoolean;
  hasLeatherSeat: PropertyBoolean;
  hasHalfLeatherSeat: PropertyBoolean;
  hasKeyless: PropertyBoolean;
  hasLEDHeadlamp: PropertyBoolean;
  hasHIDXenonLight: PropertyBoolean;
  hasBackCamera: PropertyBoolean;
  hasETC: PropertyBoolean;
  hasETC2: PropertyBoolean;
  hasAero: PropertyBoolean;
  hasSmartKey: PropertyBoolean;
  hasLowdown: PropertyBoolean;
  hasRunFlatTire: PropertyBoolean;
  hasPowerSeats: PropertyBoolean;
  has3RowSeat: PropertyBoolean;
  hasBenchSeat: PropertyBoolean;
  hasFullFlatSheet: PropertyBoolean;
  hasTipUpSeat: PropertyBoolean;
  hasOttoman: PropertyBoolean;
  hasElectricRetractableThirdSeat: PropertyBoolean;
  hasSeatHeater: PropertyBoolean;
  hasWalkThrough: PropertyBoolean;
  hasElectricRearGate: PropertyBoolean;
  hasFrontCamera: PropertyBoolean;
  hasSeatAirConditioner: PropertyBoolean;
  hasAllAroundCamera: PropertyBoolean;
  hasSideCamera: PropertyBoolean;
  hasRoofRail: PropertyBoolean;
  hasAirSuspension: PropertyBoolean;
  hasHeadlightWasher: PropertyBoolean;
};

export type InternalError = ServiceErrorInterface & {
  __typename?: 'InternalError';
  message: Scalars['String'];
};

export type InvalidCredentialsError = ServiceErrorInterface & {
  __typename?: 'InvalidCredentialsError';
  message: Scalars['String'];
};

export type InvalidUrlError = ServiceErrorInterface & {
  __typename?: 'InvalidUrlError';
  message: Scalars['String'];
  url: Scalars['String'];
};

export type ItemPositionInput = {
  id: Scalars['Int'];
  position: Scalars['Int'];
};

export type LegacyUserData = {
  id: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  locale?: Maybe<Locale>;
};

export enum LocalInfoField {
  RESTRICTION = 'RESTRICTION',
  STEERING_WHEEL_POSITION = 'STEERING_WHEEL_POSITION',
  DESTINATION_PORT = 'DESTINATION_PORT',
  INSPECTION = 'INSPECTION',
  DOCUMENTS = 'DOCUMENTS',
  TAXES = 'TAXES',
  REGISTRATION = 'REGISTRATION',
  NB = 'NB'
}

export type LocalInfoTranslation = {
  __typename?: 'LocalInfoTranslation';
  locale: Locale;
  field: LocalInfoField;
  other: Scalars['String'];
};

export type LocalInfoTranslationInput = {
  locale: Locale;
  field: LocalInfoField;
  other: Scalars['String'];
};

export enum Locale {
  EN = 'EN',
  RU = 'RU',
  DE = 'DE'
}

export type LoginInput = {
  login: Scalars['String'];
  password: Scalars['String'];
};

export type LoginOutput = LoginResult | ServiceError | UserNotFoundError | InvalidCredentialsError | ValidationErrors;

export type LoginResult = {
  __typename?: 'LoginResult';
  ok: Scalars['Boolean'];
  userContext: UserContext;
};

export type LogoutOutput = LogoutResult;

export type LogoutResult = {
  __typename?: 'LogoutResult';
  ok: Scalars['Boolean'];
};

export type MakeImportOptions = MakeImportOptionsResult | InternalError | AccessDeniedError;

export type MakeImportOptionsResult = {
  __typename?: 'MakeImportOptionsResult';
  priority: Array<ImportPriority>;
};

export type MakeNotFoundError = ServiceErrorInterface & {
  __typename?: 'MakeNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type ManageBlock = {
  __typename?: 'ManageBlock';
  id: Scalars['Int'];
  typeID: Scalars['Int'];
  viewID: Scalars['Int'];
  name: Scalars['String'];
  header: Scalars['String'];
  subheader: Scalars['String'];
  isActive: Scalars['Boolean'];
  translations: Array<BlockTranslation>;
  options?: Maybe<BlockOptionsOutput>;
};

export type ManageBlocksInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  nameAnyOf?: Maybe<Array<Scalars['String']>>;
  countTotal?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<FindBlocksSort>;
};

export type ManageBlocksOutput = ManageBlocksResult | AccessDeniedError | ValidationErrors | ServiceError;

export type ManageBlocksResult = {
  __typename?: 'ManageBlocksResult';
  blocks: Array<ManageBlock>;
  total: Scalars['Int'];
};

export type ManageCountriesOutput = ManageCountriesResult | ValidationErrors;

export type ManageCountriesResult = {
  __typename?: 'ManageCountriesResult';
  countries: Array<ManageCountry>;
  total: Scalars['Int'];
};

export type ManageCountry = {
  __typename?: 'ManageCountry';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  phoneCode: Scalars['String'];
  iso2: Scalars['String'];
  iso3: Scalars['String'];
  iso_numeric: Scalars['String'];
  continent: Scalars['String'];
  capital: Scalars['String'];
  capital_time_zone: Scalars['String'];
  translations: Array<CountryTranslation>;
  is_active: Scalars['Boolean'];
  is_local_info_active: Scalars['Boolean'];
};

export type ManageLocalInfo = {
  __typename?: 'ManageLocalInfo';
  id: Scalars['Int'];
  countryId: Scalars['Int'];
  countryName: Scalars['String'];
  restriction: Scalars['String'];
  steeringWheelPosition: Scalars['String'];
  destinationPort: Scalars['String'];
  inspection: Scalars['String'];
  documents: Scalars['String'];
  taxes: Scalars['String'];
  registration: Scalars['String'];
  nb: Scalars['String'];
  translations: Array<LocalInfoTranslation>;
};

export type ManageLocalInfoInput = {
  idAnyOf?: Maybe<Array<Scalars['Int']>>;
  countryIdAnyOf?: Maybe<Array<Scalars['Int']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ManageLocalInfoOutput = ManageLocalInfoResult | ValidationErrors;

export type ManageLocalInfoResult = {
  __typename?: 'ManageLocalInfoResult';
  items: Array<ManageLocalInfo>;
};

export type ManageUser = {
  __typename?: 'ManageUser';
  id: Scalars['Int'];
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  locale: Locale;
  isActive: Scalars['Boolean'];
  isUssActive: Scalars['Boolean'];
  isCanBid: Scalars['Boolean'];
  deposit: Scalars['Int'];
  balance: Scalars['Int'];
  groupId: Scalars['Int'];
};

export type ManageUsersFilter = {
  withDeposit?: Maybe<Scalars['Boolean']>;
  userGroup?: Maybe<UserGroup>;
};

export type ManageUsersInput = {
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sort?: Maybe<UsersSort>;
  countTotal?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<ManageUsersFilter>;
};

export type ManageUsersOutput = ManageUsersResult | AccessDeniedError | ValidationErrors;

export type ManageUsersResult = {
  __typename?: 'ManageUsersResult';
  users: Array<ManageUser>;
  total: Scalars['Int'];
};

export type MarkAsReadedOfferTranslationInput = {
  offerIdAnyOf: Array<Scalars['String']>;
};

export type MarkAsReadedOfferTranslationOutput = MarkAsReadedOfferTranslationResult | ValidationErrors | UserNotFoundError;

export type MarkAsReadedOfferTranslationResult = {
  __typename?: 'MarkAsReadedOfferTranslationResult';
  ok: Scalars['Boolean'];
};

export type MergeChassisInput = {
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
};

export type MergeChassisOutput = MergeChassisResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type MergeChassisResult = {
  __typename?: 'MergeChassisResult';
  ok: Scalars['Boolean'];
};

export type MergeMakesInput = {
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
};

export type MergeMakesOutput = MergeMakesResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type MergeMakesResult = {
  __typename?: 'MergeMakesResult';
  ok: Scalars['Boolean'];
};

export type MergeModelsInput = {
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
};

export type MergeModelsOutput = MergeModelsResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type MergeModelsResult = {
  __typename?: 'MergeModelsResult';
  ok: Scalars['Boolean'];
};

export type MergeModificationsInput = {
  fromId: Scalars['Int'];
  toId: Scalars['Int'];
};

export type MergeModificationsOutput = MergeModificationsResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type MergeModificationsResult = {
  __typename?: 'MergeModificationsResult';
  ok: Scalars['Boolean'];
};

export type ModelImportOptions = ModelImportOptionsResult | InternalError | AccessDeniedError;

export type ModelImportOptionsResult = {
  __typename?: 'ModelImportOptionsResult';
  priority: Array<ImportPriority>;
};

export type ModelNotFoundError = ServiceErrorInterface & {
  __typename?: 'ModelNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type MovedToStat = {
  __typename?: 'MovedToStat';
  movedToStat: Scalars['Boolean'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**  конвектор стоимости  */
  convertPrice: ConvertPriceOutput;
  /**  загрузка изображения блока моделей  */
  uploadBlockModelImage: UploadBlockModelOutput;
  /**  создает ассет блока моделей  */
  createBlockModelAsset: CreateBlockModelAssetOutput;
  /**  обновляет ассет блока моделей  */
  updateBlockModelAsset: UpdateBlockModelAssetOutput;
  /**  удаляет ассет блока моделей  */
  deleteBlockModelAsset?: Maybe<DeleteBlockModelAssetOutput>;
  /**  создает новый блок  */
  createBlock: CreateBlockOutput;
  /**  обновляет блок  */
  updateBlock: UpdateBlockOutput;
  /**  удаляет блок  */
  deleteBlock?: Maybe<DeleteBlockOutput>;
  /**  генерирует блоки  */
  generateBlocks?: Maybe<GenerateBlocksOutput>;
  /**  создает новый блок  */
  createBlockLayout: CreateBlockLayoutOutput;
  /**  обновляет блок  */
  updateBlockLayout: UpdateBlockLayoutOutput;
  /**  удаляет блок  */
  deleteBlockLayout?: Maybe<DeleteBlockLayoutOutput>;
  /**  создает новый блок плесмент  */
  createBlockLayoutPlacement: CreateBlockLayoutPlacementOutput;
  /**  обновляет блок плесмент  */
  updateBlockLayoutPlacement: UpdateBlockLayoutPlacementOutput;
  /**  удаляет блок плесмент  */
  deleteBlockLayoutPlacement?: Maybe<DeleteBlockLayoutPlacementOutput>;
  /**  добавляет в блок моделей элемент  */
  createBlockModelItem: CreateBlockModelItemOutput;
  /**  обновление элемента в блоке моделей  */
  updateBlockModelItem: UpdateBlockModelItemOutput;
  /**  удаляет элемент из блока моделей  */
  deleteBlockModelItem?: Maybe<DeleteBlockModelItemOutput>;
  /**  добавляет коробку передач  */
  createTransmission: CreateTransmissionOutput;
  /**  обновление коробки передач  */
  updateTransmission: UpdateTransmissionOutput;
  /**  удаляет экоробку передач  */
  deleteTransmission?: Maybe<DeleteTransmissionOutput>;
  /**  добавляет цвет кузова  */
  createColor: CreateColorOutput;
  /**  обновление цвета кузова  */
  updateColor: UpdateColorOutput;
  /**  удаляет цвет кузова  */
  deleteColor?: Maybe<DeleteColorOutput>;
  /**  добавление привода  */
  createGear: CreateGearOutput;
  /**  обновление привода  */
  updateGear: UpdateGearOutput;
  /**  удаление привода  */
  deleteGear?: Maybe<DeleteGearOutput>;
  /**  добавление рейтинга  */
  createRate: CreateRateOutput;
  /**  обновление рейтинга  */
  updateRate: UpdateRateOutput;
  /**  удаление рейтинга  */
  deleteRate?: Maybe<DeleteRateOutput>;
  /**  добавление значения словаря  */
  createDictValue: CreateDictValueOutput;
  /**  обновление значения словаря  */
  updateDictValue: UpdateDictValueOutput;
  /**  удаление значения словаря  */
  deleteDictValue?: Maybe<DeleteDictValueOutput>;
  /**  обновление маппинга импорта  */
  updateImportMapping: UpdateImportMappingOutput;
  /**  массовое обновление маппинга импорта  */
  bulkUpdateImportMappings: BulkUpdateImportMappingsOutput;
  /**  Удаление всех плохих маппингов  */
  clearBadImportMappings: ClearBadImportMappingsOutput;
  /**  массовое обновление EntityID в маппингах  */
  bulkUpdateEntityID: BulkUpdateEntityIdOutput;
  /**  логин пользователя  */
  login: LoginOutput;
  /**  логаут пользователя  */
  logout: LogoutOutput;
  /**  регистрация пользователя  */
  registerUser: RegisterUserOutput;
  /**  восстановление пароля  */
  restorePassword: RestorePasswordOutput;
  /**  обновление профиля пользователя  */
  updateUserProfile: UpdateUserProfileOutput;
  /**  добавление сохраненного поиска пользователя  */
  createUserSavedSearch: CreateUserSavedSearchOutput;
  /**  удаление сохраненного поиска пользователя  */
  deleteUserSavedSearch: DeleteUserSavedSearchOutput;
  /**  Создать марку  */
  createMake: CreateMakeOutput;
  /**  Обновить марку  */
  updateMake: UpdateMakeOutput;
  /**  Удалить марку  */
  deleteMake: DeleteMakeOutput;
  /** Объединить марки  */
  mergeMakes: MergeMakesOutput;
  /**  Создать модель  */
  createModel: CreateModelOutput;
  /**  Обновить модель  */
  updateModel: UpdateModelOutput;
  /**  Удалить модель  */
  deleteModel: DeleteModelOutput;
  /**  Слить модель  */
  mergeModels: MergeModelsOutput;
  /**  Создать шасси  */
  createChassis: CreateChassisOutput;
  /**  Обновить шасси  */
  updateChassis: UpdateChassisOutput;
  /**  Удалить шасси  */
  deleteChassis: DeleteChassisOutput;
  /**  Слить шасси  */
  mergeChassis: MergeChassisOutput;
  /**  обновление пользователя  */
  updateUser: UpdateUserOutput;
  /**  добавление пользователя по api со старого сайта  */
  addUserFromLegacy: AddUserFromLegacyOutput;
  /**  обновление пользователя по api со старого сайта  */
  updateUserFromLegacy: UpdateUserFromLegacyOutput;
  /**  добавление кузова  */
  createBodyType: CreateBodyTypeOutput;
  /**  обновление кузова  */
  updateBodyType: UpdateBodyTypeOutput;
  /**  удаление кузова  */
  deleteBodyType?: Maybe<DeleteBodyTypeOutput>;
  /**  Создать модификацию  */
  createModification: CreateModificationOutput;
  /**  Обновить модификацию  */
  updateModification: UpdateModificationOutput;
  /**  Удалить модификацию  */
  deleteModification: DeleteModificationOutput;
  /**  Слить модификации  */
  mergeModifications: MergeModificationsOutput;
  /**  Создание проекта  */
  createProject: CreateProjectOutput;
  /**  обновление проекта  */
  updateProject: UpdateProjectOutput;
  /**  удаление проекта  */
  deleteProject: DeleteProjectOutput;
  /**  Произвольный запрос от пользователя  */
  freeInquiry: FreeInquiryOutput;
  /**  Запрос пользователем персонального предложения  */
  personalOfferInquiry: PersonalOfferInquiryOutput;
  /**  Создание страницы  */
  createPage: CreatePageOutput;
  /**  обновление страницы  */
  updatePage: UpdatePageOutput;
  /**  удаление страницы  */
  deletePage: DeletePageOutput;
  /**  Создание категории вопросов  */
  createFaqCategory: CreateFaqCategoryOutput;
  /**  обновление категории вопросов  */
  updateFaqCategory: UpdateFaqCategoryOutput;
  /**  удаление категории вопросов  */
  deleteFaqCategory: DeleteFaqCategoryOutput;
  /**  Сохранение очередности категорий вопросов  */
  setFaqCategoriesPositions: SetFaqCategoriesPositionsOutput;
  /**  Создание вопроса  */
  createFaq: CreateFaqOutput;
  /**  обновление вопроса  */
  updateFaq: UpdateFaqOutput;
  /**  удаление вопроса  */
  deleteFaq: DeleteFaqOutput;
  /**  Сохранение очередности вопросов в категории  */
  setFaqPositions: SetFaqPositionsOutput;
  /**  добавляет страну  */
  createCountry: CreateCountryOutput;
  /**  обновляет страну  */
  updateCountry: UpdateCountryOutput;
  /**  удаляет страну  */
  deleteCountry: DeleteCountryOutput;
  /**  Сохранение local info  */
  saveLocalInfo: SaveLocalInfoOutput;
  /**  добавляет ставку  */
  createBid: CreateBidOutput;
  /**  изменяет ставку  */
  updateBid: UpdateBidOutput;
  /**  Отмена ставки пользователем  */
  cancelBidByOwner: CancelBidByOwnerOutput;
  /**  Запрос перевода оффера  */
  createOfferTranslationRequest: CreateOfferTranslationRequestOutput;
  /**  Изменение перевода оффера  */
  updateOfferTranslation: UpdateOfferTranslationOutput;
  /**  Поставить отметку о прочтении перевода оффера  */
  markAsReadedOfferTranslation: MarkAsReadedOfferTranslationOutput;
  /**  Добавляет приоритет импорта марки  */
  createImportMakePriority: CreateImportMakePriorityOutput;
  /**  Обновляет приоритет импорта марки  */
  updateImportMakePriority: UpdateImportMakePriorityOutput;
  /**  Удоляет приоритет импорта марки  */
  deleteImportMakePriority: DeleteImportMakePriorityOutput;
  /**  Добавляет приоритет импорта модели  */
  createImportModelPriority: CreateImportModelPriorityOutput;
  /**  Обновляет приоритет импорта модели  */
  updateImportModelPriority: UpdateImportModelPriorityOutput;
  /**  Удоляет приоритет импорта модели  */
  deleteImportModelPriority: DeleteImportModelPriorityOutput;
};


export type MutationConvertPriceArgs = {
  input: ConvertPriceInput;
};


export type MutationUploadBlockModelImageArgs = {
  file: UploadBlockModelImage;
};


export type MutationCreateBlockModelAssetArgs = {
  input: CreateBlockModelAssetInput;
};


export type MutationUpdateBlockModelAssetArgs = {
  input: UpdateBlockModelAssetInput;
};


export type MutationDeleteBlockModelAssetArgs = {
  input: DeleteBlockModelAssetInput;
};


export type MutationCreateBlockArgs = {
  input: CreateBlockInput;
};


export type MutationUpdateBlockArgs = {
  input: UpdateBlockInput;
};


export type MutationDeleteBlockArgs = {
  input: DeleteBlockInput;
};


export type MutationGenerateBlocksArgs = {
  input?: Maybe<GenerateBlocksInput>;
};


export type MutationCreateBlockLayoutArgs = {
  input: CreateBlockLayoutInput;
};


export type MutationUpdateBlockLayoutArgs = {
  input: UpdateBlockLayoutInput;
};


export type MutationDeleteBlockLayoutArgs = {
  input: DeleteBlockLayoutInput;
};


export type MutationCreateBlockLayoutPlacementArgs = {
  input: CreateBlockLayoutPlacementInput;
};


export type MutationUpdateBlockLayoutPlacementArgs = {
  input: UpdateBlockLayoutPlacementInput;
};


export type MutationDeleteBlockLayoutPlacementArgs = {
  input: DeleteBlockLayoutPlacementInput;
};


export type MutationCreateBlockModelItemArgs = {
  input: CreateBlockModelItemInput;
};


export type MutationUpdateBlockModelItemArgs = {
  input: UpdateBlockModelItemInput;
};


export type MutationDeleteBlockModelItemArgs = {
  input: DeleteBlockModelItemInput;
};


export type MutationCreateTransmissionArgs = {
  input: CreateTransmissionInput;
};


export type MutationUpdateTransmissionArgs = {
  input: UpdateTransmissionInput;
};


export type MutationDeleteTransmissionArgs = {
  input: DeleteTransmissionInput;
};


export type MutationCreateColorArgs = {
  input: CreateColorInput;
};


export type MutationUpdateColorArgs = {
  input: UpdateColorInput;
};


export type MutationDeleteColorArgs = {
  input: DeleteColorInput;
};


export type MutationCreateGearArgs = {
  input: CreateGearInput;
};


export type MutationUpdateGearArgs = {
  input: UpdateGearInput;
};


export type MutationDeleteGearArgs = {
  input: DeleteGearInput;
};


export type MutationCreateRateArgs = {
  input: CreateRateInput;
};


export type MutationUpdateRateArgs = {
  input: UpdateRateInput;
};


export type MutationDeleteRateArgs = {
  input: DeleteRateInput;
};


export type MutationCreateDictValueArgs = {
  input: CreateDictValueInput;
};


export type MutationUpdateDictValueArgs = {
  input: UpdateDictValueInput;
};


export type MutationDeleteDictValueArgs = {
  input: DeleteDictValueInput;
};


export type MutationUpdateImportMappingArgs = {
  input: UpdateImportMappingInput;
};


export type MutationBulkUpdateImportMappingsArgs = {
  input: BulkUpdateImportMappingsInput;
};


export type MutationClearBadImportMappingsArgs = {
  input: ClearBadImportMappingsInput;
};


export type MutationBulkUpdateEntityIdArgs = {
  input: BulkUpdateEntityIdInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationRestorePasswordArgs = {
  input: RestorePasswordInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationCreateUserSavedSearchArgs = {
  input: CreateUserSavedSearchInput;
};


export type MutationDeleteUserSavedSearchArgs = {
  input: DeleteUserSavedSearchInput;
};


export type MutationCreateMakeArgs = {
  input: CreateMakeInput;
};


export type MutationUpdateMakeArgs = {
  input: UpdateMakeInput;
};


export type MutationDeleteMakeArgs = {
  input: DeleteMakeInput;
};


export type MutationMergeMakesArgs = {
  input: MergeMakesInput;
};


export type MutationCreateModelArgs = {
  input: CreateModelInput;
};


export type MutationUpdateModelArgs = {
  input: UpdateModelInput;
};


export type MutationDeleteModelArgs = {
  input: DeleteModelInput;
};


export type MutationMergeModelsArgs = {
  input: MergeModelsInput;
};


export type MutationCreateChassisArgs = {
  input: CreateChassisInput;
};


export type MutationUpdateChassisArgs = {
  input: UpdateChassisInput;
};


export type MutationDeleteChassisArgs = {
  input: DeleteChassisInput;
};


export type MutationMergeChassisArgs = {
  input: MergeChassisInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationAddUserFromLegacyArgs = {
  input: AddUserFromLegacyInput;
};


export type MutationUpdateUserFromLegacyArgs = {
  input: UpdateUserFromLegacyInput;
};


export type MutationCreateBodyTypeArgs = {
  input: CreateBodyTypeInput;
};


export type MutationUpdateBodyTypeArgs = {
  input: UpdateBodyTypeInput;
};


export type MutationDeleteBodyTypeArgs = {
  input: DeleteBodyTypeInput;
};


export type MutationCreateModificationArgs = {
  input: CreateModificationInput;
};


export type MutationUpdateModificationArgs = {
  input: UpdateModificationInput;
};


export type MutationDeleteModificationArgs = {
  input: DeleteModificationInput;
};


export type MutationMergeModificationsArgs = {
  input: MergeModificationsInput;
};


export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};


export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};


export type MutationFreeInquiryArgs = {
  input: FreeInquiryInput;
};


export type MutationPersonalOfferInquiryArgs = {
  input: PersonalOfferInquiryInput;
};


export type MutationCreatePageArgs = {
  input: CreatePageInput;
};


export type MutationUpdatePageArgs = {
  input: UpdatePageInput;
};


export type MutationDeletePageArgs = {
  input: DeletePageInput;
};


export type MutationCreateFaqCategoryArgs = {
  input: CreateFaqCategoryInput;
};


export type MutationUpdateFaqCategoryArgs = {
  input: UpdateFaqCategoryInput;
};


export type MutationDeleteFaqCategoryArgs = {
  input: DeleteFaqCategoryInput;
};


export type MutationSetFaqCategoriesPositionsArgs = {
  input: SetFaqCategoriesPositionsInput;
};


export type MutationCreateFaqArgs = {
  input: CreateFaqInput;
};


export type MutationUpdateFaqArgs = {
  input: UpdateFaqInput;
};


export type MutationDeleteFaqArgs = {
  input: DeleteFaqInput;
};


export type MutationSetFaqPositionsArgs = {
  input: SetFaqPositionsInput;
};


export type MutationCreateCountryArgs = {
  input: CreateCountryInput;
};


export type MutationUpdateCountryArgs = {
  input: UpdateCountryInput;
};


export type MutationDeleteCountryArgs = {
  input: DeleteCountryInput;
};


export type MutationSaveLocalInfoArgs = {
  input: SaveLocalInfoInput;
};


export type MutationCreateBidArgs = {
  input: CreateBidInput;
};


export type MutationUpdateBidArgs = {
  input: UpdateBidInput;
};


export type MutationCancelBidByOwnerArgs = {
  input: CancelBidByOwnerInput;
};


export type MutationCreateOfferTranslationRequestArgs = {
  input: CreateOfferTranslationRequestInput;
};


export type MutationUpdateOfferTranslationArgs = {
  input: UpdateOfferTranslationInput;
};


export type MutationMarkAsReadedOfferTranslationArgs = {
  input: MarkAsReadedOfferTranslationInput;
};


export type MutationCreateImportMakePriorityArgs = {
  input: CreateImportMakePriorityInput;
};


export type MutationUpdateImportMakePriorityArgs = {
  input: UpdateImportMakePriorityInput;
};


export type MutationDeleteImportMakePriorityArgs = {
  input: DeleteImportMakePriorityInput;
};


export type MutationCreateImportModelPriorityArgs = {
  input: CreateImportModelPriorityInput;
};


export type MutationUpdateImportModelPriorityArgs = {
  input: UpdateImportModelPriorityInput;
};


export type MutationDeleteImportModelPriorityArgs = {
  input: DeleteImportModelPriorityInput;
};

export type NotFoundError = ServiceErrorInterface & {
  __typename?: 'NotFoundError';
  message: Scalars['String'];
  id: Scalars['String'];
};

export type OfferAuction = {
  __typename?: 'OfferAuction';
  id: Scalars['String'];
  kind: OfferKind;
  status: OfferAuctionStatus;
  soldStatus?: Maybe<SoldStatus>;
  make: OfferMake;
  model: OfferModel;
  modification: OfferModification;
  chassis: OfferChassis;
  bodyType: PropertyDictionary;
  year: Scalars['Int'];
  power: Scalars['Int'];
  seats: Scalars['Int'];
  mileage: Scalars['Int'];
  engineVolume: Scalars['Int'];
  transmission: PropertyDictionary;
  transmissionType: PropertyDictionary;
  color: PropertyDictionary;
  fuel: PropertyDictionary;
  steering: PropertyDictionary;
  gear?: Maybe<Array<PropertyDictionary>>;
  images: Array<Scalars['String']>;
  imagesIds: Array<Scalars['String']>;
  imagesTotal: Scalars['Int'];
  auctionListImage: Scalars['String'];
  auctionListImages: Array<Scalars['String']>;
  auctionListImagesIds: Array<Scalars['String']>;
  price: OfferPrice;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
  actions: OfferAuctionAction;
  userHasBid: Scalars['Boolean'];
  translation: OfferAuctionTranslation;
  equip: Scalars['String'];
  serial: Scalars['String'];
  auction: PropertyDictionary;
  auctionStatus: OfferAuctionStatus;
  auctionAt?: Maybe<Scalars['Time']>;
  isAuctionEnded: Scalars['Boolean'];
  lot: Scalars['String'];
  rate: PropertyDictionary;
  startPrice: OfferPrice;
  finishPrice: OfferPrice;
  averagePrice: OfferPrice;
  customFinishPrice: Scalars['Int'];
  isUss: Scalars['Boolean'];
  urlPage: Url;
  dataReady: OfferAuctionDataReady;
};


export type OfferAuctionImagesArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  type?: Maybe<ImageMode>;
  limit?: Maybe<Scalars['Int']>;
};


export type OfferAuctionAuctionListImagesArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  type?: Maybe<ImageMode>;
};


export type OfferAuctionFinishPriceArgs = {
  keepOriginal?: Maybe<Scalars['Boolean']>;
};

export type OfferAuctionAction = {
  __typename?: 'OfferAuctionAction';
  canGetImages: Scalars['Boolean'];
};

export type OfferAuctionDataReady = {
  __typename?: 'OfferAuctionDataReady';
  isDataReady: OfferAuctionDataReadyStatus;
  isImagesReady: OfferAuctionDataReadyStatus;
};

export enum OfferAuctionDataReadyStatus {
  WANT = 'WANT',
  PROCESSING = 'PROCESSING',
  DONE = 'DONE'
}

export enum OfferAuctionStatus {
  PUBLISHED = 'PUBLISHED',
  ARCHIVE = 'ARCHIVE',
  CANCELLED = 'CANCELLED',
  NOT_SOLD = 'NOT_SOLD',
  REMOVED = 'REMOVED',
  SOLD = 'SOLD',
  SOLD_BY_NEGO = 'SOLD_BY_NEGO',
  STATS = 'STATS'
}

export type OfferAuctionTranslation = {
  __typename?: 'OfferAuctionTranslation';
  text: Scalars['String'];
  isReaded: Scalars['Boolean'];
  userHasTranslationRequest: Scalars['Boolean'];
};

export type OfferChassis = {
  __typename?: 'OfferChassis';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type OfferImagesProcessing = {
  __typename?: 'OfferImagesProcessing';
  offerId: Scalars['String'];
};

export type OfferImagesReady = {
  __typename?: 'OfferImagesReady';
  offerId: Scalars['String'];
};

export enum OfferKind {
  AUCTION = 'AUCTION',
  AUCTION_STATS = 'AUCTION_STATS',
  STOCK = 'STOCK'
}

export type OfferLocation = {
  __typename?: 'OfferLocation';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type OfferMake = {
  __typename?: 'OfferMake';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type OfferModel = {
  __typename?: 'OfferModel';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type OfferModification = {
  __typename?: 'OfferModification';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type OfferNotFound = ServiceErrorInterface & {
  __typename?: 'OfferNotFound';
  message: Scalars['String'];
  id: Scalars['String'];
};

export type OfferPrice = {
  __typename?: 'OfferPrice';
  USD: Scalars['Int'];
  RUB: Scalars['Int'];
  EUR: Scalars['Int'];
  JPY: Scalars['Int'];
};

export type OfferStock = {
  __typename?: 'OfferStock';
  id: Scalars['String'];
  kind: OfferKind;
  status: OfferStockStatus;
  make: OfferMake;
  model: OfferModel;
  modification: OfferModification;
  chassis: OfferChassis;
  bodyType: PropertyDictionary;
  year: Scalars['Int'];
  power: Scalars['Int'];
  seats: Scalars['Int'];
  mileage: Scalars['Int'];
  engineVolume: Scalars['Int'];
  transmission: PropertyDictionary;
  transmissionType: PropertyDictionary;
  color: PropertyDictionary;
  fuel: PropertyDictionary;
  steering: PropertyDictionary;
  gear?: Maybe<Array<PropertyDictionary>>;
  images: Array<Scalars['String']>;
  imagesIds: Array<Scalars['String']>;
  imagesTotal: Scalars['Int'];
  price: OfferPrice;
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
  isCertified?: Maybe<Scalars['Boolean']>;
  certifiedImages: Array<Scalars['String']>;
  certifiedImagesIds: Array<Scalars['String']>;
  location: OfferLocation;
  rateExterior: PropertyDictionary;
  rateInterior: PropertyDictionary;
  rateEngine: PropertyDictionary;
  rateBody: PropertyDictionary;
  doors: PropertyDictionary;
  carCondition: CarCondition;
  standardFeatures: StandardFeatures;
  equipment: Equipment;
  interiorExterior: InteriorExterior;
  selfDriving: SelfDriving;
  safetyEquipment: SafetyEquipment;
  urlPage: Url;
};


export type OfferStockImagesArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  type?: Maybe<ImageMode>;
  limit?: Maybe<Scalars['Int']>;
};


export type OfferStockCertifiedImagesArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  type?: Maybe<ImageMode>;
};

export enum OfferStockStatus {
  PROCESSING = 'PROCESSING',
  PUBLISHED = 'PUBLISHED',
  ARCHIVE = 'ARCHIVE'
}

export type OfferTranslation = {
  __typename?: 'OfferTranslation';
  id: Scalars['Int'];
  offerID: Scalars['String'];
  status: OfferTranslationStatus;
  text: Scalars['String'];
  translatorUserID: Scalars['Int'];
  isReaded: Scalars['Boolean'];
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
  offer?: Maybe<TranslationOfferResult>;
  requests?: Maybe<Array<OfferTranslationRequest>>;
};

export type OfferTranslationRequest = {
  __typename?: 'OfferTranslationRequest';
  userID: Scalars['Int'];
  user?: Maybe<User>;
  offerID: Scalars['String'];
  hasBid: Scalars['Boolean'];
  isReaded: Scalars['Boolean'];
  createdAt: Scalars['Time'];
  updatedAt: Scalars['Time'];
};

export enum OfferTranslationStatus {
  NEW = 'NEW',
  DONE = 'DONE'
}

export enum OfferTypeAuction {
  BIDDING = 'BIDDING',
  FIXED_PRICE = 'FIXED_PRICE'
}

export type Page = {
  __typename?: 'Page';
  id: Scalars['Int'];
  type: PageType;
  meta: PageMeta;
  url: Url;
  breadcrumbs: Array<Breadcrumb>;
  payload: PagePayload;
};

export enum PageField {
  TITLE = 'TITLE',
  DESCRIPTION = 'DESCRIPTION',
  KEYWORDS = 'KEYWORDS',
  H1 = 'H1'
}

export type PageMeta = {
  __typename?: 'PageMeta';
  title: Scalars['String'];
  description: Scalars['String'];
  keywords: Scalars['String'];
  h1: Scalars['String'];
  image: Scalars['String'];
  canonical: Scalars['String'];
};

export type PageNotFoundError = ServiceErrorInterface & {
  __typename?: 'PageNotFoundError';
  message: Scalars['String'];
  originalUrl: Scalars['String'];
};

export type PagePayload = PagePayloadDefault | PagePayloadMain | PagePayloadSearchOffers | PagePayloadCardOffer;

export type PagePayloadCardOffer = {
  __typename?: 'PagePayloadCardOffer';
  offerKind: OfferKind;
  offerId: Scalars['String'];
};

export type PagePayloadCardOfferInput = {
  offerKind: OfferKind;
  offerId: Scalars['String'];
};

export type PagePayloadDefault = {
  __typename?: 'PagePayloadDefault';
  stub: Scalars['String'];
};

export type PagePayloadDefaultInput = {
  stub: Scalars['String'];
};

export type PagePayloadInput = {
  default?: Maybe<PagePayloadDefaultInput>;
  main?: Maybe<PagePayloadMainInput>;
  searchOffers?: Maybe<PagePayloadSearchOffersInput>;
  cardOffers?: Maybe<PagePayloadCardOfferInput>;
};

export type PagePayloadMain = {
  __typename?: 'PagePayloadMain';
  stub: Scalars['String'];
};

export type PagePayloadMainInput = {
  stub: Scalars['String'];
};

export type PagePayloadSearchOffers = {
  __typename?: 'PagePayloadSearchOffers';
  offerKind: OfferKind;
  makeSlug: Scalars['String'];
  modelSlug: Scalars['String'];
};

export type PagePayloadSearchOffersInput = {
  offerKind: OfferKind;
  makeSlug: Scalars['String'];
  modelSlug: Scalars['String'];
};

export type PageTranslation = {
  __typename?: 'PageTranslation';
  locale: Locale;
  field: PageField;
  other: Scalars['String'];
};

export type PageTranslationInput = {
  locale: Locale;
  field: PageField;
  other: Scalars['String'];
};

export enum PageType {
  MAIN = 'MAIN',
  SEARCH_OFFERS_STOCK = 'SEARCH_OFFERS_STOCK',
  SEARCH_OFFERS_AUTCIONS = 'SEARCH_OFFERS_AUTCIONS',
  SEARCH_OFFERS_AUTCIONS_STATS = 'SEARCH_OFFERS_AUTCIONS_STATS',
  CARD_OFFER_STOCK = 'CARD_OFFER_STOCK',
  CARD_OFFER_AUCTION = 'CARD_OFFER_AUCTION',
  CARD_OFFER_AUCTION_STATS = 'CARD_OFFER_AUCTION_STATS',
  STATIC = 'STATIC'
}

export type PersonalOfferInquiryInput = {
  userId: Scalars['Int'];
  question: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  utm: Scalars['String'];
  countryId: Scalars['Int'];
  portId: Scalars['Int'];
  offerId: Scalars['String'];
  offerKind: OfferKind;
};

export type PersonalOfferInquiryOutput = PersonalOfferInquiryResult | ValidationErrors | ServiceError | NotFoundError;

export type PersonalOfferInquiryResult = {
  __typename?: 'PersonalOfferInquiryResult';
  ok: Scalars['Boolean'];
};

export enum PortInspection {
  ATJ = 'ATJ',
  DAFF = 'DAFF',
  EAA = 'EAA',
  INTERTEK = 'INTERTEK',
  JAAI = 'JAAI',
  JEVIC = 'JEVIC',
  MAF = 'MAF',
  QISJ = 'QISJ'
}

export type Project = {
  __typename?: 'Project';
  id: Scalars['Int'];
  projectKey: Scalars['String'];
  displayName: Scalars['String'];
  countries: Array<Scalars['String']>;
  currency: Currency;
  localeId: Scalars['Int'];
};

export type ProjectContext = {
  __typename?: 'ProjectContext';
  key: ProjectKey;
  currency: Currency;
  locale: Locale;
};

export enum ProjectKey {
  MAIN = 'MAIN',
  GERMANY = 'GERMANY',
  EUROPA = 'EUROPA',
  RUSSIA = 'RUSSIA',
  RUSSAT = 'RUSSAT',
  BRITAIN = 'BRITAIN',
  AFRICARHD = 'AFRICARHD'
}

export type PropertyBoolean = {
  __typename?: 'PropertyBoolean';
  value?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type PropertyDictionary = {
  __typename?: 'PropertyDictionary';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  findOffersAuctions: FindOffersAuctionsOutput;
  requestOfferAuctionImages: RequestOfferAuctionImagesOutput;
  checkOfferAuctionDataReady: CheckOfferAuctionDataReadyOutput;
  findOffersAuctionsStats: FindOffersAuctionsOutput;
  findOffersStocks: FindOffersStocksOutput;
  findTransmissions: FindTransmissionsOutput;
  findColors: FindColorsOutput;
  findGears: FindGearsOutput;
  findRates: FindRatesOutput;
  findBlockModelAssets: FindBlockModelAssetsOutput;
  findDictGroups: FindDictGroupsOutput;
  findDictValues: FindDictValuesOutput;
  findBlocks: FindBlocksOutput;
  manageBlocks: ManageBlocksOutput;
  findBlockModelItems: FindBlockModelItemsOutput;
  getDictionaries: GetDictionariesOutput;
  getEquipmentsFields: GetEquipmentsFieldsOutput;
  getStartsAuctionsDates: GetStartsAuctionsDatesOutput;
  getStartsAuctionsStatsDates: GetStartsAuctionsStatsDatesOutput;
  getUserContext: GetUserContextOutput;
  getNewUserContext: GetNewUserContextOutput;
  getProjectContext: GetProjectContextOutput;
  getPage: GetPageOutput;
  findPages: FindPagesOutput;
  getLayoutBlocks: GetLayoutBlocksOutput;
  findBlockLayouts: FindBlockLayoutsOutput;
  findBlockLayoutPlacements: FindBlockLayoutPlacementsOutput;
  findCountries: FindCountriesOutput;
  findCountriesOfLocalInfo: FindCountriesOfLocalInfoOutput;
  manageCountries: ManageCountriesOutput;
  findPorts: FindPortsOutput;
  findProjects: FindProjectsOutput;
  findImportMappings: FindImportMappingsOutput;
  findLocales: FindLocalesOutput;
  getUserProfile: GetUserProfileOutput;
  getUserBalance: GetUserBalanceOutput;
  getUserOrders: GetUserOrdersOutput;
  findUserSavedSearches: FindUserSavedSearchesOutput;
  findMakes: FindMakesOutput;
  findMakesFavorites: FindMakesFavoritesOutput;
  findModels: FindModelsOutput;
  findChassis: FindChassisOutput;
  getFeedbacksList: FeedbacksOutput;
  getFeedback: FeedbackOutput;
  findBodyTypes: FindBodyTypesOutput;
  findModifications: FindModificationsOutput;
  findFaqCategories: FindFaqCategoriesOutput;
  findFaqCategoriesActive: FindFaqCategoriesActiveOutput;
  findFaq: FindFaqOutput;
  findFaqActive: FindFaqActiveOutput;
  manageLocalInfo: ManageLocalInfoOutput;
  findLocalInfoOfCountry: FindLocalInfoOfCountryOutput;
  manageUsers: ManageUsersOutput;
  findBids: FindBidsOutput;
  findBidsOfOwner: FindBidsOfOwnerOutput;
  findOffersTranslations: FindOffersTranslationsOutput;
  findOffersTranslationsOfOwner: FindOffersTranslationsOfOwnerOutput;
  findTranslators: FindTranslatorsOutput;
  findUsersGroups: FindUserGroupsOutput;
  /**  Получить список ресурсов  */
  getImportResources: GetImportResourcesOutput;
  checkUserEvents: CheckUserEventsOutput;
};


export type QueryFindOffersAuctionsArgs = {
  input: FindOffersAuctionsInput;
};


export type QueryRequestOfferAuctionImagesArgs = {
  input: RequestOfferAuctionImagesInput;
};


export type QueryCheckOfferAuctionDataReadyArgs = {
  input: CheckOfferAuctionDataReadyInput;
};


export type QueryFindOffersAuctionsStatsArgs = {
  input: FindOffersAuctionsInput;
};


export type QueryFindOffersStocksArgs = {
  input: FindOffersStocksInput;
};


export type QueryFindTransmissionsArgs = {
  input: FindTransmissionsInput;
};


export type QueryFindColorsArgs = {
  input: FindColorsInput;
};


export type QueryFindGearsArgs = {
  input: FindGearsInput;
};


export type QueryFindRatesArgs = {
  input: FindRatesInput;
};


export type QueryFindBlockModelAssetsArgs = {
  input: FindBlockModelAssetsInput;
};


export type QueryFindDictGroupsArgs = {
  input: FindDictGroupsInput;
};


export type QueryFindDictValuesArgs = {
  input: FindDictValuesInput;
};


export type QueryFindBlocksArgs = {
  input: FindBlocksInput;
};


export type QueryManageBlocksArgs = {
  input: ManageBlocksInput;
};


export type QueryFindBlockModelItemsArgs = {
  input: FindBlockModelItemsInput;
};


export type QueryGetDictionariesArgs = {
  input: GetDictionariesInput;
};


export type QueryGetEquipmentsFieldsArgs = {
  input: GetEquipmentsFieldsInput;
};


export type QueryGetNewUserContextArgs = {
  input: GetNewUserContextInput;
};


export type QueryGetProjectContextArgs = {
  input: GetProjectContextInput;
};


export type QueryGetPageArgs = {
  input: GetPageInput;
};


export type QueryFindPagesArgs = {
  input: FindPagesInput;
};


export type QueryGetLayoutBlocksArgs = {
  input: GetLayoutBlocksInput;
};


export type QueryFindBlockLayoutsArgs = {
  input: FindBlockLayoutsInput;
};


export type QueryFindBlockLayoutPlacementsArgs = {
  input: FindBlockLayoutPlacementsInput;
};


export type QueryFindCountriesArgs = {
  input: FindCountriesInput;
};


export type QueryFindCountriesOfLocalInfoArgs = {
  input: FindCountriesOfLocalInfoInput;
};


export type QueryManageCountriesArgs = {
  input: FindCountriesInput;
};


export type QueryFindPortsArgs = {
  input: FindPortsInput;
};


export type QueryFindProjectsArgs = {
  input: FindProjectsInput;
};


export type QueryFindImportMappingsArgs = {
  input: FindImportMappingsInput;
};


export type QueryFindLocalesArgs = {
  input: FindLocalesInput;
};


export type QueryGetUserBalanceArgs = {
  input: GetUserBalanceInput;
};


export type QueryGetUserOrdersArgs = {
  input: GetUserOrdersInput;
};


export type QueryFindUserSavedSearchesArgs = {
  input: FindUserSavedSearchesInput;
};


export type QueryFindMakesArgs = {
  input: FindMakesInput;
};


export type QueryFindMakesFavoritesArgs = {
  input: FindMakesFavoritesInput;
};


export type QueryFindModelsArgs = {
  input: FindModelsInput;
};


export type QueryFindChassisArgs = {
  input: FindChassisInput;
};


export type QueryGetFeedbacksListArgs = {
  input: FeedbacksReqInput;
};


export type QueryGetFeedbackArgs = {
  input: FeedbackInput;
};


export type QueryFindBodyTypesArgs = {
  input: FindBodyTypesInput;
};


export type QueryFindModificationsArgs = {
  input: FindModificationsInput;
};


export type QueryFindFaqCategoriesArgs = {
  input: FindFaqCategoriesInput;
};


export type QueryFindFaqCategoriesActiveArgs = {
  input: FindFaqCategoriesActiveInput;
};


export type QueryFindFaqArgs = {
  input: FindFaqInput;
};


export type QueryFindFaqActiveArgs = {
  input: FindFaqActiveInput;
};


export type QueryManageLocalInfoArgs = {
  input: ManageLocalInfoInput;
};


export type QueryFindLocalInfoOfCountryArgs = {
  input: FindLocalInfoOfCountryInput;
};


export type QueryManageUsersArgs = {
  input: ManageUsersInput;
};


export type QueryFindBidsArgs = {
  input: FindBidsInput;
};


export type QueryFindBidsOfOwnerArgs = {
  input: FindBidsOfOwnerInput;
};


export type QueryFindOffersTranslationsArgs = {
  input: FindOffersTranslationsInput;
};


export type QueryFindOffersTranslationsOfOwnerArgs = {
  input: FindOffersTranslationsOfOwnerInput;
};


export type QueryFindUsersGroupsArgs = {
  input: FindUserGroupsInput;
};

export type QueryParam = {
  __typename?: 'QueryParam';
  param: Scalars['String'];
  value: Scalars['String'];
};

export type Rate = {
  __typename?: 'Rate';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  group: RateGroup;
  position: Scalars['Int'];
  translations: Array<DictValueTranslation>;
};

export type RateGroup = {
  __typename?: 'RateGroup';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type RateGroupNotFoundError = ServiceErrorInterface & {
  __typename?: 'RateGroupNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type RegisterUserData = {
  countryId: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  promoCode?: Maybe<Scalars['String']>;
  hasImportedVehicle?: Maybe<Scalars['Boolean']>;
  agreeReceiveOffers?: Maybe<Scalars['Boolean']>;
  acceptUserAgreement: Scalars['Boolean'];
  locale: Locale;
  utm: Scalars['String'];
};

export type RegisterUserInput = {
  user: RegisterUserData;
  autologin: Scalars['Boolean'];
};

export type RegisterUserOutput = UserContext | AlreadyExistsError | ServiceError | InvalidCredentialsError | ValidationErrors;

export type RequestOfferAuctionImagesInput = {
  offerId: Scalars['String'];
};

export type RequestOfferAuctionImagesOutput = ValidationErrors | OfferNotFound | ActionIsImpossibleError | OfferImagesProcessing | OfferImagesReady | ServiceError | AccessDeniedError;

export enum Resource {
  AVTOJP = 'AVTOJP',
  GNE = 'GNE',
  NINJA = 'NINJA'
}

export type RestorePasswordInput = {
  email: Scalars['String'];
};

export type RestorePasswordOutput = RestorePasswordResult | ServiceError | UserNotFoundError | ValidationErrors;

export type RestorePasswordResult = {
  __typename?: 'RestorePasswordResult';
  ok: Scalars['Boolean'];
};

export type SafetyEquipment = {
  __typename?: 'SafetyEquipment';
  hasElectronicStabilityControl: PropertyBoolean;
  hasCollisionSafetyBody: PropertyBoolean;
  hasCollisionDamageReductionSystem: PropertyBoolean;
  hasClearanceSonar: PropertyBoolean;
  hasAutomaticHighBeam: PropertyBoolean;
  hasAutoLight: PropertyBoolean;
  hasActiveHeadrest: PropertyBoolean;
};

export type SaveLocalInfoInput = {
  countryId: Scalars['Int'];
  translations: Array<LocalInfoTranslationInput>;
};

export type SaveLocalInfoOutput = SaveLocalInfoResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type SaveLocalInfoResult = {
  __typename?: 'SaveLocalInfoResult';
  localInfo: ManageLocalInfo;
};

export type SavedSearchOption = {
  __typename?: 'SavedSearchOption';
  filter: Scalars['String'];
  displayName: Scalars['String'];
};

export type SelfDriving = {
  __typename?: 'SelfDriving';
  hasAutoCruiseControl: PropertyBoolean;
  hasLaneKeepAssist: PropertyBoolean;
  hasAutomaticParkingSystem: PropertyBoolean;
  hasParkAssist: PropertyBoolean;
};

export type ServiceError = ServiceErrorInterface & {
  __typename?: 'ServiceError';
  message: Scalars['String'];
};

export type ServiceErrorInterface = {
  message: Scalars['String'];
};

export type SetFaqCategoriesPositionsInput = {
  positions: Array<ItemPositionInput>;
};

export type SetFaqCategoriesPositionsOutput = SetFaqCategoriesPositionsResult | ValidationErrors | AccessDeniedError | ServiceError;

export type SetFaqCategoriesPositionsResult = {
  __typename?: 'SetFaqCategoriesPositionsResult';
  ok: Scalars['Boolean'];
};

export type SetFaqPositionsInput = {
  categoryId: Scalars['Int'];
  positions: Array<ItemPositionInput>;
};

export type SetFaqPositionsOutput = SetFaqPositionsResult | ValidationErrors | AccessDeniedError | ServiceError;

export type SetFaqPositionsResult = {
  __typename?: 'SetFaqPositionsResult';
  ok: Scalars['Boolean'];
};

export enum SoldStatus {
  SOLD = 'SOLD',
  NOT_SOLD = 'NOT_SOLD',
  SOLD_NEGO = 'SOLD_NEGO',
  SOLD_WIN = 'SOLD_WIN',
  SOLD_NEGO_WIN = 'SOLD_NEGO_WIN'
}

export type StandardFeatures = {
  __typename?: 'StandardFeatures';
  hasTurbocharger: PropertyBoolean;
  hasSupercharger: PropertyBoolean;
  isColdWeatherSpecificationCar: PropertyBoolean;
  isWelfareVehicles: PropertyBoolean;
};


export type TranslationOfferResult = OfferAuction | OfferNotFound;

export type Translator = {
  __typename?: 'Translator';
  userId: Scalars['Int'];
  userName: Scalars['String'];
  userEmail: Scalars['String'];
  count: Scalars['Int'];
};

export type Transmission = {
  __typename?: 'Transmission';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  type: TransmissionType;
  position: Scalars['Int'];
  translations: Array<DictValueTranslation>;
};

export type TransmissionType = {
  __typename?: 'TransmissionType';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type TransmissionTypeNotFoundError = ServiceErrorInterface & {
  __typename?: 'TransmissionTypeNotFoundError';
  message: Scalars['String'];
  id: Scalars['Int'];
};

export type UpdateBidInput = {
  id: Scalars['String'];
  endPrice?: Maybe<Scalars['Int']>;
  cvrPrice?: Maybe<Scalars['Int']>;
  soldStatus?: Maybe<SoldStatus>;
  status?: Maybe<BidStatus>;
  isConfirmed?: Maybe<Scalars['Boolean']>;
  isCanceled?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

export type UpdateBidOutput = UpdateBidResult | ValidationErrors | NotFoundError;

export type UpdateBidResult = {
  __typename?: 'UpdateBidResult';
  bid: Bid;
};

export type UpdateBlockInput = {
  id: Scalars['Int'];
  viewId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  subheader?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  translations?: Maybe<Array<BlockTranslationInput>>;
  options?: Maybe<BlockOptionsInput>;
};

export type UpdateBlockLayoutInput = {
  id: Scalars['Int'];
  projectId: Scalars['Int'];
  isVisible: Scalars['Boolean'];
  slug: Scalars['String'];
  displayName: Scalars['String'];
};

export type UpdateBlockLayoutOutput = UpdateBlockLayoutResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateBlockLayoutPlacementInput = {
  layoutId: Scalars['Int'];
  blockId: Scalars['Int'];
  isVisible?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Int']>;
};

export type UpdateBlockLayoutPlacementOutput = UpdateBlockLayoutPlacementResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type UpdateBlockLayoutPlacementResult = {
  __typename?: 'UpdateBlockLayoutPlacementResult';
  blockLayoutPlacement: FindBlockLayoutPlacement;
};

export type UpdateBlockLayoutResult = {
  __typename?: 'UpdateBlockLayoutResult';
  blockLayout: FindBlockLayout;
};

export type UpdateBlockModelAssetInput = {
  id: Scalars['Int'];
  makeId?: Maybe<Scalars['Int']>;
  modelId?: Maybe<Scalars['Int']>;
  bigImage?: Maybe<Scalars['String']>;
  middleImage?: Maybe<Scalars['String']>;
  smallImage?: Maybe<Scalars['String']>;
};

export type UpdateBlockModelAssetOutput = BlockModelAsset | BlockModelAssetNotFoundError | BlockModelAssetAlreadyExistsError | BlockModelImageNotFoundError | MakeNotFoundError | ModelNotFoundError | AccessDeniedError | ValidationErrors | ServiceError;

export type UpdateBlockModelItemInput = {
  id: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
  offerKind?: Maybe<OfferKind>;
};

export type UpdateBlockModelItemOutput = UpdateBlockModelItemResult | AccessDeniedError | ValidationErrors | BlockModelItemNotFoundError | ServiceError;

export type UpdateBlockModelItemResult = {
  __typename?: 'UpdateBlockModelItemResult';
  item: BlockModelItem;
};

export type UpdateBlockOutput = Block | BlockNotFoundError | BlockViewNotFoundError | AccessDeniedError | ValidationErrors | ServiceError;

export type UpdateBodyTypeInput = {
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type UpdateBodyTypeOutput = UpdateBodyTypeResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateBodyTypeResult = {
  __typename?: 'UpdateBodyTypeResult';
  bodyType: BodyType;
};

export type UpdateChassisInput = {
  id: Scalars['Int'];
  modelId: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateChassisOutput = UpdateChassisResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateChassisResult = {
  __typename?: 'UpdateChassisResult';
  chassis: FindChassis;
};

export type UpdateColorInput = {
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type UpdateColorOutput = UpdateColorResult | ValidationErrors | AccessDeniedError | ColorGroupNotFoundError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateColorResult = {
  __typename?: 'UpdateColorResult';
  color: Color;
};

export type UpdateCountryInput = {
  id: Scalars['Int'];
  slug?: Maybe<Scalars['String']>;
  iso2?: Maybe<Scalars['String']>;
  iso3?: Maybe<Scalars['String']>;
  iso_numeric?: Maybe<Scalars['String']>;
  phone_code?: Maybe<Scalars['String']>;
  continent?: Maybe<Scalars['String']>;
  capital?: Maybe<Scalars['String']>;
  capital_time_zone?: Maybe<Scalars['String']>;
  translations?: Maybe<Array<CountryTranslationInput>>;
  position?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_local_info_active?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type UpdateCountryOutput = UpdateCountryResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | ServiceError;

export type UpdateCountryResult = {
  __typename?: 'UpdateCountryResult';
  country: ManageCountry;
};

export type UpdateDictValueInput = {
  id: Scalars['Int'];
  groupName: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type UpdateDictValueOutput = UpdateDictValueResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateDictValueResult = {
  __typename?: 'UpdateDictValueResult';
  value: DictionaryValue;
};

export type UpdateFaqCategoryInput = {
  id: Scalars['Int'];
  slug: Scalars['String'];
  isActive: Scalars['Boolean'];
  position: Scalars['Int'];
  translations: Array<FaqCategoryTranslationInput>;
};

export type UpdateFaqCategoryOutput = UpdateFaqCategoryResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateFaqCategoryResult = {
  __typename?: 'UpdateFaqCategoryResult';
  faqCategory: FaqCategory;
};

export type UpdateFaqInput = {
  id: Scalars['Int'];
  categoryId: Scalars['Int'];
  isActive: Scalars['Boolean'];
  inBlock: Scalars['Boolean'];
  position: Scalars['Int'];
  translations: Array<FaqTranslationInput>;
};

export type UpdateFaqOutput = UpdateFaqResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateFaqResult = {
  __typename?: 'UpdateFaqResult';
  faq: Faq;
};

export type UpdateGearInput = {
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type UpdateGearOutput = UpdateGearResult | ValidationErrors | AccessDeniedError | GearTypeNotFoundError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateGearResult = {
  __typename?: 'UpdateGearResult';
  gear: Gear;
};

export type UpdateImportMakePriorityInput = {
  id: Scalars['Int'];
  makeId: Scalars['Int'];
  resourceId: Scalars['Int'];
  priority: Scalars['Int'];
};

export type UpdateImportMakePriorityOutput = UpdateImportMakePriorityResult | ValidationErrors | AccessDeniedError | NotFoundError | MakeNotFoundError | AlreadyExistsError | ServiceError;

export type UpdateImportMakePriorityResult = {
  __typename?: 'UpdateImportMakePriorityResult';
  id: Scalars['Int'];
  makeId: Scalars['Int'];
  resourceId: Scalars['Int'];
  priority: Scalars['Int'];
};

export type UpdateImportMappingInput = {
  id: Scalars['Int'];
  entitiesIds?: Maybe<Array<Scalars['Int']>>;
  isValid?: Maybe<Scalars['Boolean']>;
};

export type UpdateImportMappingOutput = UpdateImportMappingResult | ValidationErrors | AccessDeniedError | NotFoundError | ServiceError;

export type UpdateImportMappingResult = {
  __typename?: 'UpdateImportMappingResult';
  mapping: ImportMapping;
};

export type UpdateImportModelPriorityInput = {
  id: Scalars['Int'];
  modelId: Scalars['Int'];
  resourceId: Scalars['Int'];
  priority: Scalars['Int'];
};

export type UpdateImportModelPriorityOutput = UpdateImportModelPriorityResult | ValidationErrors | AccessDeniedError | NotFoundError | ModelNotFoundError | AlreadyExistsError | ServiceError;

export type UpdateImportModelPriorityResult = {
  __typename?: 'UpdateImportModelPriorityResult';
  id: Scalars['Int'];
  modelId: Scalars['Int'];
  resourceId: Scalars['Int'];
  priority: Scalars['Int'];
};

export type UpdateMakeInput = {
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  countryId: Scalars['Int'];
};

export type UpdateMakeOutput = UpdateMakeResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateMakeResult = {
  __typename?: 'UpdateMakeResult';
  make: FindMake;
};

export type UpdateModelInput = {
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
  MakeID: Scalars['Int'];
  NewMakeID?: Maybe<Scalars['Int']>;
  Position: Scalars['Int'];
  isImportant?: Maybe<Scalars['Boolean']>;
};

export type UpdateModelOutput = UpdateModelResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateModelResult = {
  __typename?: 'UpdateModelResult';
  model: FindModel;
};

export type UpdateModificationInput = {
  id: Scalars['Int'];
  modelId: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateModificationOutput = UpdateModificationResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateModificationResult = {
  __typename?: 'UpdateModificationResult';
  modification: FindModification;
};

export type UpdateOfferTranslationInput = {
  id: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
};

export type UpdateOfferTranslationOutput = UpdateOfferTranslationResult | ValidationErrors | NotFoundError;

export type UpdateOfferTranslationResult = {
  __typename?: 'UpdateOfferTranslationResult';
  translation: OfferTranslation;
};

export type UpdatePageInput = {
  id: Scalars['Int'];
  urlPath: Scalars['String'];
  type: PageType;
  displayName: Scalars['String'];
  canonical: Scalars['String'];
  isActive: Scalars['Boolean'];
  payload: PagePayloadInput;
  translations: Array<PageTranslationInput>;
  parentId: Scalars['Int'];
};

export type UpdatePageOutput = UpdatePageResult | InvalidUrlError | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdatePageResult = {
  __typename?: 'UpdatePageResult';
  page: FindPage;
};

export type UpdateProjectInput = {
  id: Scalars['Int'];
  projectKey: Scalars['String'];
  displayName: Scalars['String'];
  countries: Array<Scalars['String']>;
  currency: Currency;
  localeId: Scalars['Int'];
};

export type UpdateProjectOutput = UpdateProjectResult | ValidationErrors | AccessDeniedError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateProjectResult = {
  __typename?: 'UpdateProjectResult';
  project: Project;
};

export type UpdateRateInput = {
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type UpdateRateOutput = UpdateRateResult | ValidationErrors | AccessDeniedError | RateGroupNotFoundError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateRateResult = {
  __typename?: 'UpdateRateResult';
  rate: Rate;
};

export type UpdateTransmissionInput = {
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  typeId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  translations?: Maybe<Array<DictValueTranslationInput>>;
};

export type UpdateTransmissionOutput = UpdateTransmissionResult | ValidationErrors | AccessDeniedError | TransmissionTypeNotFoundError | AlreadyExistsError | NotFoundError | ServiceError;

export type UpdateTransmissionResult = {
  __typename?: 'UpdateTransmissionResult';
  transmission: Transmission;
};

export type UpdateUserFromLegacyInput = {
  user: LegacyUserData;
};

export type UpdateUserFromLegacyOutput = UpdateUserFromLegacyResult | AccessDeniedError | ValidationErrors | UserNotFoundError | AlreadyExistsError | ServiceError;

export type UpdateUserFromLegacyResult = {
  __typename?: 'UpdateUserFromLegacyResult';
  userId: Scalars['Int'];
};

export type UpdateUserInput = {
  id: Scalars['Int'];
  isUssActive?: Maybe<Scalars['Boolean']>;
  isCanBid?: Maybe<Scalars['Boolean']>;
  deposit?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['Int']>;
};

export type UpdateUserOutput = UpdateUserResult | ValidationErrors | AccessDeniedError | ServiceError;

export type UpdateUserProfileInput = {
  name: Scalars['String'];
  countryId: Scalars['Int'];
  email: Scalars['String'];
  phone: Scalars['String'];
  zipCode: Scalars['String'];
  address: Scalars['String'];
  currentPassword?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  newPasswordConfirm?: Maybe<Scalars['String']>;
};

export type UpdateUserProfileOutput = UpdateUserProfileResult | ValidationErrors | UserNotFoundError | AlreadyExistsError | ServiceError;

export type UpdateUserProfileResult = {
  __typename?: 'UpdateUserProfileResult';
  userProfile: UserProfile;
  isPasswordChanged: Scalars['Boolean'];
};

export type UpdateUserResult = {
  __typename?: 'UpdateUserResult';
  user: ManageUser;
};


export type UploadBlockModelImage = {
  kind: BlockModelImageKind;
  file: Scalars['Upload'];
};

export type UploadBlockModelOutput = BlockModelImage | AccessDeniedError | ValidationErrors | ServiceError;

export type Url = {
  __typename?: 'Url';
  scheme: Scalars['String'];
  host: Scalars['String'];
  path: Scalars['String'];
  query: Array<QueryParam>;
  fullUrl: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  name: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  locale: Locale;
  groupId: Scalars['Int'];
  zipCode: Scalars['String'];
  address: Scalars['String'];
  legacyUserID: Scalars['Int'];
  balance: Scalars['Int'];
};

export type UserBalanceItem = {
  __typename?: 'UserBalanceItem';
  transactionId: Scalars['String'];
  transactions: Scalars['String'];
  credit: Scalars['String'];
  debit: Scalars['String'];
  balance: Scalars['String'];
  order?: Maybe<UserOrderItem>;
  date: Scalars['Time'];
};

export type UserContext = {
  __typename?: 'UserContext';
  userId: Scalars['Int'];
  userGroup: UserGroup;
  username: Scalars['String'];
  countryId: Scalars['Int'];
  isUssActive: Scalars['Boolean'];
  isCanBid: Scalars['Boolean'];
  deposit: Scalars['Int'];
  allowActions: Array<Action>;
};

export enum UserEvents {
  UPDATE_BID = 'UPDATE_BID',
  UPDATE_TRANSLATION_REQUEST = 'UPDATE_TRANSLATION_REQUEST'
}

export enum UserGroup {
  USER = 'USER',
  ROOT = 'ROOT',
  SERVICE = 'SERVICE',
  TRANSLATOR = 'TRANSLATOR'
}

export type UserNotFoundError = ServiceErrorInterface & {
  __typename?: 'UserNotFoundError';
  message: Scalars['String'];
};

export type UserOrderFile = {
  __typename?: 'UserOrderFile';
  href: Scalars['String'];
  name: Scalars['String'];
  ext: Scalars['String'];
};

export type UserOrderItem = {
  __typename?: 'UserOrderItem';
  id: Scalars['String'];
  number: Scalars['String'];
  statusNumber: Scalars['String'];
  statusText: Scalars['String'];
  goodsDescription: Scalars['String'];
  logisticInfo: Scalars['String'];
  allocation: Scalars['String'];
  files: Array<UserOrderFile>;
  paymentData: Array<UserOrderPaymentData>;
  date: Scalars['Time'];
};

export type UserOrderPaymentData = {
  __typename?: 'UserOrderPaymentData';
  credit: Scalars['Int'];
  debit: Scalars['Int'];
  balance: Scalars['Int'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  name: Scalars['String'];
  countryId: Scalars['Int'];
  zipCode: Scalars['String'];
  address: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
};

export type UserSavedSearch = {
  __typename?: 'UserSavedSearch';
  id: Scalars['Int'];
  url: Url;
  data: Array<SavedSearchOption>;
};

export enum UsersSort {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC'
}

export type ValidationError = ServiceErrorInterface & {
  __typename?: 'ValidationError';
  field: Scalars['String'];
  rule: Scalars['String'];
  message: Scalars['String'];
  originalValue: Scalars['String'];
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  errors: Array<ValidationError>;
};

export type BlockModelItemFragment = (
  { __typename?: 'BlockModelItem' }
  & Pick<BlockModelItem, 'id' | 'blockId' | 'offerKind'>
  & { asset: (
    { __typename?: 'BlockModelAsset' }
    & TopAutoListAssetFragment
  ) }
);

export type CreateBlockModelItemMutationVariables = Exact<{
  input: CreateBlockModelItemInput;
}>;


export type CreateBlockModelItemMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateBlockModelItemResult' }
    & { item: (
      { __typename?: 'BlockModelItem' }
      & BlockModelItemFragment
    ) }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | { __typename?: 'BlockNotFoundError' } | (
    { __typename?: 'BlockModelItemAlreadyExistsError' }
    & Pick<BlockModelItemAlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'BlockModelAssetNotFoundError' }
    & Pick<BlockModelAssetNotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateTopAutoAssetMutationVariables = Exact<{
  input: CreateBlockModelAssetInput;
}>;


export type CreateTopAutoAssetMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'BlockModelAsset' }
    & Pick<BlockModelAsset, 'id'>
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'action' | 'message'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message'>
    )> }
  ) | (
    { __typename?: 'BlockModelImageNotFoundError' }
    & Pick<BlockModelImageNotFoundError, 'id' | 'message'>
  ) | (
    { __typename?: 'BlockModelAssetAlreadyExistsError' }
    & Pick<BlockModelAssetAlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'MakeNotFoundError' }
    & Pick<MakeNotFoundError, 'id' | 'message'>
  ) | (
    { __typename?: 'ModelNotFoundError' }
    & Pick<ModelNotFoundError, 'id' | 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteBlockModelItemMutationVariables = Exact<{
  input: DeleteBlockModelItemInput;
}>;


export type DeleteBlockModelItemMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'DeleteBlockModelItemResult' }
    & Pick<DeleteBlockModelItemResult, 'ok'>
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'BlockModelItemNotFoundError' }
    & Pick<BlockModelItemNotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type DeleteTopAutoAssetMutationVariables = Exact<{
  input: DeleteBlockModelAssetInput;
}>;


export type DeleteTopAutoAssetMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'BlockModelAssetNotFoundError' }
    & Pick<BlockModelAssetNotFoundError, 'message'>
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message'>
    )> }
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type FindBlockModelItemsQueryVariables = Exact<{
  input: FindBlockModelItemsInput;
}>;


export type FindBlockModelItemsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'FindBlockModelItemsResult' }
    & Pick<FindBlockModelItemsResult, 'total'>
    & { items: Array<(
      { __typename?: 'BlockModelItem' }
      & BlockModelItemFragment
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message'>
    )> }
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindTopAutoAssetsQueryVariables = Exact<{
  input: FindBlockModelAssetsInput;
}>;


export type FindTopAutoAssetsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'FindBlockModelAssetsResult' }
    & Pick<FindBlockModelAssetsResult, 'total'>
    & { assets: Array<(
      { __typename?: 'BlockModelAsset' }
      & TopAutoListAssetFragment
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | { __typename?: 'ValidationErrors' } | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type UploadTopAutoImageMutationVariables = Exact<{
  file: UploadBlockModelImage;
}>;


export type UploadTopAutoImageMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'BlockModelImage' }
    & Pick<BlockModelImage, 'id' | 'url'>
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'action' | 'message'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )> }
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type TopAutoListAssetFragment = (
  { __typename?: 'BlockModelAsset' }
  & Pick<BlockModelAsset, 'id' | 'bigImage' | 'middleImage' | 'smallImage'>
  & { make: (
    { __typename?: 'CarMake' }
    & Pick<CarMake, 'title'>
  ), model: (
    { __typename?: 'CarModel' }
    & Pick<CarModel, 'title'>
  ) }
);

export type BidUserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'phone' | 'email' | 'locale' | 'groupId' | 'zipCode' | 'address' | 'legacyUserID' | 'balance'>
);

export type FindAllBidsQueryVariables = Exact<{
  input: FindBidsInput;
}>;


export type FindAllBidsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindBidsResult' }
    & Pick<FindBidsResult, 'total'>
    & { filter?: Maybe<(
      { __typename?: 'BidFilter' }
      & { auctionDates: Array<(
        { __typename?: 'BidFilterAuctionDates' }
        & Pick<BidFilterAuctionDates, 'auctionAt' | 'count'>
      )>, auctionNames: Array<(
        { __typename?: 'BidFilterAuctionNames' }
        & Pick<BidFilterAuctionNames, 'auctionId' | 'auctionName' | 'count'>
      )>, userNames: Array<(
        { __typename?: 'BidFilterUserNames' }
        & Pick<BidFilterUserNames, 'userId' | 'userName' | 'userEmail' | 'count'>
      )>, carModels: Array<(
        { __typename?: 'BidFilterCarModels' }
        & Pick<BidFilterCarModels, 'modelId' | 'modelName' | 'count'>
      )> }
    )>, bids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'bidRequest' | 'userId' | 'offerId' | 'currency' | 'typeId' | 'createdAt' | 'text' | 'groupId' | 'endPrice' | 'cvrPrice' | 'isAgree' | 'auctionId' | 'auctionAt' | 'status' | 'soldStatus' | 'isAuctionOver' | 'isConfirmed' | 'isCanceledByUser' | 'isCanceledByManager' | 'isWin'>
      & { user: (
        { __typename?: 'User' }
        & BidUserFragmentFragment
      ), type: (
        { __typename: 'PropertyDictionary' }
        & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
      ), group: (
        { __typename: 'PropertyDictionary' }
        & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
      ), offer?: Maybe<(
        { __typename: 'OfferAuction' }
        & Pick<OfferAuction, 'auctionAt' | 'auctionListImage' | 'createdAt' | 'engineVolume' | 'equip' | 'customFinishPrice' | 'id' | 'images' | 'imagesIds' | 'imagesTotal' | 'auctionListImages' | 'auctionListImagesIds' | 'auctionStatus' | 'kind' | 'lot' | 'userHasBid' | 'isAuctionEnded' | 'mileage' | 'power' | 'seats' | 'serial' | 'status' | 'updatedAt' | 'isUss' | 'year'>
        & { auction: (
          { __typename?: 'PropertyDictionary' }
          & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
        ), averagePrice: (
          { __typename?: 'OfferPrice' }
          & Pick<OfferPrice, 'USD' | 'RUB' | 'EUR' | 'JPY'>
        ), bodyType: (
          { __typename?: 'PropertyDictionary' }
          & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
        ), chassis: (
          { __typename?: 'OfferChassis' }
          & Pick<OfferChassis, 'id' | 'slug' | 'title'>
        ), color: (
          { __typename?: 'PropertyDictionary' }
          & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
        ), finishPrice: (
          { __typename?: 'OfferPrice' }
          & Pick<OfferPrice, 'USD' | 'RUB' | 'EUR' | 'JPY'>
        ), fuel: (
          { __typename?: 'PropertyDictionary' }
          & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
        ), gear?: Maybe<Array<(
          { __typename?: 'PropertyDictionary' }
          & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
        )>>, make: (
          { __typename?: 'OfferMake' }
          & Pick<OfferMake, 'id' | 'slug' | 'title'>
        ), model: (
          { __typename?: 'OfferModel' }
          & Pick<OfferModel, 'id' | 'slug' | 'title'>
        ), modification: (
          { __typename?: 'OfferModification' }
          & Pick<OfferModification, 'id' | 'slug' | 'title'>
        ), price: (
          { __typename?: 'OfferPrice' }
          & Pick<OfferPrice, 'USD' | 'RUB' | 'EUR' | 'JPY'>
        ), rate: (
          { __typename?: 'PropertyDictionary' }
          & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
        ), startPrice: (
          { __typename?: 'OfferPrice' }
          & Pick<OfferPrice, 'USD' | 'RUB' | 'EUR' | 'JPY'>
        ), steering: (
          { __typename?: 'PropertyDictionary' }
          & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
        ), transmission: (
          { __typename?: 'PropertyDictionary' }
          & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
        ), translation: (
          { __typename?: 'OfferAuctionTranslation' }
          & Pick<OfferAuctionTranslation, 'isReaded' | 'text' | 'userHasTranslationRequest'>
        ), transmissionType: (
          { __typename?: 'PropertyDictionary' }
          & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
        ), actions: (
          { __typename?: 'OfferAuctionAction' }
          & Pick<OfferAuctionAction, 'canGetImages'>
        ), urlPage: (
          { __typename?: 'Url' }
          & Pick<Url, 'fullUrl' | 'host' | 'path' | 'scheme'>
          & { query: Array<(
            { __typename?: 'QueryParam' }
            & Pick<QueryParam, 'param' | 'value'>
          )> }
        ), dataReady: (
          { __typename?: 'OfferAuctionDataReady' }
          & Pick<OfferAuctionDataReady, 'isDataReady' | 'isImagesReady'>
        ) }
      ) | (
        { __typename: 'OfferNotFound' }
        & Pick<OfferNotFound, 'id' | 'message'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )> }
  ) }
);

export type UpdateBidMutationVariables = Exact<{
  input: UpdateBidInput;
}>;


export type UpdateBidMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateBidResult' }
    & { bid: (
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'endPrice' | 'status'>
    ) }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )> }
  ) | { __typename?: 'NotFoundError' } }
);

export type CreateBlockLayoutPlacementMutationVariables = Exact<{
  input: CreateBlockLayoutPlacementInput;
}>;


export type CreateBlockLayoutPlacementMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateBlockLayoutPlacementResult' }
    & { blockLayoutPlacement: (
      { __typename?: 'FindBlockLayoutPlacement' }
      & Pick<FindBlockLayoutPlacement, 'layoutId' | 'blockId' | 'isVisible' | 'position'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteBlockLayoutPlacementMutationVariables = Exact<{
  input: DeleteBlockLayoutPlacementInput;
}>;


export type DeleteBlockLayoutPlacementMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'DeleteBlockLayoutPlacementResult' }
    & Pick<DeleteBlockLayoutPlacementResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type FindBlockLayoutPlacementsQueryVariables = Exact<{
  input: FindBlockLayoutPlacementsInput;
}>;


export type FindBlockLayoutPlacementsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindBlockLayoutPlacementsResult' }
    & Pick<FindBlockLayoutPlacementsResult, 'total'>
    & { blockLayoutPlacements: Array<(
      { __typename?: 'FindBlockLayoutPlacement' }
      & Pick<FindBlockLayoutPlacement, 'layoutId' | 'blockId' | 'isVisible' | 'position'>
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'rule' | 'message' | 'originalValue'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message' | 'action'>
  ) }
);

export type UpdateBlockLayoutPlacementMutationVariables = Exact<{
  input: UpdateBlockLayoutPlacementInput;
}>;


export type UpdateBlockLayoutPlacementMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateBlockLayoutPlacementResult' }
    & { blockLayoutPlacement: (
      { __typename?: 'FindBlockLayoutPlacement' }
      & Pick<FindBlockLayoutPlacement, 'layoutId' | 'blockId' | 'isVisible' | 'position'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateBlockLayoutMutationVariables = Exact<{
  input: CreateBlockLayoutInput;
}>;


export type CreateBlockLayoutMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateBlockLayoutResult' }
    & { blockLayout: (
      { __typename?: 'FindBlockLayout' }
      & Pick<FindBlockLayout, 'id' | 'projectId' | 'isVisible' | 'slug' | 'displayName'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteBlockLayoutMutationVariables = Exact<{
  input: DeleteBlockLayoutInput;
}>;


export type DeleteBlockLayoutMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'DeleteBlockLayoutResult' }
    & Pick<DeleteBlockLayoutResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type FindBlockLayoutsQueryVariables = Exact<{
  input: FindBlockLayoutsInput;
}>;


export type FindBlockLayoutsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindBlockLayoutsResult' }
    & Pick<FindBlockLayoutsResult, 'total'>
    & { blockLayouts: Array<(
      { __typename?: 'FindBlockLayout' }
      & Pick<FindBlockLayout, 'id' | 'projectId' | 'isVisible' | 'slug' | 'displayName'>
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'rule' | 'message' | 'originalValue'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message' | 'action'>
  ) }
);

export type GenerateBlocksMutationVariables = Exact<{
  input: GenerateBlocksInput;
}>;


export type GenerateBlocksMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'GenerateBlocksResult' }
    & Pick<GenerateBlocksResult, 'ok'>
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type UpdateBlockLayoutMutationVariables = Exact<{
  input: UpdateBlockLayoutInput;
}>;


export type UpdateBlockLayoutMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateBlockLayoutResult' }
    & { blockLayout: (
      { __typename?: 'FindBlockLayout' }
      & Pick<FindBlockLayout, 'id' | 'projectId' | 'isVisible' | 'slug' | 'displayName'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateBlockMutationVariables = Exact<{
  input: CreateBlockInput;
}>;


export type CreateBlockMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'Block' }
    & Pick<Block, 'id' | 'type' | 'name' | 'header' | 'subheader' | 'isActive'>
    & { view: (
      { __typename?: 'BlockView' }
      & Pick<BlockView, 'id' | 'type' | 'view' | 'displayName'>
    ), translations: Array<(
      { __typename?: 'BlockTranslation' }
      & Pick<BlockTranslation, 'locale' | 'field' | 'other'>
    )>, options?: Maybe<(
      { __typename?: 'BlockModelsOptions' }
      & Pick<BlockModelsOptions, 'shuffleSize' | 'limit'>
    ) | (
      { __typename?: 'BlockOffersOptions' }
      & Pick<BlockOffersOptions, 'offerKind' | 'makeIDAnyOf' | 'modelIDAnyOf' | 'bodyTypeIDAnyOf' | 'yearMin' | 'yearMax' | 'engineVolumeMin' | 'engineVolumeMax' | 'mileageMin' | 'mileageMax' | 'shuffleSize' | 'limit'>
    )> }
  ) | (
    { __typename?: 'BlockViewNotFoundError' }
    & Pick<BlockViewNotFoundError, 'message' | 'id'>
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteBlockMutationVariables = Exact<{
  input: DeleteBlockInput;
}>;


export type DeleteBlockMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'BlockNotFoundError' }
    & Pick<BlockNotFoundError, 'message' | 'id'>
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type ManageBlocksQueryVariables = Exact<{
  input: ManageBlocksInput;
}>;


export type ManageBlocksQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'ManageBlocksResult' }
    & Pick<ManageBlocksResult, 'total'>
    & { blocks: Array<(
      { __typename?: 'ManageBlock' }
      & Pick<ManageBlock, 'id' | 'typeID' | 'viewID' | 'name' | 'header' | 'subheader' | 'isActive'>
      & { translations: Array<(
        { __typename?: 'BlockTranslation' }
        & Pick<BlockTranslation, 'locale' | 'field' | 'other'>
      )>, options?: Maybe<(
        { __typename?: 'BlockModelsOptions' }
        & Pick<BlockModelsOptions, 'shuffleSize' | 'limit'>
      ) | (
        { __typename?: 'BlockOffersOptions' }
        & Pick<BlockOffersOptions, 'offerKind' | 'makeIDAnyOf' | 'modelIDAnyOf' | 'bodyTypeIDAnyOf' | 'yearMin' | 'yearMax' | 'engineVolumeMin' | 'engineVolumeMax' | 'mileageMin' | 'mileageMax' | 'shuffleSize' | 'limit'>
      )> }
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message' | 'action'>
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'rule' | 'message' | 'originalValue'>
    )> }
  ) | (
    { __typename: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type UpdateBlockMutationVariables = Exact<{
  input: UpdateBlockInput;
}>;


export type UpdateBlockMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'Block' }
    & Pick<Block, 'id' | 'type' | 'name' | 'header' | 'subheader' | 'isActive'>
    & { view: (
      { __typename?: 'BlockView' }
      & Pick<BlockView, 'id' | 'type' | 'view' | 'displayName'>
    ), translations: Array<(
      { __typename?: 'BlockTranslation' }
      & Pick<BlockTranslation, 'locale' | 'field' | 'other'>
    )>, options?: Maybe<(
      { __typename?: 'BlockModelsOptions' }
      & Pick<BlockModelsOptions, 'shuffleSize' | 'limit'>
    ) | (
      { __typename?: 'BlockOffersOptions' }
      & Pick<BlockOffersOptions, 'offerKind' | 'makeIDAnyOf' | 'modelIDAnyOf' | 'bodyTypeIDAnyOf' | 'yearMin' | 'yearMax' | 'engineVolumeMin' | 'engineVolumeMax' | 'mileageMin' | 'mileageMax' | 'shuffleSize' | 'limit'>
    )> }
  ) | (
    { __typename?: 'BlockNotFoundError' }
    & Pick<BlockNotFoundError, 'message' | 'id'>
  ) | (
    { __typename?: 'BlockViewNotFoundError' }
    & Pick<BlockViewNotFoundError, 'message' | 'id'>
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateBodyTypeMutationVariables = Exact<{
  input: CreateBodyTypeInput;
}>;


export type CreateBodyTypeMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateBodyTypeResult' }
    & { bodyType: (
      { __typename?: 'BodyType' }
      & Pick<BodyType, 'id' | 'slug' | 'title' | 'position'>
      & { translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteBodyTypeMutationVariables = Exact<{
  input: DeleteBodyTypeInput;
}>;


export type DeleteBodyTypeMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'DeleteBodyTypeResult' }
    & Pick<DeleteBodyTypeResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type FindBodyTypesQueryVariables = Exact<{
  input: FindBodyTypesInput;
}>;


export type FindBodyTypesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindBodyTypesResult' }
    & Pick<FindBodyTypesResult, 'total'>
    & { bodyTypes: Array<(
      { __typename?: 'BodyType' }
      & Pick<BodyType, 'id' | 'slug' | 'title' | 'position'>
      & { translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type UpdateBodyTypeMutationVariables = Exact<{
  input: UpdateBodyTypeInput;
}>;


export type UpdateBodyTypeMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateBodyTypeResult' }
    & { bodyType: (
      { __typename?: 'BodyType' }
      & Pick<BodyType, 'id' | 'slug' | 'title' | 'position'>
      & { translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateChassisMutationVariables = Exact<{
  input: CreateChassisInput;
}>;


export type CreateChassisMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateChassisResult' }
    & { chassis: (
      { __typename?: 'FindChassis' }
      & Pick<FindChassis, 'id' | 'modelId' | 'slug' | 'title' | 'offersTotal'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteChassisMutationVariables = Exact<{
  input: DeleteChassisInput;
}>;


export type DeleteChassisMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeleteChassisResult' }
    & Pick<DeleteChassisResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindChassisQueryVariables = Exact<{
  input: FindChassisInput;
}>;


export type FindChassisQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindChassisResult' }
    & Pick<FindChassisResult, 'total'>
    & { chassis: Array<(
      { __typename?: 'FindChassis' }
      & Pick<FindChassis, 'id' | 'modelId' | 'slug' | 'title' | 'offersTotal'>
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'rule' | 'message' | 'originalValue'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message' | 'action'>
  ) }
);

export type MergeChassisMutationVariables = Exact<{
  input: MergeChassisInput;
}>;


export type MergeChassisMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'MergeChassisResult' }
    & Pick<MergeChassisResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type UpdateChassisMutationVariables = Exact<{
  input: UpdateChassisInput;
}>;


export type UpdateChassisMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateChassisResult' }
    & { chassis: (
      { __typename?: 'FindChassis' }
      & Pick<FindChassis, 'id' | 'modelId' | 'slug' | 'title' | 'offersTotal'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateColorMutationVariables = Exact<{
  input: CreateColorInput;
}>;


export type CreateColorMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateColorResult' }
    & { color: (
      { __typename?: 'Color' }
      & Pick<Color, 'id' | 'slug' | 'title' | 'position'>
      & { group: (
        { __typename?: 'ColorGroup' }
        & Pick<ColorGroup, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ColorGroupNotFoundError' }
    & Pick<ColorGroupNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteColorMutationVariables = Exact<{
  input: DeleteColorInput;
}>;


export type DeleteColorMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'DeleteColorResult' }
    & Pick<DeleteColorResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type FindColorsQueryVariables = Exact<{
  input: FindColorsInput;
}>;


export type FindColorsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindColorsResult' }
    & Pick<FindColorsResult, 'total'>
    & { Colors: Array<(
      { __typename?: 'Color' }
      & Pick<Color, 'id' | 'slug' | 'title' | 'position'>
      & { group: (
        { __typename?: 'ColorGroup' }
        & Pick<ColorGroup, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type UpdateColorMutationVariables = Exact<{
  input: UpdateColorInput;
}>;


export type UpdateColorMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateColorResult' }
    & { color: (
      { __typename?: 'Color' }
      & Pick<Color, 'id' | 'slug' | 'title' | 'position'>
      & { group: (
        { __typename?: 'ColorGroup' }
        & Pick<ColorGroup, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ColorGroupNotFoundError' }
    & Pick<ColorGroupNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateCountryMutationVariables = Exact<{
  input: CreateCountryInput;
}>;


export type CreateCountryMutation = (
  { __typename?: 'Mutation' }
  & { createCountry: (
    { __typename: 'CreateCountryResult' }
    & { country: (
      { __typename?: 'ManageCountry' }
      & Pick<ManageCountry, 'id' | 'slug' | 'title' | 'phoneCode' | 'iso2' | 'iso3' | 'iso_numeric' | 'continent' | 'capital' | 'capital_time_zone' | 'is_active' | 'is_local_info_active'>
      & { translations: Array<(
        { __typename?: 'CountryTranslation' }
        & Pick<CountryTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename: 'ValidationError' }
      & Pick<ValidationError, 'rule' | 'field'>
    )> }
  ) | { __typename: 'AccessDeniedError' } | (
    { __typename: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'entity' | 'message'>
  ) | (
    { __typename: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteCountryMutationVariables = Exact<{
  input: DeleteCountryInput;
}>;


export type DeleteCountryMutation = (
  { __typename?: 'Mutation' }
  & { result: { __typename?: 'CreateCountryResult' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | { __typename?: 'AlreadyExistsError' } | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'DeleteCountryResult' }
    & Pick<DeleteCountryResult, 'ok'>
  ) }
);

export type FindCountriesOfLocalInfoQueryVariables = Exact<{
  input: FindCountriesOfLocalInfoInput;
}>;


export type FindCountriesOfLocalInfoQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindCountriesOfLocalInfoResult' }
    & Pick<FindCountriesOfLocalInfoResult, 'total'>
    & { countries: Array<(
      { __typename?: 'FindCountry' }
      & Pick<FindCountry, 'id' | 'slug' | 'title' | 'phoneCode' | 'iso'>
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type ManageCountriesQueryVariables = Exact<{
  input: FindCountriesInput;
}>;


export type ManageCountriesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'ManageCountriesResult' }
    & Pick<ManageCountriesResult, 'total'>
    & { countries: Array<(
      { __typename?: 'ManageCountry' }
      & Pick<ManageCountry, 'id' | 'slug' | 'title' | 'phoneCode' | 'iso2' | 'iso3' | 'iso_numeric' | 'continent' | 'capital' | 'capital_time_zone' | 'is_active' | 'is_local_info_active'>
      & { translations: Array<(
        { __typename?: 'CountryTranslation' }
        & Pick<CountryTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type FindCountriesQueryVariables = Exact<{
  input: FindCountriesInput;
}>;


export type FindCountriesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindCountriesResult' }
    & Pick<FindCountriesResult, 'total'>
    & { countries: Array<(
      { __typename?: 'FindCountry' }
      & Pick<FindCountry, 'id' | 'slug' | 'title' | 'phoneCode'>
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type UpdateCountryMutationVariables = Exact<{
  input: UpdateCountryInput;
}>;


export type UpdateCountryMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateCountryResult' }
    & { country: (
      { __typename?: 'ManageCountry' }
      & Pick<ManageCountry, 'id' | 'slug' | 'title' | 'phoneCode' | 'iso2' | 'iso3' | 'iso_numeric' | 'continent' | 'capital' | 'capital_time_zone' | 'is_active' | 'is_local_info_active'>
      & { translations: Array<(
        { __typename?: 'CountryTranslation' }
        & Pick<CountryTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateDictValueMutationVariables = Exact<{
  input: CreateDictValueInput;
}>;


export type CreateDictValueMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateDictValueResult' }
    & { value: (
      { __typename?: 'DictionaryValue' }
      & Pick<DictionaryValue, 'id' | 'slug' | 'title'>
      & { translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteDictValueMutationVariables = Exact<{
  input: DeleteDictValueInput;
}>;


export type DeleteDictValueMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'DeleteDictValueResult' }
    & Pick<DeleteDictValueResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type FindDictGroupsQueryVariables = Exact<{
  input: FindDictGroupsInput;
}>;


export type FindDictGroupsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindDictGroupsResult' }
    & Pick<FindDictGroupsResult, 'total'>
    & { dictGroups: Array<(
      { __typename?: 'DictionaryGroup' }
      & Pick<DictionaryGroup, 'id' | 'name'>
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type FindDictValuesQueryVariables = Exact<{
  input: FindDictValuesInput;
}>;


export type FindDictValuesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindDictValuesResult' }
    & Pick<FindDictValuesResult, 'total'>
    & { dictValues: Array<(
      { __typename?: 'DictionaryValue' }
      & Pick<DictionaryValue, 'id' | 'slug' | 'title'>
      & { translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type UpdateDictValueMutationVariables = Exact<{
  input: UpdateDictValueInput;
}>;


export type UpdateDictValueMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateDictValueResult' }
    & { value: (
      { __typename?: 'DictionaryValue' }
      & Pick<DictionaryValue, 'id' | 'slug' | 'title'>
      & { translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateFaqCategoryMutationVariables = Exact<{
  input: CreateFaqCategoryInput;
}>;


export type CreateFaqCategoryMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateFaqCategoryResult' }
    & { faqCategory: (
      { __typename?: 'FaqCategory' }
      & Pick<FaqCategory, 'id' | 'title' | 'slug' | 'isActive' | 'position'>
      & { translations: Array<(
        { __typename?: 'FaqCategoryTranslation' }
        & Pick<FaqCategoryTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteFaqCategoryMutationVariables = Exact<{
  input: DeleteFaqCategoryInput;
}>;


export type DeleteFaqCategoryMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeleteFaqCategoryResult' }
    & Pick<DeleteFaqCategoryResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindFaqCategoriesQueryVariables = Exact<{
  input: FindFaqCategoriesInput;
}>;


export type FindFaqCategoriesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindFaqCategoriesResult' }
    & { faqCategories: Array<(
      { __typename?: 'FaqCategory' }
      & Pick<FaqCategory, 'id' | 'title' | 'slug' | 'isActive' | 'position'>
      & { translations: Array<(
        { __typename?: 'FaqCategoryTranslation' }
        & Pick<FaqCategoryTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type SetFaqCategoriesPositionsMutationVariables = Exact<{
  input: SetFaqCategoriesPositionsInput;
}>;


export type SetFaqCategoriesPositionsMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename: 'SetFaqCategoriesPositionsResult' }
    & Pick<SetFaqCategoriesPositionsResult, 'ok'>
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | { __typename: 'ServiceError' } }
);

export type UpdateFaqCategoryMutationVariables = Exact<{
  input: UpdateFaqCategoryInput;
}>;


export type UpdateFaqCategoryMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateFaqCategoryResult' }
    & { faqCategory: (
      { __typename?: 'FaqCategory' }
      & Pick<FaqCategory, 'id' | 'title' | 'slug' | 'isActive' | 'position'>
      & { translations: Array<(
        { __typename?: 'FaqCategoryTranslation' }
        & Pick<FaqCategoryTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateFaqMutationVariables = Exact<{
  input: CreateFaqInput;
}>;


export type CreateFaqMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateFaqResult' }
    & { faq: (
      { __typename?: 'Faq' }
      & Pick<Faq, 'id' | 'categoryId' | 'question' | 'answer' | 'isActive' | 'inBlock' | 'position'>
      & { translations: Array<(
        { __typename?: 'FaqTranslation' }
        & Pick<FaqTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | { __typename?: 'NotFoundError' } | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteFaqMutationVariables = Exact<{
  input: DeleteFaqInput;
}>;


export type DeleteFaqMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeleteFaqResult' }
    & Pick<DeleteFaqResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindFaqQueryVariables = Exact<{
  input: FindFaqInput;
}>;


export type FindFaqQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindFaqResult' }
    & { faq: Array<(
      { __typename?: 'Faq' }
      & Pick<Faq, 'id' | 'categoryId' | 'question' | 'answer' | 'isActive' | 'inBlock' | 'position'>
      & { translations: Array<(
        { __typename?: 'FaqTranslation' }
        & Pick<FaqTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type SetFaqPositionsMutationVariables = Exact<{
  input: SetFaqPositionsInput;
}>;


export type SetFaqPositionsMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename: 'SetFaqPositionsResult' }
    & Pick<SetFaqPositionsResult, 'ok'>
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | { __typename: 'ServiceError' } }
);

export type UpdateFaqMutationVariables = Exact<{
  input: UpdateFaqInput;
}>;


export type UpdateFaqMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateFaqResult' }
    & { faq: (
      { __typename?: 'Faq' }
      & Pick<Faq, 'id' | 'categoryId' | 'question' | 'answer' | 'isActive' | 'inBlock' | 'position'>
      & { translations: Array<(
        { __typename?: 'FaqTranslation' }
        & Pick<FaqTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateGearMutationVariables = Exact<{
  input: CreateGearInput;
}>;


export type CreateGearMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateGearResult' }
    & { gear: (
      { __typename?: 'Gear' }
      & Pick<Gear, 'id' | 'slug' | 'title' | 'position'>
      & { type: (
        { __typename?: 'GearType' }
        & Pick<GearType, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'GearTypeNotFoundError' }
    & Pick<GearTypeNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteGearMutationVariables = Exact<{
  input: DeleteGearInput;
}>;


export type DeleteGearMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'DeleteGearResult' }
    & Pick<DeleteGearResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type FindGearsQueryVariables = Exact<{
  input: FindGearsInput;
}>;


export type FindGearsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindGearsResult' }
    & Pick<FindGearsResult, 'total'>
    & { gears: Array<(
      { __typename?: 'Gear' }
      & Pick<Gear, 'id' | 'slug' | 'title' | 'position'>
      & { type: (
        { __typename?: 'GearType' }
        & Pick<GearType, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type UpdateGearMutationVariables = Exact<{
  input: UpdateGearInput;
}>;


export type UpdateGearMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateGearResult' }
    & { gear: (
      { __typename?: 'Gear' }
      & Pick<Gear, 'id' | 'slug' | 'title' | 'position'>
      & { type: (
        { __typename?: 'GearType' }
        & Pick<GearType, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'GearTypeNotFoundError' }
    & Pick<GearTypeNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type BulkUpdateEntityIdMutationVariables = Exact<{
  input: BulkUpdateEntityIdInput;
}>;


export type BulkUpdateEntityIdMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'BulkUpdateEntityIDResult' }
    & Pick<BulkUpdateEntityIdResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type BulkUpdateImportMappingMutationVariables = Exact<{
  input: BulkUpdateImportMappingsInput;
}>;


export type BulkUpdateImportMappingMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'BulkUpdateImportMappingsResult' }
    & Pick<BulkUpdateImportMappingsResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindImportMappingsQueryVariables = Exact<{
  input: FindImportMappingsInput;
}>;


export type FindImportMappingsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'FindImportMappingsResult' }
    & Pick<FindImportMappingsResult, 'total'>
    & { mapping: Array<(
      { __typename?: 'ImportMapping' }
      & Pick<ImportMapping, 'id' | 'rawValue' | 'resource' | 'field' | 'entityType' | 'entitiesIds' | 'isValid' | 'createdAt'>
      & { entities: Array<(
        { __typename: 'Transmission' }
        & Pick<Transmission, 'id' | 'slug' | 'title' | 'position'>
        & { type: (
          { __typename?: 'TransmissionType' }
          & Pick<TransmissionType, 'id' | 'slug' | 'title'>
        ), translations: Array<(
          { __typename?: 'DictValueTranslation' }
          & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
        )> }
      ) | (
        { __typename: 'Color' }
        & Pick<Color, 'id' | 'slug' | 'title' | 'position'>
        & { group: (
          { __typename?: 'ColorGroup' }
          & Pick<ColorGroup, 'id' | 'slug' | 'title'>
        ), translations: Array<(
          { __typename?: 'DictValueTranslation' }
          & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
        )> }
      ) | (
        { __typename: 'Gear' }
        & Pick<Gear, 'id' | 'slug' | 'title' | 'position'>
        & { type: (
          { __typename?: 'GearType' }
          & Pick<GearType, 'id' | 'slug' | 'title'>
        ), translations: Array<(
          { __typename?: 'DictValueTranslation' }
          & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
        )> }
      ) | (
        { __typename: 'Rate' }
        & Pick<Rate, 'id' | 'slug' | 'title' | 'position'>
        & { group: (
          { __typename?: 'RateGroup' }
          & Pick<RateGroup, 'id' | 'slug' | 'title'>
        ), translations: Array<(
          { __typename?: 'DictValueTranslation' }
          & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
        )> }
      ) | (
        { __typename: 'ImportMappingEntityNotFoundError' }
        & Pick<ImportMappingEntityNotFoundError, 'id' | 'message'>
      )> }
    )> }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | { __typename?: 'AccessDeniedError' } }
);

export type UpdateImportMappingMutationVariables = Exact<{
  input: UpdateImportMappingInput;
}>;


export type UpdateImportMappingMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateImportMappingResult' }
    & { mapping: (
      { __typename?: 'ImportMapping' }
      & Pick<ImportMapping, 'id' | 'rawValue' | 'resource' | 'field' | 'entityType' | 'entitiesIds' | 'isValid' | 'createdAt'>
      & { entities: Array<(
        { __typename: 'Transmission' }
        & Pick<Transmission, 'id' | 'slug' | 'title' | 'position'>
        & { type: (
          { __typename?: 'TransmissionType' }
          & Pick<TransmissionType, 'id' | 'slug' | 'title'>
        ), translations: Array<(
          { __typename?: 'DictValueTranslation' }
          & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
        )> }
      ) | (
        { __typename: 'Color' }
        & Pick<Color, 'id' | 'slug' | 'title' | 'position'>
        & { group: (
          { __typename?: 'ColorGroup' }
          & Pick<ColorGroup, 'id' | 'slug' | 'title'>
        ), translations: Array<(
          { __typename?: 'DictValueTranslation' }
          & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
        )> }
      ) | (
        { __typename: 'Gear' }
        & Pick<Gear, 'id' | 'slug' | 'title' | 'position'>
        & { type: (
          { __typename?: 'GearType' }
          & Pick<GearType, 'id' | 'slug' | 'title'>
        ), translations: Array<(
          { __typename?: 'DictValueTranslation' }
          & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
        )> }
      ) | (
        { __typename: 'Rate' }
        & Pick<Rate, 'id' | 'slug' | 'title' | 'position'>
        & { group: (
          { __typename?: 'RateGroup' }
          & Pick<RateGroup, 'id' | 'slug' | 'title'>
        ), translations: Array<(
          { __typename?: 'DictValueTranslation' }
          & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
        )> }
      ) | (
        { __typename: 'ImportMappingEntityNotFoundError' }
        & Pick<ImportMappingEntityNotFoundError, 'id' | 'message'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type ManageLocalInfoQueryVariables = Exact<{
  input: ManageLocalInfoInput;
}>;


export type ManageLocalInfoQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'ManageLocalInfoResult' }
    & { items: Array<(
      { __typename?: 'ManageLocalInfo' }
      & Pick<ManageLocalInfo, 'id' | 'countryId' | 'countryName' | 'restriction' | 'steeringWheelPosition' | 'destinationPort' | 'inspection' | 'documents' | 'taxes' | 'registration' | 'nb'>
      & { translations: Array<(
        { __typename?: 'LocalInfoTranslation' }
        & Pick<LocalInfoTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type SaveLocalInfoMutationVariables = Exact<{
  input: SaveLocalInfoInput;
}>;


export type SaveLocalInfoMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename: 'SaveLocalInfoResult' }
    & { localInfo: (
      { __typename?: 'ManageLocalInfo' }
      & Pick<ManageLocalInfo, 'id' | 'countryId' | 'countryName' | 'restriction' | 'steeringWheelPosition' | 'destinationPort' | 'inspection' | 'documents' | 'taxes' | 'registration' | 'nb'>
      & { translations: Array<(
        { __typename?: 'LocalInfoTranslation' }
        & Pick<LocalInfoTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindLocalesQueryVariables = Exact<{
  input: FindLocalesInput;
}>;


export type FindLocalesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'FindLocalesResult' }
    & Pick<FindLocalesResult, 'total'>
    & { locales: Array<(
      { __typename?: 'FindLocale' }
      & Pick<FindLocale, 'id' | 'name' | 'position'>
    )> }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type CreateImportMakePriorityMutationVariables = Exact<{
  input: CreateImportMakePriorityInput;
}>;


export type CreateImportMakePriorityMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateImportMakePriorityResult' }
    & Pick<CreateImportMakePriorityResult, 'id' | 'makeId' | 'resourceId' | 'priority'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'MakeNotFoundError' }
    & Pick<MakeNotFoundError, 'id' | 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateMakeMutationVariables = Exact<{
  input: CreateMakeInput;
}>;


export type CreateMakeMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateMakeResult' }
    & { make: (
      { __typename?: 'FindMake' }
      & Pick<FindMake, 'id' | 'slug' | 'title' | 'offersTotal' | 'countryId'>
      & { importOptions: (
        { __typename: 'MakeImportOptionsResult' }
        & { priority: Array<(
          { __typename?: 'ImportPriority' }
          & Pick<ImportPriority, 'id' | 'priority'>
          & { resource: (
            { __typename?: 'ImportResource' }
            & Pick<ImportResource, 'id' | 'displayName'>
          ) }
        )> }
      ) | (
        { __typename: 'InternalError' }
        & Pick<InternalError, 'message'>
      ) | (
        { __typename: 'AccessDeniedError' }
        & Pick<AccessDeniedError, 'message' | 'action'>
      ) }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteImportMakePriorityMutationVariables = Exact<{
  input: DeleteImportMakePriorityInput;
}>;


export type DeleteImportMakePriorityMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeleteImportMakePriorityResult' }
    & Pick<DeleteImportMakePriorityResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteMakeMutationVariables = Exact<{
  input: DeleteMakeInput;
}>;


export type DeleteMakeMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeleteMakeResult' }
    & Pick<DeleteMakeResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindMakesQueryVariables = Exact<{
  input: FindMakesInput;
}>;


export type FindMakesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindMakesResult' }
    & Pick<FindMakesResult, 'total'>
    & { makes: Array<(
      { __typename?: 'FindMake' }
      & Pick<FindMake, 'id' | 'slug' | 'title' | 'offersTotal' | 'countryId'>
      & { importOptions: (
        { __typename: 'MakeImportOptionsResult' }
        & { priority: Array<(
          { __typename?: 'ImportPriority' }
          & Pick<ImportPriority, 'id' | 'priority'>
          & { resource: (
            { __typename?: 'ImportResource' }
            & Pick<ImportResource, 'id' | 'displayName'>
          ) }
        )> }
      ) | (
        { __typename: 'InternalError' }
        & Pick<InternalError, 'message'>
      ) | (
        { __typename: 'AccessDeniedError' }
        & Pick<AccessDeniedError, 'message' | 'action'>
      ) }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'rule' | 'message' | 'originalValue'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message' | 'action'>
  ) }
);

export type GetImportResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetImportResourcesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'GetImportResourcesResult' }
    & Pick<GetImportResourcesResult, 'total'>
    & { resources: Array<(
      { __typename?: 'ImportResource' }
      & Pick<ImportResource, 'id' | 'displayName'>
    )> }
  ) | { __typename?: 'ValidationErrors' } | { __typename?: 'AccessDeniedError' } }
);

export type MergeMakesMutationVariables = Exact<{
  input: MergeMakesInput;
}>;


export type MergeMakesMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'MergeMakesResult' }
    & Pick<MergeMakesResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type UpdateImportMakePriorityMutationVariables = Exact<{
  input: UpdateImportMakePriorityInput;
}>;


export type UpdateImportMakePriorityMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateImportMakePriorityResult' }
    & Pick<UpdateImportMakePriorityResult, 'id' | 'makeId' | 'resourceId' | 'priority'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | { __typename?: 'NotFoundError' } | (
    { __typename?: 'MakeNotFoundError' }
    & Pick<MakeNotFoundError, 'id' | 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type UpdateMakeMutationVariables = Exact<{
  input: UpdateMakeInput;
}>;


export type UpdateMakeMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateMakeResult' }
    & { make: (
      { __typename?: 'FindMake' }
      & Pick<FindMake, 'id' | 'slug' | 'title' | 'offersTotal' | 'countryId'>
      & { importOptions: (
        { __typename: 'MakeImportOptionsResult' }
        & { priority: Array<(
          { __typename?: 'ImportPriority' }
          & Pick<ImportPriority, 'id' | 'priority'>
          & { resource: (
            { __typename?: 'ImportResource' }
            & Pick<ImportResource, 'id' | 'displayName'>
          ) }
        )> }
      ) | (
        { __typename: 'InternalError' }
        & Pick<InternalError, 'message'>
      ) | (
        { __typename: 'AccessDeniedError' }
        & Pick<AccessDeniedError, 'message' | 'action'>
      ) }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindUsersGroupsQueryVariables = Exact<{
  input: FindUserGroupsInput;
}>;


export type FindUsersGroupsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindUserGroupsResult' }
    & Pick<FindUserGroupsResult, 'total'>
    & { groups: Array<(
      { __typename?: 'FindUserGroup' }
      & Pick<FindUserGroup, 'id' | 'slug' | 'title'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )> }
  ) }
);

export type ManageUsersQueryVariables = Exact<{
  input: ManageUsersInput;
}>;


export type ManageUsersQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'ManageUsersResult' }
    & Pick<ManageUsersResult, 'total'>
    & { users: Array<(
      { __typename?: 'ManageUser' }
      & Pick<ManageUser, 'email' | 'name' | 'id' | 'phone' | 'locale' | 'isActive' | 'isUssActive' | 'groupId' | 'isCanBid' | 'deposit' | 'balance'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message' | 'action'>
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'rule' | 'message' | 'originalValue'>
    )> }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename: 'UpdateUserResult' }
    & { user: (
      { __typename?: 'ManageUser' }
      & Pick<ManageUser, 'id' | 'isUssActive' | 'groupId'>
    ) }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message' | 'action'>
  ) | (
    { __typename: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateImportModelPriorityMutationVariables = Exact<{
  input: CreateImportModelPriorityInput;
}>;


export type CreateImportModelPriorityMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateImportModelPriorityResult' }
    & Pick<CreateImportModelPriorityResult, 'id' | 'modelId' | 'resourceId' | 'priority'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'ModelNotFoundError' }
    & Pick<ModelNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateModelMutationVariables = Exact<{
  input: CreateModelInput;
}>;


export type CreateModelMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateModelResult' }
    & { model: (
      { __typename?: 'FindModel' }
      & Pick<FindModel, 'id' | 'slug' | 'title' | 'offersTotal' | 'MakeID' | 'Position' | 'isImportant'>
      & { importOptions: (
        { __typename: 'ModelImportOptionsResult' }
        & { priority: Array<(
          { __typename?: 'ImportPriority' }
          & Pick<ImportPriority, 'id' | 'priority'>
          & { resource: (
            { __typename?: 'ImportResource' }
            & Pick<ImportResource, 'id' | 'displayName'>
          ) }
        )> }
      ) | (
        { __typename: 'InternalError' }
        & Pick<InternalError, 'message'>
      ) | (
        { __typename: 'AccessDeniedError' }
        & Pick<AccessDeniedError, 'message' | 'action'>
      ) }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteImportModelPriorityMutationVariables = Exact<{
  input: DeleteImportModelPriorityInput;
}>;


export type DeleteImportModelPriorityMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeleteImportModelPriorityResult' }
    & Pick<DeleteImportModelPriorityResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteModelMutationVariables = Exact<{
  input: DeleteModelInput;
}>;


export type DeleteModelMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeleteModelResult' }
    & Pick<DeleteModelResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindModelsQueryVariables = Exact<{
  input: FindModelsInput;
}>;


export type FindModelsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindModelsResult' }
    & Pick<FindModelsResult, 'total'>
    & { models: Array<(
      { __typename?: 'FindModel' }
      & Pick<FindModel, 'id' | 'slug' | 'title' | 'offersTotal' | 'MakeID' | 'Position' | 'isImportant'>
      & { importOptions: (
        { __typename: 'ModelImportOptionsResult' }
        & { priority: Array<(
          { __typename?: 'ImportPriority' }
          & Pick<ImportPriority, 'id' | 'priority'>
          & { resource: (
            { __typename?: 'ImportResource' }
            & Pick<ImportResource, 'id' | 'displayName'>
          ) }
        )> }
      ) | (
        { __typename: 'InternalError' }
        & Pick<InternalError, 'message'>
      ) | (
        { __typename: 'AccessDeniedError' }
        & Pick<AccessDeniedError, 'message' | 'action'>
      ) }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'rule' | 'message' | 'originalValue'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message' | 'action'>
  ) }
);

export type MergeModelsMutationVariables = Exact<{
  input: MergeModelsInput;
}>;


export type MergeModelsMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'MergeModelsResult' }
    & Pick<MergeModelsResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type UpdateImportModelPriorityMutationVariables = Exact<{
  input: UpdateImportModelPriorityInput;
}>;


export type UpdateImportModelPriorityMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateImportModelPriorityResult' }
    & Pick<UpdateImportModelPriorityResult, 'id' | 'modelId' | 'resourceId' | 'priority'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | { __typename?: 'NotFoundError' } | (
    { __typename?: 'ModelNotFoundError' }
    & Pick<ModelNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type UpdateModelMutationVariables = Exact<{
  input: UpdateModelInput;
}>;


export type UpdateModelMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateModelResult' }
    & { model: (
      { __typename?: 'FindModel' }
      & Pick<FindModel, 'id' | 'slug' | 'title' | 'offersTotal' | 'MakeID' | 'Position' | 'isImportant'>
      & { importOptions: (
        { __typename: 'ModelImportOptionsResult' }
        & { priority: Array<(
          { __typename?: 'ImportPriority' }
          & Pick<ImportPriority, 'id' | 'priority'>
          & { resource: (
            { __typename?: 'ImportResource' }
            & Pick<ImportResource, 'id' | 'displayName'>
          ) }
        )> }
      ) | (
        { __typename: 'InternalError' }
        & Pick<InternalError, 'message'>
      ) | (
        { __typename: 'AccessDeniedError' }
        & Pick<AccessDeniedError, 'message' | 'action'>
      ) }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateModificationMutationVariables = Exact<{
  input: CreateModificationInput;
}>;


export type CreateModificationMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateModificationResult' }
    & { modification: (
      { __typename?: 'FindModification' }
      & Pick<FindModification, 'id' | 'modelId' | 'slug' | 'title' | 'offersTotal'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteModificationMutationVariables = Exact<{
  input: DeleteModificationInput;
}>;


export type DeleteModificationMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeleteModificationResult' }
    & Pick<DeleteModificationResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindModificationsQueryVariables = Exact<{
  input: FindModificationsInput;
}>;


export type FindModificationsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindModificationsResult' }
    & Pick<FindModificationsResult, 'total'>
    & { modifications: Array<(
      { __typename?: 'FindModification' }
      & Pick<FindModification, 'id' | 'modelId' | 'slug' | 'title' | 'offersTotal'>
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'rule' | 'message' | 'originalValue'>
    )> }
  ) | (
    { __typename: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message' | 'action'>
  ) }
);

export type MergeModificationsMutationVariables = Exact<{
  input: MergeModificationsInput;
}>;


export type MergeModificationsMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'MergeModificationsResult' }
    & Pick<MergeModificationsResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type UpdateModificationMutationVariables = Exact<{
  input: UpdateModificationInput;
}>;


export type UpdateModificationMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateModificationResult' }
    & { modification: (
      { __typename?: 'FindModification' }
      & Pick<FindModification, 'id' | 'modelId' | 'slug' | 'title' | 'offersTotal'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreatePageMutationVariables = Exact<{
  input: CreatePageInput;
}>;


export type CreatePageMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreatePageResult' }
    & { page: (
      { __typename?: 'FindPage' }
      & Pick<FindPage, 'id' | 'userId' | 'urlPath' | 'type' | 'displayName' | 'canonical' | 'parentId' | 'isActive'>
      & { payload: (
        { __typename?: 'PagePayloadDefault' }
        & Pick<PagePayloadDefault, 'stub'>
      ) | (
        { __typename?: 'PagePayloadMain' }
        & Pick<PagePayloadMain, 'stub'>
      ) | (
        { __typename?: 'PagePayloadSearchOffers' }
        & Pick<PagePayloadSearchOffers, 'offerKind' | 'makeSlug' | 'modelSlug'>
      ) | (
        { __typename?: 'PagePayloadCardOffer' }
        & Pick<PagePayloadCardOffer, 'offerKind' | 'offerId'>
      ), translations: Array<(
        { __typename?: 'PageTranslation' }
        & Pick<PageTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | { __typename?: 'InvalidUrlError' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeletePageMutationVariables = Exact<{
  input: DeletePageInput;
}>;


export type DeletePageMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeletePageResult' }
    & Pick<DeletePageResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindPagesQueryVariables = Exact<{
  input: FindPagesInput;
}>;


export type FindPagesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | { __typename: 'PageNotFoundError' } | { __typename: 'InvalidUrlError' } | (
    { __typename: 'FindPagesResult' }
    & { pages: Array<(
      { __typename?: 'FindPage' }
      & Pick<FindPage, 'id' | 'userId' | 'urlPath' | 'type' | 'displayName' | 'canonical' | 'parentId' | 'isActive'>
      & { payload: (
        { __typename?: 'PagePayloadDefault' }
        & Pick<PagePayloadDefault, 'stub'>
      ) | (
        { __typename?: 'PagePayloadMain' }
        & Pick<PagePayloadMain, 'stub'>
      ) | (
        { __typename?: 'PagePayloadSearchOffers' }
        & Pick<PagePayloadSearchOffers, 'offerKind' | 'makeSlug' | 'modelSlug'>
      ) | (
        { __typename?: 'PagePayloadCardOffer' }
        & Pick<PagePayloadCardOffer, 'offerKind' | 'offerId'>
      ), translations: Array<(
        { __typename?: 'PageTranslation' }
        & Pick<PageTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) }
);

export type UpdatePageMutationVariables = Exact<{
  input: UpdatePageInput;
}>;


export type UpdatePageMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdatePageResult' }
    & { page: (
      { __typename?: 'FindPage' }
      & Pick<FindPage, 'id' | 'userId' | 'urlPath' | 'type' | 'displayName' | 'canonical' | 'parentId' | 'isActive'>
      & { payload: (
        { __typename?: 'PagePayloadDefault' }
        & Pick<PagePayloadDefault, 'stub'>
      ) | (
        { __typename?: 'PagePayloadMain' }
        & Pick<PagePayloadMain, 'stub'>
      ) | (
        { __typename?: 'PagePayloadSearchOffers' }
        & Pick<PagePayloadSearchOffers, 'offerKind' | 'makeSlug' | 'modelSlug'>
      ) | (
        { __typename?: 'PagePayloadCardOffer' }
        & Pick<PagePayloadCardOffer, 'offerKind' | 'offerId'>
      ), translations: Array<(
        { __typename?: 'PageTranslation' }
        & Pick<PageTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | { __typename?: 'InvalidUrlError' } | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput;
}>;


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateProjectResult' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'projectKey' | 'displayName' | 'countries' | 'currency' | 'localeId'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteProjectMutationVariables = Exact<{
  input: DeleteProjectInput;
}>;


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'DeleteProjectResult' }
    & Pick<DeleteProjectResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindProjectsQueryVariables = Exact<{
  input: FindProjectsInput;
}>;


export type FindProjectsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindProjectsResult' }
    & Pick<FindProjectsResult, 'total'>
    & { projects: Array<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'projectKey' | 'displayName' | 'countries' | 'currency' | 'localeId'>
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | { __typename: 'AccessDeniedError' } }
);

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput;
}>;


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateProjectResult' }
    & { project: (
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'projectKey' | 'displayName' | 'countries' | 'currency' | 'localeId'>
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type CreateRateMutationVariables = Exact<{
  input: CreateRateInput;
}>;


export type CreateRateMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateRateResult' }
    & { rate: (
      { __typename?: 'Rate' }
      & Pick<Rate, 'id' | 'slug' | 'title' | 'position'>
      & { group: (
        { __typename?: 'RateGroup' }
        & Pick<RateGroup, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'RateGroupNotFoundError' }
    & Pick<RateGroupNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteRateMutationVariables = Exact<{
  input: DeleteRateInput;
}>;


export type DeleteRateMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'DeleteRateResult' }
    & Pick<DeleteRateResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type FindRatesQueryVariables = Exact<{
  input: FindRatesInput;
}>;


export type FindRatesQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindRatesResult' }
    & Pick<FindRatesResult, 'total'>
    & { rates: Array<(
      { __typename?: 'Rate' }
      & Pick<Rate, 'id' | 'slug' | 'title' | 'position'>
      & { group: (
        { __typename?: 'RateGroup' }
        & Pick<RateGroup, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) }
);

export type UpdateRateMutationVariables = Exact<{
  input: UpdateRateInput;
}>;


export type UpdateRateMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateRateResult' }
    & { rate: (
      { __typename?: 'Rate' }
      & Pick<Rate, 'id' | 'slug' | 'title' | 'position'>
      & { group: (
        { __typename?: 'RateGroup' }
        & Pick<RateGroup, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message' | 'field'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'RateGroupNotFoundError' }
    & Pick<RateGroupNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type FindOffersTranslationsQueryVariables = Exact<{
  input: FindOffersTranslationsInput;
}>;


export type FindOffersTranslationsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindOffersTranslationsResult' }
    & Pick<FindOffersTranslationsResult, 'total'>
    & { translations: Array<(
      { __typename?: 'OfferTranslation' }
      & Pick<OfferTranslation, 'id' | 'text' | 'status' | 'offerID' | 'createdAt' | 'updatedAt' | 'translatorUserID' | 'isReaded'>
      & { requests?: Maybe<Array<(
        { __typename?: 'OfferTranslationRequest' }
        & OfferTranslationsRequestFragmentFragment
      )>>, offer?: Maybe<(
        { __typename: 'OfferAuction' }
        & OfferAuctionForTranslationFragmentFragment
      ) | (
        { __typename: 'OfferNotFound' }
        & Pick<OfferNotFound, 'id' | 'message'>
      )> }
    )> }
  ) | (
    { __typename: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )> }
  ) }
);

export type FindTranslatorsQueryVariables = Exact<{ [key: string]: never; }>;


export type FindTranslatorsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindTranslatorsResult' }
    & { translators: Array<(
      { __typename?: 'Translator' }
      & Pick<Translator, 'userId' | 'userName' | 'userEmail' | 'count'>
    )> }
  ) | (
    { __typename: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type OfferAuctionForTranslationFragmentFragment = (
  { __typename?: 'OfferAuction' }
  & Pick<OfferAuction, 'auctionAt' | 'auctionListImage' | 'createdAt' | 'engineVolume' | 'equip' | 'customFinishPrice' | 'id' | 'images' | 'imagesIds' | 'imagesTotal' | 'auctionListImages' | 'auctionListImagesIds' | 'auctionStatus' | 'kind' | 'lot' | 'userHasBid' | 'isAuctionEnded' | 'mileage' | 'power' | 'seats' | 'serial' | 'status' | 'updatedAt' | 'isUss' | 'year'>
  & { auction: (
    { __typename?: 'PropertyDictionary' }
    & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
  ), averagePrice: (
    { __typename?: 'OfferPrice' }
    & Pick<OfferPrice, 'USD' | 'RUB' | 'EUR' | 'JPY'>
  ), bodyType: (
    { __typename?: 'PropertyDictionary' }
    & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
  ), chassis: (
    { __typename?: 'OfferChassis' }
    & Pick<OfferChassis, 'id' | 'slug' | 'title'>
  ), color: (
    { __typename?: 'PropertyDictionary' }
    & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
  ), finishPrice: (
    { __typename?: 'OfferPrice' }
    & Pick<OfferPrice, 'USD' | 'RUB' | 'EUR' | 'JPY'>
  ), fuel: (
    { __typename?: 'PropertyDictionary' }
    & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
  ), gear?: Maybe<Array<(
    { __typename?: 'PropertyDictionary' }
    & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
  )>>, make: (
    { __typename?: 'OfferMake' }
    & Pick<OfferMake, 'id' | 'slug' | 'title'>
  ), model: (
    { __typename?: 'OfferModel' }
    & Pick<OfferModel, 'id' | 'slug' | 'title'>
  ), modification: (
    { __typename?: 'OfferModification' }
    & Pick<OfferModification, 'id' | 'slug' | 'title'>
  ), price: (
    { __typename?: 'OfferPrice' }
    & Pick<OfferPrice, 'USD' | 'RUB' | 'EUR' | 'JPY'>
  ), rate: (
    { __typename?: 'PropertyDictionary' }
    & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
  ), startPrice: (
    { __typename?: 'OfferPrice' }
    & Pick<OfferPrice, 'USD' | 'RUB' | 'EUR' | 'JPY'>
  ), steering: (
    { __typename?: 'PropertyDictionary' }
    & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
  ), transmission: (
    { __typename?: 'PropertyDictionary' }
    & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
  ), translation: (
    { __typename?: 'OfferAuctionTranslation' }
    & Pick<OfferAuctionTranslation, 'isReaded' | 'text' | 'userHasTranslationRequest'>
  ), transmissionType: (
    { __typename?: 'PropertyDictionary' }
    & Pick<PropertyDictionary, 'id' | 'slug' | 'title'>
  ), actions: (
    { __typename?: 'OfferAuctionAction' }
    & Pick<OfferAuctionAction, 'canGetImages'>
  ), urlPage: (
    { __typename?: 'Url' }
    & Pick<Url, 'fullUrl' | 'host' | 'path' | 'scheme'>
    & { query: Array<(
      { __typename?: 'QueryParam' }
      & Pick<QueryParam, 'param' | 'value'>
    )> }
  ), dataReady: (
    { __typename?: 'OfferAuctionDataReady' }
    & Pick<OfferAuctionDataReady, 'isDataReady' | 'isImagesReady'>
  ) }
);

export type OfferTranslationsRequestFragmentFragment = (
  { __typename?: 'OfferTranslationRequest' }
  & Pick<OfferTranslationRequest, 'userID' | 'offerID' | 'hasBid' | 'isReaded' | 'createdAt' | 'updatedAt'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type UpdateOfferTranslationMutationVariables = Exact<{
  input: UpdateOfferTranslationInput;
}>;


export type UpdateOfferTranslationMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename: 'UpdateOfferTranslationResult' }
    & { translation: (
      { __typename?: 'OfferTranslation' }
      & Pick<OfferTranslation, 'id' | 'text' | 'status' | 'offerID' | 'createdAt' | 'updatedAt' | 'translatorUserID' | 'isReaded'>
      & { requests?: Maybe<Array<(
        { __typename?: 'OfferTranslationRequest' }
        & OfferTranslationsRequestFragmentFragment
      )>> }
    ) }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )> }
  ) | (
    { __typename: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) }
);

export type CreateTransmissionMutationVariables = Exact<{
  input: CreateTransmissionInput;
}>;


export type CreateTransmissionMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'CreateTransmissionResult' }
    & { transmission: (
      { __typename?: 'Transmission' }
      & Pick<Transmission, 'id' | 'title' | 'slug' | 'position'>
      & { type: (
        { __typename?: 'TransmissionType' }
        & Pick<TransmissionType, 'id' | 'slug' | 'title'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'TransmissionTypeNotFoundError' }
    & Pick<TransmissionTypeNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type DeleteTransmissionMutationVariables = Exact<{
  input: DeleteTransmissionInput;
}>;


export type DeleteTransmissionMutation = (
  { __typename?: 'Mutation' }
  & { result?: Maybe<(
    { __typename?: 'DeleteTransmissionResult' }
    & Pick<DeleteTransmissionResult, 'ok'>
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  )> }
);

export type FindTransmissionsQueryVariables = Exact<{
  input: FindTransmissionsInput;
}>;


export type FindTransmissionsQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename: 'FindTransmissionsResult' }
    & Pick<FindTransmissionsResult, 'total'>
    & { transmissions: Array<(
      { __typename?: 'Transmission' }
      & Pick<Transmission, 'id' | 'title' | 'slug' | 'position'>
      & { type: (
        { __typename?: 'TransmissionType' }
        & Pick<TransmissionType, 'id' | 'title' | 'slug'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    )> }
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message'>
    )> }
  ) }
);

export type UpdateTransmissionMutationVariables = Exact<{
  input: UpdateTransmissionInput;
}>;


export type UpdateTransmissionMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename?: 'UpdateTransmissionResult' }
    & { transmission: (
      { __typename?: 'Transmission' }
      & Pick<Transmission, 'id' | 'title' | 'slug' | 'position'>
      & { type: (
        { __typename?: 'TransmissionType' }
        & Pick<TransmissionType, 'id' | 'slug' | 'title'>
      ), translations: Array<(
        { __typename?: 'DictValueTranslation' }
        & Pick<DictValueTranslation, 'locale' | 'field' | 'other'>
      )> }
    ) }
  ) | (
    { __typename?: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'message'>
    )> }
  ) | (
    { __typename?: 'AccessDeniedError' }
    & Pick<AccessDeniedError, 'message'>
  ) | (
    { __typename?: 'TransmissionTypeNotFoundError' }
    & Pick<TransmissionTypeNotFoundError, 'message'>
  ) | (
    { __typename?: 'AlreadyExistsError' }
    & Pick<AlreadyExistsError, 'message'>
  ) | (
    { __typename?: 'NotFoundError' }
    & Pick<NotFoundError, 'message'>
  ) | (
    { __typename?: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) }
);

export type GetUserContextQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserContextQuery = (
  { __typename?: 'Query' }
  & { result: (
    { __typename?: 'UserContext' }
    & Pick<UserContext, 'userId' | 'username' | 'countryId' | 'isCanBid' | 'isUssActive' | 'deposit' | 'allowActions' | 'userGroup'>
  ) }
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename: 'LoginResult' }
    & Pick<LoginResult, 'ok'>
  ) | (
    { __typename: 'ServiceError' }
    & Pick<ServiceError, 'message'>
  ) | (
    { __typename: 'UserNotFoundError' }
    & Pick<UserNotFoundError, 'message'>
  ) | (
    { __typename: 'InvalidCredentialsError' }
    & Pick<InvalidCredentialsError, 'message'>
  ) | (
    { __typename: 'ValidationErrors' }
    & { errors: Array<(
      { __typename?: 'ValidationError' }
      & Pick<ValidationError, 'field' | 'message'>
    )> }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { result: (
    { __typename: 'LogoutResult' }
    & Pick<LogoutResult, 'ok'>
  ) }
);

export const TopAutoListAssetFragmentDoc = gql`
    fragment TopAutoListAsset on BlockModelAsset {
  id
  make {
    title
  }
  model {
    title
  }
  bigImage
  middleImage
  smallImage
}
    `;
export const BlockModelItemFragmentDoc = gql`
    fragment BlockModelItem on BlockModelItem {
  id
  blockId
  asset {
    ...TopAutoListAsset
  }
  offerKind
}
    ${TopAutoListAssetFragmentDoc}`;
export const BidUserFragmentFragmentDoc = gql`
    fragment BidUserFragment on User {
  id
  name
  phone
  email
  locale
  groupId
  zipCode
  address
  legacyUserID
  balance
  email
}
    `;
export const OfferAuctionForTranslationFragmentFragmentDoc = gql`
    fragment OfferAuctionForTranslationFragment on OfferAuction {
  auction {
    id
    slug
    title
  }
  auctionAt
  auctionListImage
  averagePrice {
    USD
    RUB
    EUR
    JPY
  }
  bodyType {
    id
    slug
    title
  }
  chassis {
    id
    slug
    title
  }
  color {
    id
    slug
    title
  }
  createdAt
  engineVolume
  equip
  customFinishPrice
  finishPrice(keepOriginal: true) {
    USD
    RUB
    EUR
    JPY
  }
  fuel {
    id
    slug
    title
  }
  gear {
    id
    slug
    title
  }
  id
  images
  imagesIds
  imagesTotal
  auctionListImages
  auctionListImagesIds
  auctionStatus
  kind
  lot
  userHasBid
  isAuctionEnded
  make {
    id
    slug
    title
  }
  mileage
  model {
    id
    slug
    title
  }
  modification {
    id
    slug
    title
  }
  power
  price {
    USD
    RUB
    EUR
    JPY
  }
  rate {
    id
    slug
    title
  }
  seats
  serial
  startPrice {
    USD
    RUB
    EUR
    JPY
  }
  status
  steering {
    id
    slug
    title
  }
  transmission {
    id
    slug
    title
  }
  translation {
    isReaded
    text
    userHasTranslationRequest
  }
  transmissionType {
    id
    slug
    title
  }
  updatedAt
  actions {
    canGetImages
  }
  urlPage {
    fullUrl
    host
    path
    query {
      param
      value
    }
    scheme
  }
  dataReady {
    isDataReady
    isImagesReady
  }
  isUss
  year
}
    `;
export const OfferTranslationsRequestFragmentFragmentDoc = gql`
    fragment OfferTranslationsRequestFragment on OfferTranslationRequest {
  userID
  user {
    id
    name
  }
  offerID
  hasBid
  isReaded
  createdAt
  updatedAt
}
    `;
export const CreateBlockModelItemDocument = gql`
    mutation createBlockModelItem($input: CreateBlockModelItemInput!) {
  result: createBlockModelItem(input: $input) {
    ... on CreateBlockModelItemResult {
      item {
        ...BlockModelItem
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on BlockModelAssetNotFoundError {
      message
    }
    ... on BlockModelItemAlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    ${BlockModelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBlockModelItemGQL extends Apollo.Mutation<CreateBlockModelItemMutation, CreateBlockModelItemMutationVariables> {
    document = CreateBlockModelItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTopAutoAssetDocument = gql`
    mutation createTopAutoAsset($input: CreateBlockModelAssetInput!) {
  result: createBlockModelAsset(input: $input) {
    ... on BlockModelAsset {
      id
    }
    ... on AccessDeniedError {
      action
      message
    }
    ... on BlockModelAssetAlreadyExistsError {
      message
    }
    ... on BlockModelImageNotFoundError {
      id
      message
    }
    ... on MakeNotFoundError {
      id
      message
    }
    ... on ModelNotFoundError {
      id
      message
    }
    ... on ServiceError {
      message
    }
    ... on ServiceErrorInterface {
      message
    }
    ... on ValidationErrors {
      errors {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTopAutoAssetGQL extends Apollo.Mutation<CreateTopAutoAssetMutation, CreateTopAutoAssetMutationVariables> {
    document = CreateTopAutoAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBlockModelItemDocument = gql`
    mutation deleteBlockModelItem($input: DeleteBlockModelItemInput!) {
  result: deleteBlockModelItem(input: $input) {
    ... on DeleteBlockModelItemResult {
      ok
    }
    ... on AccessDeniedError {
      message
    }
    ... on BlockModelItemNotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBlockModelItemGQL extends Apollo.Mutation<DeleteBlockModelItemMutation, DeleteBlockModelItemMutationVariables> {
    document = DeleteBlockModelItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTopAutoAssetDocument = gql`
    mutation deleteTopAutoAsset($input: DeleteBlockModelAssetInput!) {
  result: deleteBlockModelAsset(input: $input) {
    ... on AccessDeniedError {
      message
    }
    ... on BlockModelAssetNotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
    ... on ServiceErrorInterface {
      message
    }
    ... on ValidationErrors {
      errors {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTopAutoAssetGQL extends Apollo.Mutation<DeleteTopAutoAssetMutation, DeleteTopAutoAssetMutationVariables> {
    document = DeleteTopAutoAssetDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindBlockModelItemsDocument = gql`
    query findBlockModelItems($input: FindBlockModelItemsInput!) {
  result: findBlockModelItems(input: $input) {
    ... on FindBlockModelItemsResult {
      items {
        ...BlockModelItem
      }
      total
    }
    ... on AccessDeniedError {
      message
    }
    ... on ServiceError {
      message
    }
    ... on ValidationErrors {
      errors {
        message
      }
    }
  }
}
    ${BlockModelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindBlockModelItemsGQL extends Apollo.Query<FindBlockModelItemsQuery, FindBlockModelItemsQueryVariables> {
    document = FindBlockModelItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindTopAutoAssetsDocument = gql`
    query findTopAutoAssets($input: FindBlockModelAssetsInput!) {
  result: findBlockModelAssets(input: $input) {
    ... on FindBlockModelAssetsResult {
      total
      assets {
        ...TopAutoListAsset
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on ServiceError {
      message
    }
    ... on ServiceErrorInterface {
      message
    }
  }
}
    ${TopAutoListAssetFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindTopAutoAssetsGQL extends Apollo.Query<FindTopAutoAssetsQuery, FindTopAutoAssetsQueryVariables> {
    document = FindTopAutoAssetsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadTopAutoImageDocument = gql`
    mutation uploadTopAutoImage($file: UploadBlockModelImage!) {
  result: uploadBlockModelImage(file: $file) {
    ... on BlockModelImage {
      id
      url
    }
    ... on AccessDeniedError {
      action
      message
    }
    ... on ServiceError {
      message
    }
    ... on ServiceErrorInterface {
      message
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadTopAutoImageGQL extends Apollo.Mutation<UploadTopAutoImageMutation, UploadTopAutoImageMutationVariables> {
    document = UploadTopAutoImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindAllBidsDocument = gql`
    query findAllBids($input: FindBidsInput!) {
  result: findBids(input: $input) {
    __typename
    ... on FindBidsResult {
      filter {
        auctionDates {
          auctionAt
          count
        }
        auctionNames {
          auctionId
          auctionName
          count
        }
        userNames {
          userId
          userName
          userEmail
          count
        }
        carModels {
          modelId
          modelName
          count
        }
      }
      bids {
        id
        bidRequest
        userId
        offerId
        currency
        typeId
        createdAt
        text
        groupId
        endPrice
        cvrPrice
        isAgree
        auctionId
        auctionAt
        status
        soldStatus
        isAuctionOver
        isConfirmed
        isCanceledByUser
        isCanceledByManager
        isWin
        user {
          ...BidUserFragment
        }
        type {
          id
          slug
          title
          __typename
        }
        group {
          id
          slug
          title
          __typename
        }
        offer {
          __typename
          ... on OfferNotFound {
            id
            message
          }
          ... on OfferAuction {
            auction {
              id
              slug
              title
            }
            auctionAt
            auctionListImage
            averagePrice {
              USD
              RUB
              EUR
              JPY
            }
            bodyType {
              id
              slug
              title
            }
            chassis {
              id
              slug
              title
            }
            color {
              id
              slug
              title
            }
            createdAt
            engineVolume
            equip
            customFinishPrice
            finishPrice(keepOriginal: true) {
              USD
              RUB
              EUR
              JPY
            }
            fuel {
              id
              slug
              title
            }
            gear {
              id
              slug
              title
            }
            id
            images
            imagesIds
            imagesTotal
            auctionListImages
            auctionListImagesIds
            auctionStatus
            kind
            lot
            userHasBid
            isAuctionEnded
            make {
              id
              slug
              title
            }
            mileage
            model {
              id
              slug
              title
            }
            modification {
              id
              slug
              title
            }
            power
            price {
              USD
              RUB
              EUR
              JPY
            }
            rate {
              id
              slug
              title
            }
            seats
            serial
            startPrice {
              USD
              RUB
              EUR
              JPY
            }
            status
            steering {
              id
              slug
              title
            }
            transmission {
              id
              slug
              title
            }
            translation {
              isReaded
              text
              userHasTranslationRequest
            }
            transmissionType {
              id
              slug
              title
            }
            updatedAt
            actions {
              canGetImages
            }
            urlPage {
              fullUrl
              host
              path
              query {
                param
                value
              }
              scheme
            }
            dataReady {
              isDataReady
              isImagesReady
            }
            isUss
            year
          }
        }
      }
      total
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
  }
}
    ${BidUserFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindAllBidsGQL extends Apollo.Query<FindAllBidsQuery, FindAllBidsQueryVariables> {
    document = FindAllBidsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBidDocument = gql`
    mutation updateBid($input: UpdateBidInput!) {
  result: updateBid(input: $input) {
    ... on UpdateBidResult {
      bid {
        id
        endPrice
        status
      }
    }
    ... on ValidationErrors {
      __typename
      errors {
        field
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBidGQL extends Apollo.Mutation<UpdateBidMutation, UpdateBidMutationVariables> {
    document = UpdateBidDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBlockLayoutPlacementDocument = gql`
    mutation createBlockLayoutPlacement($input: CreateBlockLayoutPlacementInput!) {
  result: createBlockLayoutPlacement(input: $input) {
    ... on CreateBlockLayoutPlacementResult {
      blockLayoutPlacement {
        layoutId
        blockId
        isVisible
        position
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBlockLayoutPlacementGQL extends Apollo.Mutation<CreateBlockLayoutPlacementMutation, CreateBlockLayoutPlacementMutationVariables> {
    document = CreateBlockLayoutPlacementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBlockLayoutPlacementDocument = gql`
    mutation deleteBlockLayoutPlacement($input: DeleteBlockLayoutPlacementInput!) {
  result: deleteBlockLayoutPlacement(input: $input) {
    ... on DeleteBlockLayoutPlacementResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBlockLayoutPlacementGQL extends Apollo.Mutation<DeleteBlockLayoutPlacementMutation, DeleteBlockLayoutPlacementMutationVariables> {
    document = DeleteBlockLayoutPlacementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindBlockLayoutPlacementsDocument = gql`
    query findBlockLayoutPlacements($input: FindBlockLayoutPlacementsInput!) {
  result: findBlockLayoutPlacements(input: $input) {
    __typename
    ... on FindBlockLayoutPlacementsResult {
      blockLayoutPlacements {
        layoutId
        blockId
        isVisible
        position
      }
      total
    }
    ... on AccessDeniedError {
      message
      action
    }
    ... on ValidationErrors {
      errors {
        field
        rule
        message
        originalValue
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindBlockLayoutPlacementsGQL extends Apollo.Query<FindBlockLayoutPlacementsQuery, FindBlockLayoutPlacementsQueryVariables> {
    document = FindBlockLayoutPlacementsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBlockLayoutPlacementDocument = gql`
    mutation updateBlockLayoutPlacement($input: UpdateBlockLayoutPlacementInput!) {
  result: updateBlockLayoutPlacement(input: $input) {
    ... on UpdateBlockLayoutPlacementResult {
      blockLayoutPlacement {
        layoutId
        blockId
        isVisible
        position
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBlockLayoutPlacementGQL extends Apollo.Mutation<UpdateBlockLayoutPlacementMutation, UpdateBlockLayoutPlacementMutationVariables> {
    document = UpdateBlockLayoutPlacementDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBlockLayoutDocument = gql`
    mutation createBlockLayout($input: CreateBlockLayoutInput!) {
  result: createBlockLayout(input: $input) {
    ... on CreateBlockLayoutResult {
      blockLayout {
        id
        projectId
        isVisible
        slug
        displayName
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBlockLayoutGQL extends Apollo.Mutation<CreateBlockLayoutMutation, CreateBlockLayoutMutationVariables> {
    document = CreateBlockLayoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBlockLayoutDocument = gql`
    mutation deleteBlockLayout($input: DeleteBlockLayoutInput!) {
  result: deleteBlockLayout(input: $input) {
    ... on DeleteBlockLayoutResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBlockLayoutGQL extends Apollo.Mutation<DeleteBlockLayoutMutation, DeleteBlockLayoutMutationVariables> {
    document = DeleteBlockLayoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindBlockLayoutsDocument = gql`
    query findBlockLayouts($input: FindBlockLayoutsInput!) {
  result: findBlockLayouts(input: $input) {
    __typename
    ... on FindBlockLayoutsResult {
      blockLayouts {
        id
        projectId
        isVisible
        slug
        displayName
      }
      total
    }
    ... on AccessDeniedError {
      message
      action
    }
    ... on ValidationErrors {
      errors {
        field
        rule
        message
        originalValue
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindBlockLayoutsGQL extends Apollo.Query<FindBlockLayoutsQuery, FindBlockLayoutsQueryVariables> {
    document = FindBlockLayoutsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GenerateBlocksDocument = gql`
    mutation generateBlocks($input: GenerateBlocksInput!) {
  result: generateBlocks(input: $input) {
    ... on GenerateBlocksResult {
      ok
    }
    ... on AccessDeniedError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GenerateBlocksGQL extends Apollo.Mutation<GenerateBlocksMutation, GenerateBlocksMutationVariables> {
    document = GenerateBlocksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBlockLayoutDocument = gql`
    mutation updateBlockLayout($input: UpdateBlockLayoutInput!) {
  result: updateBlockLayout(input: $input) {
    ... on UpdateBlockLayoutResult {
      blockLayout {
        id
        projectId
        isVisible
        slug
        displayName
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBlockLayoutGQL extends Apollo.Mutation<UpdateBlockLayoutMutation, UpdateBlockLayoutMutationVariables> {
    document = UpdateBlockLayoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBlockDocument = gql`
    mutation createBlock($input: CreateBlockInput!) {
  result: createBlock(input: $input) {
    ... on Block {
      id
      type
      view {
        id
        type
        view
        displayName
      }
      name
      header
      subheader
      isActive
      translations {
        locale
        field
        other
      }
      options {
        ... on BlockModelsOptions {
          shuffleSize
          limit
        }
        ... on BlockOffersOptions {
          offerKind
          makeIDAnyOf
          modelIDAnyOf
          bodyTypeIDAnyOf
          yearMin
          yearMax
          engineVolumeMin
          engineVolumeMax
          mileageMin
          mileageMax
          shuffleSize
          limit
        }
      }
    }
    ... on BlockViewNotFoundError {
      message
      id
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBlockGQL extends Apollo.Mutation<CreateBlockMutation, CreateBlockMutationVariables> {
    document = CreateBlockDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBlockDocument = gql`
    mutation deleteBlock($input: DeleteBlockInput!) {
  result: deleteBlock(input: $input) {
    ... on BlockNotFoundError {
      message
      id
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBlockGQL extends Apollo.Mutation<DeleteBlockMutation, DeleteBlockMutationVariables> {
    document = DeleteBlockDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageBlocksDocument = gql`
    query manageBlocks($input: ManageBlocksInput!) {
  result: manageBlocks(input: $input) {
    __typename
    ... on ManageBlocksResult {
      blocks {
        id
        typeID
        viewID
        name
        header
        subheader
        isActive
        translations {
          locale
          field
          other
        }
        options {
          ... on BlockModelsOptions {
            shuffleSize
            limit
          }
          ... on BlockOffersOptions {
            offerKind
            makeIDAnyOf
            modelIDAnyOf
            bodyTypeIDAnyOf
            yearMin
            yearMax
            engineVolumeMin
            engineVolumeMax
            mileageMin
            mileageMax
            shuffleSize
            limit
          }
        }
      }
      total
    }
    ... on AccessDeniedError {
      message
      action
    }
    ... on ValidationErrors {
      errors {
        field
        rule
        message
        originalValue
      }
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageBlocksGQL extends Apollo.Query<ManageBlocksQuery, ManageBlocksQueryVariables> {
    document = ManageBlocksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBlockDocument = gql`
    mutation updateBlock($input: UpdateBlockInput!) {
  result: updateBlock(input: $input) {
    ... on Block {
      id
      type
      view {
        id
        type
        view
        displayName
      }
      name
      header
      subheader
      isActive
      translations {
        locale
        field
        other
      }
      options {
        ... on BlockModelsOptions {
          shuffleSize
          limit
        }
        ... on BlockOffersOptions {
          offerKind
          makeIDAnyOf
          modelIDAnyOf
          bodyTypeIDAnyOf
          yearMin
          yearMax
          engineVolumeMin
          engineVolumeMax
          mileageMin
          mileageMax
          shuffleSize
          limit
        }
      }
    }
    ... on BlockNotFoundError {
      message
      id
    }
    ... on BlockViewNotFoundError {
      message
      id
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBlockGQL extends Apollo.Mutation<UpdateBlockMutation, UpdateBlockMutationVariables> {
    document = UpdateBlockDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBodyTypeDocument = gql`
    mutation createBodyType($input: CreateBodyTypeInput!) {
  result: createBodyType(input: $input) {
    ... on CreateBodyTypeResult {
      bodyType {
        id
        slug
        title
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBodyTypeGQL extends Apollo.Mutation<CreateBodyTypeMutation, CreateBodyTypeMutationVariables> {
    document = CreateBodyTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteBodyTypeDocument = gql`
    mutation deleteBodyType($input: DeleteBodyTypeInput!) {
  result: deleteBodyType(input: $input) {
    ... on DeleteBodyTypeResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteBodyTypeGQL extends Apollo.Mutation<DeleteBodyTypeMutation, DeleteBodyTypeMutationVariables> {
    document = DeleteBodyTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindBodyTypesDocument = gql`
    query findBodyTypes($input: FindBodyTypesInput!) {
  result: findBodyTypes(input: $input) {
    __typename
    ... on FindBodyTypesResult {
      bodyTypes {
        id
        slug
        title
        position
        translations {
          locale
          field
          other
        }
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindBodyTypesGQL extends Apollo.Query<FindBodyTypesQuery, FindBodyTypesQueryVariables> {
    document = FindBodyTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBodyTypeDocument = gql`
    mutation updateBodyType($input: UpdateBodyTypeInput!) {
  result: updateBodyType(input: $input) {
    ... on UpdateBodyTypeResult {
      bodyType {
        id
        slug
        title
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBodyTypeGQL extends Apollo.Mutation<UpdateBodyTypeMutation, UpdateBodyTypeMutationVariables> {
    document = UpdateBodyTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateChassisDocument = gql`
    mutation createChassis($input: CreateChassisInput!) {
  result: createChassis(input: $input) {
    ... on CreateChassisResult {
      chassis {
        id
        modelId
        slug
        title
        offersTotal
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateChassisGQL extends Apollo.Mutation<CreateChassisMutation, CreateChassisMutationVariables> {
    document = CreateChassisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteChassisDocument = gql`
    mutation deleteChassis($input: DeleteChassisInput!) {
  result: deleteChassis(input: $input) {
    ... on DeleteChassisResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteChassisGQL extends Apollo.Mutation<DeleteChassisMutation, DeleteChassisMutationVariables> {
    document = DeleteChassisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindChassisDocument = gql`
    query findChassis($input: FindChassisInput!) {
  result: findChassis(input: $input) {
    __typename
    ... on FindChassisResult {
      chassis {
        id
        modelId
        slug
        title
        offersTotal
      }
      total
    }
    ... on AccessDeniedError {
      message
      action
    }
    ... on ServiceErrorInterface {
      message
    }
    ... on ValidationErrors {
      errors {
        field
        rule
        message
        originalValue
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindChassisGQL extends Apollo.Query<FindChassisQuery, FindChassisQueryVariables> {
    document = FindChassisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MergeChassisDocument = gql`
    mutation mergeChassis($input: MergeChassisInput!) {
  result: mergeChassis(input: $input) {
    ... on MergeChassisResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MergeChassisGQL extends Apollo.Mutation<MergeChassisMutation, MergeChassisMutationVariables> {
    document = MergeChassisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateChassisDocument = gql`
    mutation updateChassis($input: UpdateChassisInput!) {
  result: updateChassis(input: $input) {
    ... on UpdateChassisResult {
      chassis {
        id
        modelId
        slug
        title
        offersTotal
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateChassisGQL extends Apollo.Mutation<UpdateChassisMutation, UpdateChassisMutationVariables> {
    document = UpdateChassisDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateColorDocument = gql`
    mutation createColor($input: CreateColorInput!) {
  result: createColor(input: $input) {
    ... on CreateColorResult {
      color {
        id
        slug
        title
        group {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on ColorGroupNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateColorGQL extends Apollo.Mutation<CreateColorMutation, CreateColorMutationVariables> {
    document = CreateColorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteColorDocument = gql`
    mutation deleteColor($input: DeleteColorInput!) {
  result: deleteColor(input: $input) {
    ... on DeleteColorResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteColorGQL extends Apollo.Mutation<DeleteColorMutation, DeleteColorMutationVariables> {
    document = DeleteColorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindColorsDocument = gql`
    query findColors($input: FindColorsInput!) {
  result: findColors(input: $input) {
    __typename
    ... on FindColorsResult {
      Colors {
        id
        slug
        title
        group {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindColorsGQL extends Apollo.Query<FindColorsQuery, FindColorsQueryVariables> {
    document = FindColorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateColorDocument = gql`
    mutation updateColor($input: UpdateColorInput!) {
  result: updateColor(input: $input) {
    ... on UpdateColorResult {
      color {
        id
        slug
        title
        group {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on ColorGroupNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateColorGQL extends Apollo.Mutation<UpdateColorMutation, UpdateColorMutationVariables> {
    document = UpdateColorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCountryDocument = gql`
    mutation createCountry($input: CreateCountryInput!) {
  createCountry(input: $input) {
    __typename
    ... on ServiceError {
      __typename
      message
    }
    ... on AlreadyExistsError {
      __typename
      entity
      message
    }
    ... on ValidationErrors {
      __typename
      errors {
        __typename
        rule
        field
      }
    }
    ... on CreateCountryResult {
      country {
        id
        slug
        title
        phoneCode
        iso2
        iso3
        iso_numeric
        continent
        capital
        capital_time_zone
        translations {
          locale
          field
          other
        }
        is_active
        is_local_info_active
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCountryGQL extends Apollo.Mutation<CreateCountryMutation, CreateCountryMutationVariables> {
    document = CreateCountryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCountryDocument = gql`
    mutation deleteCountry($input: DeleteCountryInput!) {
  result: deleteCountry(input: $input) {
    ... on DeleteCountryResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCountryGQL extends Apollo.Mutation<DeleteCountryMutation, DeleteCountryMutationVariables> {
    document = DeleteCountryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindCountriesOfLocalInfoDocument = gql`
    query findCountriesOfLocalInfo($input: FindCountriesOfLocalInfoInput!) {
  result: findCountriesOfLocalInfo(input: $input) {
    __typename
    ... on FindCountriesOfLocalInfoResult {
      countries {
        id
        slug
        title
        phoneCode
        iso
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindCountriesOfLocalInfoGQL extends Apollo.Query<FindCountriesOfLocalInfoQuery, FindCountriesOfLocalInfoQueryVariables> {
    document = FindCountriesOfLocalInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageCountriesDocument = gql`
    query manageCountries($input: FindCountriesInput!) {
  result: manageCountries(input: $input) {
    __typename
    ... on ManageCountriesResult {
      countries {
        id
        slug
        title
        phoneCode
        iso2
        iso3
        iso_numeric
        continent
        capital
        capital_time_zone
        translations {
          locale
          field
          other
        }
        is_active
        is_local_info_active
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageCountriesGQL extends Apollo.Query<ManageCountriesQuery, ManageCountriesQueryVariables> {
    document = ManageCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindCountriesDocument = gql`
    query findCountries($input: FindCountriesInput!) {
  result: findCountries(input: $input) {
    __typename
    ... on FindCountriesResult {
      countries {
        id
        slug
        title
        phoneCode
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindCountriesGQL extends Apollo.Query<FindCountriesQuery, FindCountriesQueryVariables> {
    document = FindCountriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCountryDocument = gql`
    mutation updateCountry($input: UpdateCountryInput!) {
  result: updateCountry(input: $input) {
    ... on UpdateCountryResult {
      country {
        id
        slug
        title
        phoneCode
        iso2
        iso3
        iso_numeric
        continent
        capital
        capital_time_zone
        translations {
          locale
          field
          other
        }
        is_active
        is_local_info_active
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCountryGQL extends Apollo.Mutation<UpdateCountryMutation, UpdateCountryMutationVariables> {
    document = UpdateCountryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDictValueDocument = gql`
    mutation createDictValue($input: CreateDictValueInput!) {
  result: createDictValue(input: $input) {
    ... on CreateDictValueResult {
      value {
        id
        slug
        title
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDictValueGQL extends Apollo.Mutation<CreateDictValueMutation, CreateDictValueMutationVariables> {
    document = CreateDictValueDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDictValueDocument = gql`
    mutation deleteDictValue($input: DeleteDictValueInput!) {
  result: deleteDictValue(input: $input) {
    ... on DeleteDictValueResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDictValueGQL extends Apollo.Mutation<DeleteDictValueMutation, DeleteDictValueMutationVariables> {
    document = DeleteDictValueDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindDictGroupsDocument = gql`
    query findDictGroups($input: FindDictGroupsInput!) {
  result: findDictGroups(input: $input) {
    __typename
    ... on FindDictGroupsResult {
      dictGroups {
        id
        name
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindDictGroupsGQL extends Apollo.Query<FindDictGroupsQuery, FindDictGroupsQueryVariables> {
    document = FindDictGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindDictValuesDocument = gql`
    query findDictValues($input: FindDictValuesInput!) {
  result: findDictValues(input: $input) {
    __typename
    ... on FindDictValuesResult {
      dictValues {
        id
        slug
        title
        translations {
          locale
          field
          other
        }
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindDictValuesGQL extends Apollo.Query<FindDictValuesQuery, FindDictValuesQueryVariables> {
    document = FindDictValuesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDictValueDocument = gql`
    mutation updateDictValue($input: UpdateDictValueInput!) {
  result: updateDictValue(input: $input) {
    ... on UpdateDictValueResult {
      value {
        id
        slug
        title
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDictValueGQL extends Apollo.Mutation<UpdateDictValueMutation, UpdateDictValueMutationVariables> {
    document = UpdateDictValueDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateFaqCategoryDocument = gql`
    mutation createFaqCategory($input: CreateFaqCategoryInput!) {
  result: createFaqCategory(input: $input) {
    ... on CreateFaqCategoryResult {
      faqCategory {
        id
        title
        slug
        isActive
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFaqCategoryGQL extends Apollo.Mutation<CreateFaqCategoryMutation, CreateFaqCategoryMutationVariables> {
    document = CreateFaqCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFaqCategoryDocument = gql`
    mutation deleteFaqCategory($input: DeleteFaqCategoryInput!) {
  result: deleteFaqCategory(input: $input) {
    ... on DeleteFaqCategoryResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFaqCategoryGQL extends Apollo.Mutation<DeleteFaqCategoryMutation, DeleteFaqCategoryMutationVariables> {
    document = DeleteFaqCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindFaqCategoriesDocument = gql`
    query findFaqCategories($input: FindFaqCategoriesInput!) {
  result: findFaqCategories(input: $input) {
    __typename
    ... on FindFaqCategoriesResult {
      faqCategories {
        id
        title
        slug
        isActive
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindFaqCategoriesGQL extends Apollo.Query<FindFaqCategoriesQuery, FindFaqCategoriesQueryVariables> {
    document = FindFaqCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetFaqCategoriesPositionsDocument = gql`
    mutation setFaqCategoriesPositions($input: SetFaqCategoriesPositionsInput!) {
  result: setFaqCategoriesPositions(input: $input) {
    __typename
    ... on SetFaqCategoriesPositionsResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
    ... on AccessDeniedError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetFaqCategoriesPositionsGQL extends Apollo.Mutation<SetFaqCategoriesPositionsMutation, SetFaqCategoriesPositionsMutationVariables> {
    document = SetFaqCategoriesPositionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFaqCategoryDocument = gql`
    mutation updateFaqCategory($input: UpdateFaqCategoryInput!) {
  result: updateFaqCategory(input: $input) {
    ... on UpdateFaqCategoryResult {
      faqCategory {
        id
        title
        slug
        isActive
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFaqCategoryGQL extends Apollo.Mutation<UpdateFaqCategoryMutation, UpdateFaqCategoryMutationVariables> {
    document = UpdateFaqCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateFaqDocument = gql`
    mutation createFaq($input: CreateFaqInput!) {
  result: createFaq(input: $input) {
    ... on CreateFaqResult {
      faq {
        id
        categoryId
        question
        answer
        isActive
        inBlock
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFaqGQL extends Apollo.Mutation<CreateFaqMutation, CreateFaqMutationVariables> {
    document = CreateFaqDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFaqDocument = gql`
    mutation deleteFaq($input: DeleteFaqInput!) {
  result: deleteFaq(input: $input) {
    ... on DeleteFaqResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFaqGQL extends Apollo.Mutation<DeleteFaqMutation, DeleteFaqMutationVariables> {
    document = DeleteFaqDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindFaqDocument = gql`
    query findFaq($input: FindFaqInput!) {
  result: findFaq(input: $input) {
    __typename
    ... on FindFaqResult {
      faq {
        id
        categoryId
        question
        answer
        isActive
        inBlock
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindFaqGQL extends Apollo.Query<FindFaqQuery, FindFaqQueryVariables> {
    document = FindFaqDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetFaqPositionsDocument = gql`
    mutation setFaqPositions($input: SetFaqPositionsInput!) {
  result: setFaqPositions(input: $input) {
    __typename
    ... on SetFaqPositionsResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
    ... on AccessDeniedError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetFaqPositionsGQL extends Apollo.Mutation<SetFaqPositionsMutation, SetFaqPositionsMutationVariables> {
    document = SetFaqPositionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFaqDocument = gql`
    mutation updateFaq($input: UpdateFaqInput!) {
  result: updateFaq(input: $input) {
    ... on UpdateFaqResult {
      faq {
        id
        categoryId
        question
        answer
        isActive
        inBlock
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFaqGQL extends Apollo.Mutation<UpdateFaqMutation, UpdateFaqMutationVariables> {
    document = UpdateFaqDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGearDocument = gql`
    mutation createGear($input: CreateGearInput!) {
  result: createGear(input: $input) {
    ... on CreateGearResult {
      gear {
        id
        slug
        title
        type {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on GearTypeNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGearGQL extends Apollo.Mutation<CreateGearMutation, CreateGearMutationVariables> {
    document = CreateGearDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteGearDocument = gql`
    mutation deleteGear($input: DeleteGearInput!) {
  result: deleteGear(input: $input) {
    ... on DeleteGearResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteGearGQL extends Apollo.Mutation<DeleteGearMutation, DeleteGearMutationVariables> {
    document = DeleteGearDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindGearsDocument = gql`
    query findGears($input: FindGearsInput!) {
  result: findGears(input: $input) {
    __typename
    ... on FindGearsResult {
      gears {
        id
        slug
        title
        type {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindGearsGQL extends Apollo.Query<FindGearsQuery, FindGearsQueryVariables> {
    document = FindGearsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGearDocument = gql`
    mutation updateGear($input: UpdateGearInput!) {
  result: updateGear(input: $input) {
    ... on UpdateGearResult {
      gear {
        id
        slug
        title
        type {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on GearTypeNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGearGQL extends Apollo.Mutation<UpdateGearMutation, UpdateGearMutationVariables> {
    document = UpdateGearDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BulkUpdateEntityIdDocument = gql`
    mutation bulkUpdateEntityID($input: BulkUpdateEntityIDInput!) {
  result: bulkUpdateEntityID(input: $input) {
    ... on BulkUpdateEntityIDResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BulkUpdateEntityIdGQL extends Apollo.Mutation<BulkUpdateEntityIdMutation, BulkUpdateEntityIdMutationVariables> {
    document = BulkUpdateEntityIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const BulkUpdateImportMappingDocument = gql`
    mutation bulkUpdateImportMapping($input: BulkUpdateImportMappingsInput!) {
  result: bulkUpdateImportMappings(input: $input) {
    ... on BulkUpdateImportMappingsResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class BulkUpdateImportMappingGQL extends Apollo.Mutation<BulkUpdateImportMappingMutation, BulkUpdateImportMappingMutationVariables> {
    document = BulkUpdateImportMappingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindImportMappingsDocument = gql`
    query findImportMappings($input: FindImportMappingsInput!) {
  result: findImportMappings(input: $input) {
    ... on FindImportMappingsResult {
      mapping {
        id
        rawValue
        resource
        field
        entityType
        entitiesIds
        entities {
          __typename
          ... on Transmission {
            id
            slug
            title
            type {
              id
              slug
              title
            }
            position
            translations {
              locale
              field
              other
            }
          }
          ... on Color {
            id
            slug
            title
            group {
              id
              slug
              title
            }
            position
            translations {
              locale
              field
              other
            }
          }
          ... on Gear {
            id
            slug
            title
            type {
              id
              slug
              title
            }
            position
            translations {
              locale
              field
              other
            }
          }
          ... on Rate {
            id
            slug
            title
            group {
              id
              slug
              title
            }
            position
            translations {
              locale
              field
              other
            }
          }
          ... on ImportMappingEntityNotFoundError {
            id
            message
          }
        }
        isValid
        createdAt
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindImportMappingsGQL extends Apollo.Query<FindImportMappingsQuery, FindImportMappingsQueryVariables> {
    document = FindImportMappingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateImportMappingDocument = gql`
    mutation updateImportMapping($input: UpdateImportMappingInput!) {
  result: updateImportMapping(input: $input) {
    ... on UpdateImportMappingResult {
      mapping {
        id
        rawValue
        resource
        field
        entityType
        entitiesIds
        entities {
          __typename
          ... on Transmission {
            id
            slug
            title
            type {
              id
              slug
              title
            }
            position
            translations {
              locale
              field
              other
            }
          }
          ... on Color {
            id
            slug
            title
            group {
              id
              slug
              title
            }
            position
            translations {
              locale
              field
              other
            }
          }
          ... on Gear {
            id
            slug
            title
            type {
              id
              slug
              title
            }
            position
            translations {
              locale
              field
              other
            }
          }
          ... on Rate {
            id
            slug
            title
            group {
              id
              slug
              title
            }
            position
            translations {
              locale
              field
              other
            }
          }
          ... on ImportMappingEntityNotFoundError {
            id
            message
          }
        }
        isValid
        createdAt
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateImportMappingGQL extends Apollo.Mutation<UpdateImportMappingMutation, UpdateImportMappingMutationVariables> {
    document = UpdateImportMappingDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageLocalInfoDocument = gql`
    query manageLocalInfo($input: ManageLocalInfoInput!) {
  result: manageLocalInfo(input: $input) {
    __typename
    ... on ManageLocalInfoResult {
      items {
        id
        countryId
        countryName
        restriction
        steeringWheelPosition
        destinationPort
        inspection
        documents
        taxes
        registration
        nb
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageLocalInfoGQL extends Apollo.Query<ManageLocalInfoQuery, ManageLocalInfoQueryVariables> {
    document = ManageLocalInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SaveLocalInfoDocument = gql`
    mutation saveLocalInfo($input: SaveLocalInfoInput!) {
  result: saveLocalInfo(input: $input) {
    __typename
    ... on SaveLocalInfoResult {
      localInfo {
        id
        countryId
        countryName
        restriction
        steeringWheelPosition
        destinationPort
        inspection
        documents
        taxes
        registration
        nb
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SaveLocalInfoGQL extends Apollo.Mutation<SaveLocalInfoMutation, SaveLocalInfoMutationVariables> {
    document = SaveLocalInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindLocalesDocument = gql`
    query findLocales($input: FindLocalesInput!) {
  result: findLocales(input: $input) {
    ... on FindLocalesResult {
      locales {
        id
        name
        position
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindLocalesGQL extends Apollo.Query<FindLocalesQuery, FindLocalesQueryVariables> {
    document = FindLocalesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateImportMakePriorityDocument = gql`
    mutation createImportMakePriority($input: CreateImportMakePriorityInput!) {
  result: createImportMakePriority(input: $input) {
    ... on CreateImportMakePriorityResult {
      id
      makeId
      resourceId
      priority
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on MakeNotFoundError {
      id
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateImportMakePriorityGQL extends Apollo.Mutation<CreateImportMakePriorityMutation, CreateImportMakePriorityMutationVariables> {
    document = CreateImportMakePriorityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMakeDocument = gql`
    mutation createMake($input: CreateMakeInput!) {
  result: createMake(input: $input) {
    ... on CreateMakeResult {
      make {
        id
        slug
        title
        offersTotal
        countryId
        importOptions {
          __typename
          ... on InternalError {
            message
          }
          ... on AccessDeniedError {
            message
            action
          }
          ... on MakeImportOptionsResult {
            priority {
              id
              resource {
                id
                displayName
              }
              priority
            }
          }
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMakeGQL extends Apollo.Mutation<CreateMakeMutation, CreateMakeMutationVariables> {
    document = CreateMakeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteImportMakePriorityDocument = gql`
    mutation deleteImportMakePriority($input: DeleteImportMakePriorityInput!) {
  result: deleteImportMakePriority(input: $input) {
    ... on DeleteImportMakePriorityResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteImportMakePriorityGQL extends Apollo.Mutation<DeleteImportMakePriorityMutation, DeleteImportMakePriorityMutationVariables> {
    document = DeleteImportMakePriorityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMakeDocument = gql`
    mutation deleteMake($input: DeleteMakeInput!) {
  result: deleteMake(input: $input) {
    ... on DeleteMakeResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMakeGQL extends Apollo.Mutation<DeleteMakeMutation, DeleteMakeMutationVariables> {
    document = DeleteMakeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindMakesDocument = gql`
    query findMakes($input: FindMakesInput!) {
  result: findMakes(input: $input) {
    __typename
    ... on FindMakesResult {
      makes {
        id
        slug
        title
        offersTotal
        countryId
        importOptions {
          __typename
          ... on InternalError {
            message
          }
          ... on AccessDeniedError {
            message
            action
          }
          ... on MakeImportOptionsResult {
            priority {
              id
              resource {
                id
                displayName
              }
              priority
            }
          }
        }
      }
      total
    }
    ... on AccessDeniedError {
      message
      action
    }
    ... on ServiceErrorInterface {
      message
    }
    ... on ValidationErrors {
      errors {
        field
        rule
        message
        originalValue
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindMakesGQL extends Apollo.Query<FindMakesQuery, FindMakesQueryVariables> {
    document = FindMakesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetImportResourcesDocument = gql`
    query getImportResources {
  result: getImportResources {
    ... on GetImportResourcesResult {
      resources {
        id
        displayName
      }
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetImportResourcesGQL extends Apollo.Query<GetImportResourcesQuery, GetImportResourcesQueryVariables> {
    document = GetImportResourcesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MergeMakesDocument = gql`
    mutation mergeMakes($input: MergeMakesInput!) {
  result: mergeMakes(input: $input) {
    ... on MergeMakesResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MergeMakesGQL extends Apollo.Mutation<MergeMakesMutation, MergeMakesMutationVariables> {
    document = MergeMakesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateImportMakePriorityDocument = gql`
    mutation updateImportMakePriority($input: UpdateImportMakePriorityInput!) {
  result: updateImportMakePriority(input: $input) {
    ... on UpdateImportMakePriorityResult {
      id
      makeId
      resourceId
      priority
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on MakeNotFoundError {
      id
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateImportMakePriorityGQL extends Apollo.Mutation<UpdateImportMakePriorityMutation, UpdateImportMakePriorityMutationVariables> {
    document = UpdateImportMakePriorityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMakeDocument = gql`
    mutation updateMake($input: UpdateMakeInput!) {
  result: updateMake(input: $input) {
    ... on UpdateMakeResult {
      make {
        id
        slug
        title
        offersTotal
        countryId
        importOptions {
          __typename
          ... on InternalError {
            message
          }
          ... on AccessDeniedError {
            message
            action
          }
          ... on MakeImportOptionsResult {
            priority {
              id
              resource {
                id
                displayName
              }
              priority
            }
          }
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMakeGQL extends Apollo.Mutation<UpdateMakeMutation, UpdateMakeMutationVariables> {
    document = UpdateMakeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindUsersGroupsDocument = gql`
    query findUsersGroups($input: FindUserGroupsInput!) {
  result: findUsersGroups(input: $input) {
    __typename
    ... on FindUserGroupsResult {
      groups {
        id
        slug
        title
      }
      total
    }
    ... on AccessDeniedError {
      message
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindUsersGroupsGQL extends Apollo.Query<FindUsersGroupsQuery, FindUsersGroupsQueryVariables> {
    document = FindUsersGroupsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ManageUsersDocument = gql`
    query manageUsers($input: ManageUsersInput!) {
  result: manageUsers(input: $input) {
    __typename
    ... on ManageUsersResult {
      total
      users {
        email
        name
        id
        phone
        locale
        isActive
        isUssActive
        groupId
        isCanBid
        deposit
        balance
        isUssActive
      }
    }
    ... on AccessDeniedError {
      message
      action
    }
    ... on ValidationErrors {
      errors {
        field
        rule
        message
        originalValue
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ManageUsersGQL extends Apollo.Query<ManageUsersQuery, ManageUsersQueryVariables> {
    document = ManageUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInput!) {
  result: updateUser(input: $input) {
    __typename
    ... on UpdateUserResult {
      user {
        id
        isUssActive
        groupId
      }
    }
    ... on AccessDeniedError {
      message
      action
    }
    ... on ServiceError {
      message
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateImportModelPriorityDocument = gql`
    mutation createImportModelPriority($input: CreateImportModelPriorityInput!) {
  result: createImportModelPriority(input: $input) {
    ... on CreateImportModelPriorityResult {
      id
      modelId
      resourceId
      priority
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on ModelNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateImportModelPriorityGQL extends Apollo.Mutation<CreateImportModelPriorityMutation, CreateImportModelPriorityMutationVariables> {
    document = CreateImportModelPriorityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateModelDocument = gql`
    mutation createModel($input: CreateModelInput!) {
  result: createModel(input: $input) {
    ... on CreateModelResult {
      model {
        id
        slug
        title
        offersTotal
        MakeID
        Position
        isImportant
        importOptions {
          __typename
          ... on InternalError {
            message
          }
          ... on AccessDeniedError {
            message
            action
          }
          ... on ModelImportOptionsResult {
            priority {
              id
              resource {
                id
                displayName
              }
              priority
            }
          }
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateModelGQL extends Apollo.Mutation<CreateModelMutation, CreateModelMutationVariables> {
    document = CreateModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteImportModelPriorityDocument = gql`
    mutation deleteImportModelPriority($input: DeleteImportModelPriorityInput!) {
  result: deleteImportModelPriority(input: $input) {
    ... on DeleteImportModelPriorityResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteImportModelPriorityGQL extends Apollo.Mutation<DeleteImportModelPriorityMutation, DeleteImportModelPriorityMutationVariables> {
    document = DeleteImportModelPriorityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteModelDocument = gql`
    mutation deleteModel($input: DeleteModelInput!) {
  result: deleteModel(input: $input) {
    ... on DeleteModelResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteModelGQL extends Apollo.Mutation<DeleteModelMutation, DeleteModelMutationVariables> {
    document = DeleteModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindModelsDocument = gql`
    query findModels($input: FindModelsInput!) {
  result: findModels(input: $input) {
    __typename
    ... on FindModelsResult {
      models {
        id
        slug
        title
        offersTotal
        MakeID
        Position
        isImportant
        importOptions {
          __typename
          ... on InternalError {
            message
          }
          ... on AccessDeniedError {
            message
            action
          }
          ... on ModelImportOptionsResult {
            priority {
              id
              resource {
                id
                displayName
              }
              priority
            }
          }
        }
      }
      total
    }
    ... on AccessDeniedError {
      message
      action
    }
    ... on ServiceErrorInterface {
      message
    }
    ... on ValidationErrors {
      errors {
        field
        rule
        message
        originalValue
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindModelsGQL extends Apollo.Query<FindModelsQuery, FindModelsQueryVariables> {
    document = FindModelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MergeModelsDocument = gql`
    mutation mergeModels($input: MergeModelsInput!) {
  result: mergeModels(input: $input) {
    ... on MergeModelsResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MergeModelsGQL extends Apollo.Mutation<MergeModelsMutation, MergeModelsMutationVariables> {
    document = MergeModelsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateImportModelPriorityDocument = gql`
    mutation updateImportModelPriority($input: UpdateImportModelPriorityInput!) {
  result: updateImportModelPriority(input: $input) {
    ... on UpdateImportModelPriorityResult {
      id
      modelId
      resourceId
      priority
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on ModelNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateImportModelPriorityGQL extends Apollo.Mutation<UpdateImportModelPriorityMutation, UpdateImportModelPriorityMutationVariables> {
    document = UpdateImportModelPriorityDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateModelDocument = gql`
    mutation updateModel($input: UpdateModelInput!) {
  result: updateModel(input: $input) {
    ... on UpdateModelResult {
      model {
        id
        slug
        title
        offersTotal
        MakeID
        Position
        isImportant
        importOptions {
          __typename
          ... on InternalError {
            message
          }
          ... on AccessDeniedError {
            message
            action
          }
          ... on ModelImportOptionsResult {
            priority {
              id
              resource {
                id
                displayName
              }
              priority
            }
          }
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateModelGQL extends Apollo.Mutation<UpdateModelMutation, UpdateModelMutationVariables> {
    document = UpdateModelDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateModificationDocument = gql`
    mutation createModification($input: CreateModificationInput!) {
  result: createModification(input: $input) {
    ... on CreateModificationResult {
      modification {
        id
        modelId
        slug
        title
        offersTotal
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateModificationGQL extends Apollo.Mutation<CreateModificationMutation, CreateModificationMutationVariables> {
    document = CreateModificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteModificationDocument = gql`
    mutation deleteModification($input: DeleteModificationInput!) {
  result: deleteModification(input: $input) {
    ... on DeleteModificationResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteModificationGQL extends Apollo.Mutation<DeleteModificationMutation, DeleteModificationMutationVariables> {
    document = DeleteModificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindModificationsDocument = gql`
    query findModifications($input: FindModificationsInput!) {
  result: findModifications(input: $input) {
    __typename
    ... on FindModificationsResult {
      modifications {
        id
        modelId
        slug
        title
        offersTotal
      }
      total
    }
    ... on AccessDeniedError {
      message
      action
    }
    ... on ServiceErrorInterface {
      message
    }
    ... on ValidationErrors {
      errors {
        field
        rule
        message
        originalValue
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindModificationsGQL extends Apollo.Query<FindModificationsQuery, FindModificationsQueryVariables> {
    document = FindModificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MergeModificationsDocument = gql`
    mutation mergeModifications($input: MergeModificationsInput!) {
  result: mergeModifications(input: $input) {
    ... on MergeModificationsResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MergeModificationsGQL extends Apollo.Mutation<MergeModificationsMutation, MergeModificationsMutationVariables> {
    document = MergeModificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateModificationDocument = gql`
    mutation updateModification($input: UpdateModificationInput!) {
  result: updateModification(input: $input) {
    ... on UpdateModificationResult {
      modification {
        id
        modelId
        slug
        title
        offersTotal
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateModificationGQL extends Apollo.Mutation<UpdateModificationMutation, UpdateModificationMutationVariables> {
    document = UpdateModificationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePageDocument = gql`
    mutation createPage($input: CreatePageInput!) {
  result: createPage(input: $input) {
    ... on CreatePageResult {
      page {
        id
        userId
        urlPath
        type
        displayName
        canonical
        parentId
        isActive
        payload {
          ... on PagePayloadMain {
            stub
          }
          ... on PagePayloadCardOffer {
            offerKind
            offerId
          }
          ... on PagePayloadSearchOffers {
            offerKind
            makeSlug
            modelSlug
          }
          ... on PagePayloadDefault {
            stub
          }
        }
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePageGQL extends Apollo.Mutation<CreatePageMutation, CreatePageMutationVariables> {
    document = CreatePageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeletePageDocument = gql`
    mutation deletePage($input: DeletePageInput!) {
  result: deletePage(input: $input) {
    ... on DeletePageResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeletePageGQL extends Apollo.Mutation<DeletePageMutation, DeletePageMutationVariables> {
    document = DeletePageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindPagesDocument = gql`
    query findPages($input: FindPagesInput!) {
  result: findPages(input: $input) {
    __typename
    ... on FindPagesResult {
      pages {
        id
        userId
        urlPath
        type
        displayName
        canonical
        parentId
        isActive
        payload {
          ... on PagePayloadMain {
            stub
          }
          ... on PagePayloadCardOffer {
            offerKind
            offerId
          }
          ... on PagePayloadSearchOffers {
            offerKind
            makeSlug
            modelSlug
          }
          ... on PagePayloadDefault {
            stub
          }
        }
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindPagesGQL extends Apollo.Query<FindPagesQuery, FindPagesQueryVariables> {
    document = FindPagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatePageDocument = gql`
    mutation updatePage($input: UpdatePageInput!) {
  result: updatePage(input: $input) {
    ... on UpdatePageResult {
      page {
        id
        userId
        urlPath
        type
        displayName
        canonical
        parentId
        isActive
        payload {
          ... on PagePayloadMain {
            stub
          }
          ... on PagePayloadCardOffer {
            offerKind
            offerId
          }
          ... on PagePayloadSearchOffers {
            offerKind
            makeSlug
            modelSlug
          }
          ... on PagePayloadDefault {
            stub
          }
        }
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatePageGQL extends Apollo.Mutation<UpdatePageMutation, UpdatePageMutationVariables> {
    document = UpdatePageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProjectDocument = gql`
    mutation createProject($input: CreateProjectInput!) {
  result: createProject(input: $input) {
    ... on CreateProjectResult {
      project {
        id
        projectKey
        displayName
        countries
        currency
        localeId
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProjectGQL extends Apollo.Mutation<CreateProjectMutation, CreateProjectMutationVariables> {
    document = CreateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProjectDocument = gql`
    mutation deleteProject($input: DeleteProjectInput!) {
  result: deleteProject(input: $input) {
    ... on DeleteProjectResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProjectGQL extends Apollo.Mutation<DeleteProjectMutation, DeleteProjectMutationVariables> {
    document = DeleteProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindProjectsDocument = gql`
    query findProjects($input: FindProjectsInput!) {
  result: findProjects(input: $input) {
    __typename
    ... on FindProjectsResult {
      projects {
        id
        projectKey
        displayName
        countries
        currency
        localeId
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindProjectsGQL extends Apollo.Query<FindProjectsQuery, FindProjectsQueryVariables> {
    document = FindProjectsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProjectDocument = gql`
    mutation updateProject($input: UpdateProjectInput!) {
  result: updateProject(input: $input) {
    ... on UpdateProjectResult {
      project {
        id
        projectKey
        displayName
        countries
        currency
        localeId
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProjectGQL extends Apollo.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> {
    document = UpdateProjectDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRateDocument = gql`
    mutation createRate($input: CreateRateInput!) {
  result: createRate(input: $input) {
    ... on CreateRateResult {
      rate {
        id
        slug
        title
        group {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on RateGroupNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRateGQL extends Apollo.Mutation<CreateRateMutation, CreateRateMutationVariables> {
    document = CreateRateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteRateDocument = gql`
    mutation deleteRate($input: DeleteRateInput!) {
  result: deleteRate(input: $input) {
    ... on DeleteRateResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteRateGQL extends Apollo.Mutation<DeleteRateMutation, DeleteRateMutationVariables> {
    document = DeleteRateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindRatesDocument = gql`
    query findRates($input: FindRatesInput!) {
  result: findRates(input: $input) {
    __typename
    ... on FindRatesResult {
      rates {
        id
        slug
        title
        group {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindRatesGQL extends Apollo.Query<FindRatesQuery, FindRatesQueryVariables> {
    document = FindRatesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRateDocument = gql`
    mutation updateRate($input: UpdateRateInput!) {
  result: updateRate(input: $input) {
    ... on UpdateRateResult {
      rate {
        id
        slug
        title
        group {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
        field
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on RateGroupNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRateGQL extends Apollo.Mutation<UpdateRateMutation, UpdateRateMutationVariables> {
    document = UpdateRateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindOffersTranslationsDocument = gql`
    query findOffersTranslations($input: FindOffersTranslationsInput!) {
  result: findOffersTranslations(input: $input) {
    __typename
    ... on FindOffersTranslationsResult {
      translations {
        id
        text
        status
        offerID
        createdAt
        updatedAt
        translatorUserID
        isReaded
        requests {
          ...OfferTranslationsRequestFragment
        }
        offer {
          __typename
          ... on OfferNotFound {
            id
            message
          }
          ... on OfferAuction {
            ...OfferAuctionForTranslationFragment
          }
        }
      }
      total
    }
    ... on ServiceError {
      message
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
  }
}
    ${OfferTranslationsRequestFragmentFragmentDoc}
${OfferAuctionForTranslationFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindOffersTranslationsGQL extends Apollo.Query<FindOffersTranslationsQuery, FindOffersTranslationsQueryVariables> {
    document = FindOffersTranslationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindTranslatorsDocument = gql`
    query findTranslators {
  result: findTranslators {
    __typename
    ... on FindTranslatorsResult {
      translators {
        userId
        userName
        userEmail
        count
      }
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindTranslatorsGQL extends Apollo.Query<FindTranslatorsQuery, FindTranslatorsQueryVariables> {
    document = FindTranslatorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateOfferTranslationDocument = gql`
    mutation updateOfferTranslation($input: UpdateOfferTranslationInput!) {
  result: updateOfferTranslation(input: $input) {
    __typename
    ... on UpdateOfferTranslationResult {
      translation {
        id
        text
        status
        offerID
        createdAt
        updatedAt
        translatorUserID
        isReaded
        requests {
          ...OfferTranslationsRequestFragment
        }
      }
    }
    ... on NotFoundError {
      message
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
  }
}
    ${OfferTranslationsRequestFragmentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOfferTranslationGQL extends Apollo.Mutation<UpdateOfferTranslationMutation, UpdateOfferTranslationMutationVariables> {
    document = UpdateOfferTranslationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTransmissionDocument = gql`
    mutation createTransmission($input: CreateTransmissionInput!) {
  result: createTransmission(input: $input) {
    ... on CreateTransmissionResult {
      transmission {
        id
        title
        slug
        type {
          id
          slug
          title
        }
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on TransmissionTypeNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTransmissionGQL extends Apollo.Mutation<CreateTransmissionMutation, CreateTransmissionMutationVariables> {
    document = CreateTransmissionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTransmissionDocument = gql`
    mutation deleteTransmission($input: DeleteTransmissionInput!) {
  result: deleteTransmission(input: $input) {
    ... on DeleteTransmissionResult {
      ok
    }
    ... on ValidationErrors {
      errors {
        message
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTransmissionGQL extends Apollo.Mutation<DeleteTransmissionMutation, DeleteTransmissionMutationVariables> {
    document = DeleteTransmissionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindTransmissionsDocument = gql`
    query findTransmissions($input: FindTransmissionsInput!) {
  result: findTransmissions(input: $input) {
    __typename
    ... on FindTransmissionsResult {
      transmissions {
        id
        title
        slug
        type {
          id
          title
          slug
        }
        position
        translations {
          locale
          field
          other
        }
      }
      total
    }
    ... on ValidationErrors {
      errors {
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FindTransmissionsGQL extends Apollo.Query<FindTransmissionsQuery, FindTransmissionsQueryVariables> {
    document = FindTransmissionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTransmissionDocument = gql`
    mutation updateTransmission($input: UpdateTransmissionInput!) {
  result: updateTransmission(input: $input) {
    ... on UpdateTransmissionResult {
      transmission {
        id
        title
        slug
        type {
          id
          slug
          title
        }
        position
        translations {
          locale
          field
          other
        }
      }
    }
    ... on ValidationErrors {
      errors {
        message
      }
    }
    ... on AccessDeniedError {
      message
    }
    ... on TransmissionTypeNotFoundError {
      message
    }
    ... on AlreadyExistsError {
      message
    }
    ... on NotFoundError {
      message
    }
    ... on ServiceError {
      message
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTransmissionGQL extends Apollo.Mutation<UpdateTransmissionMutation, UpdateTransmissionMutationVariables> {
    document = UpdateTransmissionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserContextDocument = gql`
    query getUserContext {
  result: getUserContext {
    ... on UserContext {
      userId
      username
      countryId
      isCanBid
      isUssActive
      deposit
      allowActions
      userGroup
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserContextGQL extends Apollo.Query<GetUserContextQuery, GetUserContextQueryVariables> {
    document = GetUserContextDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  result: login(input: $input) {
    __typename
    ... on LoginResult {
      __typename
      ok
    }
    ... on ServiceError {
      __typename
      message
    }
    ... on UserNotFoundError {
      __typename
      message
    }
    ... on InvalidCredentialsError {
      __typename
      message
    }
    ... on ValidationErrors {
      errors {
        field
        message
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LogoutDocument = gql`
    mutation logout {
  result: logout {
    __typename
    ... on LogoutResult {
      ok
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LogoutGQL extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
    document = LogoutDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }