import {
  Directive,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
  Renderer2,
  ChangeDetectorRef
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CarvectorPopoverComponent } from './popover.component';

@Directive({ selector: '[carvectorPopoverContent]' })
export class PopoverContentDirective implements OnDestroy {
  destroy$ = new Subject();

  constructor(
    private host: CarvectorPopoverComponent,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) {
    this.host.changeState$.pipe(takeUntil(this.destroy$)).subscribe(visible => {
      if (visible) {
        const view = this.viewContainer.createEmbeddedView(this.templateRef);
        this.renderer.addClass(view.rootNodes[0], `carvector-popover-content`);
        this.renderer.addClass(
          view.rootNodes[0],
          'carvector-popover-content-bottom-left'
        );
      } else {
        this.viewContainer.clear();
      }
      this.cd.markForCheck();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
