import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { UserContext, UserGroup } from '@carvector/shared/carvector-api';
import { Nullable } from '@carvector/shared/util-type-fest';
import { UserQuery } from '@carvector/users';

import { MenuItem } from '../../navigation/navigation.component';
import { CarvectorPopoverComponent } from '../../popover/popover.component';

@Component({
  selector: 'carvector-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss']
})
export class HeaderMenuComponent implements OnInit {
  @Input()
  menu!: MenuItem[];

  public userInfo$ = this.userQuery.context$;
  public isTranslator = false;

  public userGroup = UserGroup;

  constructor(public router: Router, private userQuery: UserQuery) {}

  ngOnInit(): void {
    this.userInfo$.subscribe((userContext: Nullable<UserContext>) => {
      if (userContext) {
        this.isTranslator = userContext.userGroup === UserGroup.TRANSLATOR;
      }
    });
  }

  public navigate(menu: MenuItem, popover?: CarvectorPopoverComponent): void {
    if (menu?.url) {
      window.open(menu.url, '_blank', 'noopener,noreferrer');
      popover?.hide();
    } else if (menu?.routerLink) {
      void this.router.navigate([menu.routerLink], { replaceUrl: true }).then(() => {
        popover?.hide();
      });
    }
  }
}
