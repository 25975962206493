import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input
} from '@angular/core';

import { Nullable } from '@carvector/shared/util-type-fest';

import { MenuItem } from '../navigation/navigation.component';

@Component({
  selector: 'carvector-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  @Input()
  enableSidebarToggle = false;

  @Input()
  menu!: MenuItem[];

  @Input()
  userInfo: Nullable<{ username: string; userId: number }> = null;

  @Output()
  toggleSidenav = new EventEmitter<void>();
}
