import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

import { LoginFacade } from '../../application';

@Component({
  selector: 'carvector-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  public loginForm = new FormGroup({
    name: new FormControl('', [this.loginFacade.userNameValidator()]),
    password: new FormControl('', [this.loginFacade.userPasswordValidator()])
  });

  public errorMessage$ = this.loginFacade.errorMessage$;

  public get userNameError(): string {
    const nameControl = this.loginForm.get('name');

    return nameControl?.hasError('userName') && nameControl?.dirty
      ? (nameControl?.errors?.message as string)
      : '';
  }

  public get userPasswordError(): string {
    const nameControl = this.loginForm.get('password');

    return nameControl?.hasError('userPassword') && nameControl?.dirty
      ? (nameControl?.errors?.message as string)
      : '';
  }

  constructor(private loginFacade: LoginFacade) {}

  public login() {
    if (this.loginForm.valid) {
      this.loginFacade.login(
        this.loginForm.get('name')?.value,
        this.loginForm.get('password')?.value
      );
    }
  }
}
