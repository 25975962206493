import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardModule } from 'primeng/card';

import { UserSettingsFacade } from '../../application';

import { UserSettingsComponent } from './user-settings.component';

@NgModule({
  imports: [CommonModule, CardModule],
  declarations: [UserSettingsComponent],
  providers: [UserSettingsFacade]
})
export class UserSettingsPageModule {}
