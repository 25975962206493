import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { map as eMap, getOrElseW as eGetOrElseW } from 'fp-ts/Either';
import { pipe as fPipe } from 'fp-ts/function';
import { map } from 'rxjs/operators';

import { UserService } from '../+state/user';

export const REDIRECT_PARAM = 'redirect';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userService.getUserContext().pipe(
      map(result =>
        fPipe(
          result,
          eMap(() => true),
          eGetOrElseW(() =>
            this.router.createUrlTree(['login'], {
              queryParams: {
                [REDIRECT_PARAM]: state.url === '/logout' ? null : state.url
              },
              queryParamsHandling: 'merge'
            })
          )
        )
      )
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
