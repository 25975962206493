import { Component } from '@angular/core';

import { UserSettingsFacade } from '../../application';

@Component({
  selector: 'carvector-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent {
  public userId$ = this.userSettingsFacade.userId$;

  public allowedActions$ = this.userSettingsFacade.allowedActions$;

  constructor(private userSettingsFacade: UserSettingsFacade) {}
}
