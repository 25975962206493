import { left, right } from 'fp-ts/Either';

import { BaseError, ValueObject } from '@carvector/shared/seedwork';
import { againstAtLeast, notEmpty } from '@carvector/shared/util-strings';

export class UserNameValidationError extends BaseError {
  public tag: 'UserNameValidationError';

  private constructor(message: string) {
    super(message);

    this.tag = 'UserNameValidationError';
  }

  public static of(message: string): UserNameValidationError {
    return new UserNameValidationError(message);
  }
}

export type UserNameProps = {
  name: string;
};

export class UserName extends ValueObject<UserNameProps> {
  public static minLength = 4;

  get value() {
    return this.props.name;
  }

  private constructor(props: UserNameProps) {
    super(props);
  }

  public static create(props: UserNameProps) {
    if (!notEmpty(props.name) || !againstAtLeast(props.name.trim(), this.minLength)) {
      return left(
        UserNameValidationError.of(
          `Имя пользователя должно быть не менее ${this.minLength} символов`
        )
      );
    }

    return right(new UserName(props));
  }
}
