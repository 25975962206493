import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PopoverContentDirective } from './popover-content.dicrective';
import { PopoverTriggerDirective } from './popover-trigger.directive';
import { CarvectorPopoverComponent } from './popover.component';

@NgModule({
  imports: [CommonModule],
  declarations: [
    CarvectorPopoverComponent,
    PopoverContentDirective,
    PopoverTriggerDirective
  ],
  exports: [CarvectorPopoverComponent, PopoverContentDirective, PopoverTriggerDirective]
})
export class PopoverModule {}
