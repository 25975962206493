import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type MenuItem = {
  label: string;
  routerLink?: string;
  url?: string;
  items?: MenuItem[];
};

@Component({
  selector: 'carvector-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent {
  @Input()
  menu: MenuItem[] = [];
}
