import { Injectable } from '@angular/core';
import { Either, left, right } from 'fp-ts/Either';
import { Observable, of } from 'rxjs';
import { catchError, map, pluck } from 'rxjs/operators';

import {
  GetUserContextGQL,
  LoginGQL,
  LoginInput,
  LogoutGQL,
  UserContext
} from '@carvector/shared/carvector-api';
import { notEmpty } from '@carvector/shared/util-arrays';

import { AuthUserError, LoginUserError, UnexpectedUserError } from './user-errors';
import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private userStore: UserStore,
    private loginGQL: LoginGQL,
    private logoutGQL: LogoutGQL,
    private getUserContextGQL: GetUserContextGQL
  ) {}

  public getUserContext(): Observable<
    Either<AuthUserError | UnexpectedUserError, UserContext>
  > {
    return this.getUserContextGQL.fetch().pipe(
      pluck('data', 'result'),
      map(context => {
        if (notEmpty(context.allowActions)) {
          this.userStore.update({
            context
          });

          return right(context);
        }

        return left(AuthUserError.of('У пользователя нет разрешенных действий'));
      }),
      catchError((error: Error) => {
        const unexpectedError = UnexpectedUserError.of(error.message);
        this.userStore.setError(unexpectedError);
        return of(left(unexpectedError));
      })
    );
  }

  login(
    input: LoginInput
  ): Observable<Either<LoginUserError | UnexpectedUserError, boolean>> {
    return this.loginGQL.mutate({ input }).pipe(
      pluck('data'),
      map(data => {
        switch (data?.result.__typename) {
          case 'LoginResult': {
            this.userStore.setError(null);
            return right(data.result.ok);
          }

          case 'InvalidCredentialsError':
          case 'ServiceError':
          case 'UserNotFoundError': {
            const loginError = LoginUserError.of(data.result.message);
            this.userStore.setError(loginError);
            return left(loginError);
          }

          case 'ValidationErrors': {
            const resultMessage = data.result.errors.reduce(
              (acc, error) => `${acc}${error.field}: ${error.message}`,
              ''
            );
            const loginError = LoginUserError.of(resultMessage);
            this.userStore.setError(loginError);
            return left(loginError);
          }

          default: {
            return left(
              UnexpectedUserError.of('Необработанная ситуация при авторизации')
            );
          }
        }
      }),
      catchError((error: Error) => {
        const unexpectedError = UnexpectedUserError.of(error.message);
        this.userStore.setError(unexpectedError);
        return of(left(unexpectedError));
      })
    );
  }

  logout(): Observable<Either<UnexpectedUserError, boolean>> {
    return this.logoutGQL.mutate().pipe(
      pluck('data'),
      map(data => {
        switch (data?.result.__typename) {
          case 'LogoutResult': {
            return right(data.result.ok);
          }
          default: {
            return left(
              UnexpectedUserError.of('Необработанная ситуация при авторизации')
            );
          }
        }
      }),
      catchError((error: Error) => {
        const unexpectedError = UnexpectedUserError.of(error.message);
        this.userStore.setError(unexpectedError);
        return of(left(unexpectedError));
      })
    );
  }
}
