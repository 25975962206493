export const AUTO_PROMO_URL = 'auto-promo';
export const TOP_URL = 'top';
export const MOST_POPULAR_URL = 'most-popular';
export const TOP_20_URL = 'top-20';
export const LOGIN_PAGE_PATH = 'login';
export const LOGOUT_PAGE_PATH = 'logout';
export const USER_SETTINGS_PAGE_PATH = 'user';

export const HANDBOOKS_URL = 'handbooks';
export const TRANSMISSIONS_URL = 'transmissions';
export const COLORS_URL = 'colors';
export const GEARS_URL = 'gears';
export const MAKES_URL = 'makes';
export const MODELS_URL = 'models';
export const CHASSIS_URL = 'chassis';
export const BODY_TYPES_URL = 'body-types';
export const MODIFICATIONS_URL = 'modifications';
export const RATES_URL = 'rates';
export const COMMON_URL = 'common';
export const PROJECTS_URL = 'projects';

export const HELP_URL = 'help';
export const FAQ_CATEGORIES_URL = 'categories';
export const FAQ_URL = 'questions';

export const PAGES_URL = 'pages';

export const COUNTRIES_URL = 'countries';

export const LOCAL_INFO_URL = 'local-info';

export const USERS_URL = 'users';

export const BLOCKS_URL = 'blocks';
export const BLOCK_LAYOUTS_URL = 'block-layouts';
export const BLOCK_LAYOUT_PLACEMENTS_URL = 'block-layout-placements';

export const BIDS_URL = 'bids';
export const TRANSLATOR_URL = 'translations';
