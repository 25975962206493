import { Injectable } from '@angular/core';
import { matchW } from 'fp-ts/Either';
import { pipe as fPipe } from 'fp-ts/function';

import { UserService } from './+state/user';

@Injectable()
export class LogoutFacade {
  constructor(private userService: UserService) {}

  public logout() {
    this.userService.logout().subscribe(result =>
      fPipe(
        result,
        matchW(
          () => result,
          () => {
            document.cookie =
              'CARVECTOR_AUTH_TOKEN=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            return window.location.replace('/login');
          }
        )
      )
    );
  }
}
