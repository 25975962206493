import { againstNullOrUndefined } from '@carvector/shared/util-guards';

type ValueObjectProps = {
  [index: string]: unknown;
};

export abstract class ValueObject<T extends ValueObjectProps> {
  public props: T;

  constructor(props: T) {
    this.props = props;
  }

  public equals(vo?: ValueObject<T>): boolean {
    if (!againstNullOrUndefined(vo)) {
      return false;
    }
    if (!againstNullOrUndefined(vo.props)) {
      return false;
    }

    return JSON.stringify(this.props) === JSON.stringify(vo.props);
  }
}
