import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap, filter, skip } from 'rxjs/operators';

import { UserQuery } from '@carvector/users';

import { MenuItem } from '../navigation/navigation.component';

const SMALL_WIDTH_BREAKPOINT = 992;

@Component({
  selector: 'carvector-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit {
  @Input()
  menu!: MenuItem[];

  public isScreenSmall$: Observable<boolean>;

  public title$: Observable<string | null> = of(null);

  public isSidebarVisible = false;

  public isUserAuth$ = this.userQuery.isAuth$;

  public userInfo$ = this.userQuery.context$;

  private navigationEndEvents = this.router.events.pipe(
    filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
  );

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private breakpoints: BreakpointObserver,
    private userQuery: UserQuery
  ) {
    this.isScreenSmall$ = this.breakpoints
      .observe(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`)
      .pipe(map(breakpoint => breakpoint.matches));
  }

  ngOnInit() {
    this.navigationEndEvents
      .pipe(
        skip(1),
        mergeMap(() => this.isScreenSmall$)
      )
      .subscribe(shouldCloseSideNav => shouldCloseSideNav && this.closeSidenav());

    this.title$ = this.navigationEndEvents.pipe(
      map(() => this.getRouteTitle(this.route.firstChild))
    );
  }

  getRouteTitle(route: ActivatedRoute | null) {
    let child = route;
    while (child) {
      if (child.firstChild) {
        child = child.firstChild;
      } else if (child.snapshot.data && child.snapshot.data['title']) {
        return child.snapshot.data['title'] as string;
      } else {
        return null;
      }
    }

    return null;
  }

  toggleSidenav() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }

  closeSidenav() {
    this.isSidebarVisible = false;
  }
}
