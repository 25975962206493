import { againstNullOrUndefined } from '@carvector/shared/util-guards';

export const againstAtLeast = (input: unknown[], size = 0) => input.length > size;

export const notEmpty = (input?: unknown[] | null) =>
  againstNullOrUndefined(input) && againstAtLeast(input);

export const first = <T>(input: T[]) => input[0];

export const unique = <T>(value: T, index: number, array: T[]) => index === array.indexOf(value);
