import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { map as eMap, getOrElseW as eGetOrElseW } from 'fp-ts/Either';
import { pipe as fPipe } from 'fp-ts/function';
import { map } from 'rxjs/operators';

import { UserService } from '../+state/user';

@Injectable({ providedIn: 'root' })
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate() {
    return this.userService.getUserContext().pipe(
      map(result =>
        fPipe(
          result,
          eMap(() => this.router.createUrlTree(['user'])),
          eGetOrElseW(() => true)
        )
      )
    );
  }

  canActivateChild() {
    return this.canActivate();
  }
}
