import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';

import { againstNullOrUndefined } from '@carvector/shared/util-guards';

import { UserErrors } from './user-errors';
import { UserStore, UserState } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  public context$ = this.select('context');

  public isAuth$ = this.context$.pipe(map(againstNullOrUndefined));

  public error$ = this.selectError<UserErrors | null>();

  constructor(protected store: UserStore) {
    super(store);
  }
}
