import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AuthGuard, UserQuery } from './application';

@NgModule({
  imports: [CommonModule]
})
export class UsersModule {
  static forRoot() {
    return {
      ngModule: UsersModule,
      providers: [AuthGuard, UserQuery]
    };
  }
}
