import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Inject,
  InjectionToken,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { Subject, timer } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

import { PopoverTriggerDirective } from './popover-trigger.directive';

@Component({
  selector: 'carvector-popover',
  templateUrl: './popover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarvectorPopoverComponent implements OnInit, OnDestroy {
  @HostBinding('class.carvector-popover')
  classPopover = true;

  @HostBinding('class.carvector-popover-open')
  isOpen = false;

  @ContentChild(PopoverTriggerDirective, { static: true, read: ElementRef })
  triggerEl!: ElementRef<HTMLElement>;

  private readonly changeState = new Subject<boolean>();
  public destroy$ = new Subject();
  public readonly changeState$ = this.changeState.pipe(distinctUntilChanged());

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<unknown>,
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef<HTMLElement>,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.ngZone.runOutsideAngular(() => {
        this.el.nativeElement.addEventListener('click', this.handleClick);
        this.document.addEventListener('click', this.handleClickOut);
      });
    }
  }

  handleClick = (event: MouseEvent) => {
    if (this.triggerEl.nativeElement.contains(event.target as HTMLElement)) {
      this.show();
    }
  };

  handleClickOut = (event: MouseEvent) => {
    if (
      this.isOpen &&
      this.el.nativeElement &&
      !this.el.nativeElement.contains(event.target as HTMLElement)
    ) {
      this.hide();
    }
  };

  show() {
    this.toggle(true, 0);
  }

  hide() {
    this.toggle(false, 0);
  }

  private toggle(state: boolean, delay: number) {
    timer(delay)
      .pipe(takeUntil(this.destroy$))
      .pipe(tap(() => (this.isOpen = state)))
      .subscribe(() => this.ngZone.run(() => this.changeState.next(state)));
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.document.removeEventListener('click', this.handleClickOut);
      this.el.nativeElement.removeEventListener('click', this.handleClick);
    }
  }
}
