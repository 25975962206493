import { Component, OnInit } from '@angular/core';

import { LogoutFacade } from '../../application';

@Component({
  selector: 'carvector-logout-page',
  template: '',
  styles: []
})
export class LogoutPageComponent implements OnInit {
  constructor(private logoutFacade: LogoutFacade) {}

  ngOnInit() {
    this.logoutFacade.logout();
  }
}
