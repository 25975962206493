import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { UserContext } from '@carvector/shared/carvector-api';
import { Nullable } from '@carvector/shared/util-type-fest';

export interface UserState {
  context: Nullable<UserContext>;
}

export function createInitialState(): UserState {
  return {
    context: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
    this.setError(null);
  }
}
