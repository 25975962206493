<div class="p-grid p-col-6 p-m-auto">
  <p-card class="p-col-12">
    <i class="pi pi-user"></i>

    <h4>UserID: {{ userId$ | async }}</h4>

    <h4>Allowed Actions:</h4>

    <ul>
      <li *ngFor="let action of allowedActions$ | async">{{ action }}</li>
    </ul>
  </p-card>
</div>
