import { BaseError } from '@carvector/shared/seedwork';

export class LoginUserError extends BaseError {
  public tag: 'LoginUserError';

  private constructor(message: string) {
    super(message);

    this.tag = 'LoginUserError';
  }

  public static of(message: string): LoginUserError {
    return new LoginUserError(message);
  }
}

export class AuthUserError extends BaseError {
  public tag: 'AuthUserError';

  private constructor(message: string) {
    super(message);

    this.tag = 'AuthUserError';
  }

  public static of(message: string): AuthUserError {
    return new AuthUserError(message);
  }
}

export class UnexpectedUserError extends BaseError {
  public tag: 'UnexpectedUserError';

  private constructor(message: string) {
    super(message);

    this.tag = 'UnexpectedUserError';
  }

  public static of(message: string): UnexpectedUserError {
    return new UnexpectedUserError(message);
  }
}

export type UserErrors = LoginUserError | AuthUserError | UnexpectedUserError;
