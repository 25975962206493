/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface GQLReqBody {
  operationName: string;
  variables: {
    input: any;
  };
  query: string;
}

interface GQLResBody {
  data?: {
    result?: any;
  };
  message: string;
  errors?: {
    path: string;
  }[];
  ok?: boolean;
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<GQLReqBody>,
    next: HttpHandler
  ): Observable<HttpEvent<GQLResBody>> {
    if (req.url !== '/graphql') {
      return next.handle(req);
    }
    return next.handle(req).pipe(
      map((res: HttpEvent<GQLResBody>) => {
        if (
          (res instanceof HttpResponse &&
            res.body?.errors &&
            res.body?.errors?.length > 0) ||
          (res instanceof HttpResponse && res.body?.message) ||
          (res instanceof HttpResponse && res.body?.ok === false)
        ) {
          Sentry.withScope(scope => {
            scope.setTag('kind', req.url);
            scope.setExtra('query', req.body?.query);
            scope.setExtra('variables', JSON.stringify(req.body?.variables));
            scope.setExtra('requestBody', JSON.stringify(req.body));
            scope.setExtra('responseBody', JSON.stringify(res.body));
            if (
              res instanceof HttpResponse &&
              res.body &&
              res.body.errors &&
              res.body.errors[0]?.path
            ) {
              scope.addBreadcrumb({
                category: 'query-path',
                message: res.body.errors.map(error => error.path).join(' > '),
                level: Sentry.Severity.Debug
              });
            }
            Sentry.captureException(res.body);
          });
        }

        return res;
      })
    );
  }
}
