import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

import { environment } from '../environments/environment';

import {
  AUTO_PROMO_MOST_POPULAR_URL,
  AUTO_PROMO_TOP_20_URL,
  AUTO_PROMO_TOP_URL,
  BLOCKS_BLOCK_LAYOUTS_URL,
  BLOCKS_BLOCK_LAYOUT_PLACEMENTS_URL,
  BLOCKS_BLOCK_URL,
  HANDBOOKS_BODY_TYPES_URL,
  HANDBOOKS_CHASSIS_URL,
  HANDBOOKS_COLORS_URL,
  HANDBOOKS_COMMON_URL,
  HANDBOOKS_GEARS_URL,
  HANDBOOKS_MAKES_URL,
  HANDBOOKS_MODELS_URL,
  HANDBOOKS_MODIFICATIONS_URL,
  HANDBOOKS_RATES_URL,
  HANDBOOKS_TRANSMISSIONS_URL,
  HELP_FAQ_CATEGORIES_URL,
  HELP_FAQ_URL
} from './app-routing.module';
import {
  COUNTRIES_URL,
  LOCAL_INFO_URL,
  PAGES_URL,
  PROJECTS_URL,
  USERS_URL
} from './modules/urls';

@Component({
  selector: 'carvector-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public menu = [
    {
      label: 'Customers',
      routerLink: USERS_URL
    },
    {
      label: 'Auction',
      items: [
        {
          label: 'Requests',
          url: environment.newAdminBidRequestsUrl
        },
        {
          label: 'Bids',
          url: environment.newAdminBidsUrl
        },
        {
          label: 'Translations',
          url: environment.newAdminTranslationsUrl
        }
      ]
    },
    {
      label: 'Content',
      items: [
        {
          label: 'Страницы',
          routerLink: PAGES_URL
        },
        {
          label: 'Помощь и поддержка',
          items: [
            {
              label: 'Категории',
              routerLink: HELP_FAQ_CATEGORIES_URL
            },
            {
              label: 'Вопросы',
              routerLink: HELP_FAQ_URL
            }
          ]
        },
        {
          label: 'Local info',
          routerLink: LOCAL_INFO_URL
        },
        {
          label: 'Промо авто',
          items: [
            {
              label: 'Библиотека Top Auto',
              routerLink: AUTO_PROMO_TOP_URL
            },
            {
              label: 'Блок Most popular',
              routerLink: AUTO_PROMO_MOST_POPULAR_URL
            },
            {
              label: 'Блок Top 20',
              routerLink: AUTO_PROMO_TOP_20_URL
            }
          ]
        },
        {
          label: 'Отзывы'
        }
      ]
    },
    {
      label: 'Settings',
      items: [
        {
          label: 'Справочники',
          items: [
            {
              label: 'Коробки передач',
              routerLink: HANDBOOKS_TRANSMISSIONS_URL
            },
            {
              label: 'Цвета кузовов',
              routerLink: HANDBOOKS_COLORS_URL
            },
            {
              label: 'Приводы',
              routerLink: HANDBOOKS_GEARS_URL
            },
            {
              label: 'Марки',
              routerLink: HANDBOOKS_MAKES_URL
            },
            {
              label: 'Модели',
              routerLink: HANDBOOKS_MODELS_URL
            },
            {
              label: 'Шасси',
              routerLink: HANDBOOKS_CHASSIS_URL
            },
            {
              label: 'Корпуса',
              routerLink: HANDBOOKS_BODY_TYPES_URL
            },
            {
              label: 'Модификации',
              routerLink: HANDBOOKS_MODIFICATIONS_URL
            },
            {
              label: 'Рейтинги',
              routerLink: HANDBOOKS_RATES_URL
            },
            {
              label: 'Все справочники',
              routerLink: HANDBOOKS_COMMON_URL
            }
          ]
        },
        {
          label: 'Проекты',
          routerLink: PROJECTS_URL
        },
        {
          label: 'Страны',
          routerLink: COUNTRIES_URL
        },
        {
          label: 'Блоки',
          items: [
            {
              label: 'Блоки',
              routerLink: BLOCKS_BLOCK_URL
            },
            {
              label: 'Блоки Лейаутов',
              routerLink: BLOCKS_BLOCK_LAYOUTS_URL
            },
            {
              label: 'Позиции блоков в лейаутах',
              routerLink: BLOCKS_BLOCK_LAYOUT_PLACEMENTS_URL
            }
          ]
        }
      ]
    }
  ];

  constructor(private primengConfig: PrimeNGConfig) {}

  ngOnInit() {
    this.primengConfig.ripple = true;
  }
}
