import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ExternalUrlGuard } from './externalUrl.guard';
import {
  TOP_URL,
  MOST_POPULAR_URL,
  AUTO_PROMO_URL,
  HANDBOOKS_URL,
  TRANSMISSIONS_URL,
  COLORS_URL,
  GEARS_URL,
  RATES_URL,
  COMMON_URL,
  PROJECTS_URL,
  MAKES_URL,
  MODELS_URL,
  CHASSIS_URL,
  BODY_TYPES_URL,
  MODIFICATIONS_URL,
  HELP_URL,
  FAQ_CATEGORIES_URL,
  FAQ_URL,
  PAGES_URL,
  COUNTRIES_URL,
  LOCAL_INFO_URL,
  TOP_20_URL,
  USERS_URL,
  BLOCKS_URL,
  BLOCK_LAYOUTS_URL,
  BIDS_URL,
  TRANSLATOR_URL,
  BLOCK_LAYOUT_PLACEMENTS_URL
} from './modules/urls';

export const AUTO_PROMO_TOP_URL = `${AUTO_PROMO_URL}/${TOP_URL}`;
export const AUTO_PROMO_MOST_POPULAR_URL = `${AUTO_PROMO_URL}/${MOST_POPULAR_URL}`;
export const AUTO_PROMO_TOP_20_URL = `${AUTO_PROMO_URL}/${TOP_20_URL}`;
export const HANDBOOKS_TRANSMISSIONS_URL = `${HANDBOOKS_URL}/${TRANSMISSIONS_URL}`;
export const HANDBOOKS_COLORS_URL = `${HANDBOOKS_URL}/${COLORS_URL}`;
export const HANDBOOKS_GEARS_URL = `${HANDBOOKS_URL}/${GEARS_URL}`;
export const HANDBOOKS_MAKES_URL = `${HANDBOOKS_URL}/${MAKES_URL}`;
export const HANDBOOKS_MODELS_URL = `${HANDBOOKS_URL}/${MODELS_URL}`;
export const HANDBOOKS_CHASSIS_URL = `${HANDBOOKS_URL}/${CHASSIS_URL}`;
export const HANDBOOKS_BODY_TYPES_URL = `${HANDBOOKS_URL}/${BODY_TYPES_URL}`;
export const HANDBOOKS_MODIFICATIONS_URL = `${HANDBOOKS_URL}/${MODIFICATIONS_URL}`;
export const HANDBOOKS_RATES_URL = `${HANDBOOKS_URL}/${RATES_URL}`;
export const HANDBOOKS_COMMON_URL = `${HANDBOOKS_URL}/${COMMON_URL}`;
export const HELP_FAQ_CATEGORIES_URL = `${HELP_URL}/${FAQ_CATEGORIES_URL}`;
export const HELP_FAQ_URL = `${HELP_URL}/${FAQ_URL}`;
export const BLOCKS_BLOCK_LAYOUTS_URL = `${BLOCKS_URL}/${BLOCK_LAYOUTS_URL}`;
export const BLOCKS_BLOCK_LAYOUT_PLACEMENTS_URL = `${BLOCKS_URL}/${BLOCK_LAYOUT_PLACEMENTS_URL}`;
export const BLOCKS_BLOCK_URL = `${BLOCKS_URL}/${BLOCKS_URL}`;

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('./modules/home-shell.module').then(
              esModule => esModule.HomeShellModule
            )
        },
        {
          path: AUTO_PROMO_URL,
          loadChildren: () =>
            import('./modules/auto-promo-shell.module').then(
              esModule => esModule.AutoPromoShellModule
            )
        },
        {
          path: '',
          loadChildren: () =>
            import('./modules/users-shell.module').then(
              esModule => esModule.UsersShellModule
            )
        },
        {
          path: HANDBOOKS_URL,
          loadChildren: () =>
            import('./modules/handbooks-shell.module').then(
              esModule => esModule.HandbooksShellModule
            )
        },
        {
          path: PROJECTS_URL,
          loadChildren: () =>
            import('./modules/projects-shell.module').then(
              esModule => esModule.ProjectShellModule
            )
        },
        {
          path: HELP_URL,
          loadChildren: () =>
            import('./modules/faq-shell.module').then(esModule => esModule.FaqShellModule)
        },
        {
          path: PAGES_URL,
          loadChildren: () =>
            import('./modules/pages-shell.module').then(
              esModule => esModule.PageShellModule
            )
        },
        {
          path: COUNTRIES_URL,
          loadChildren: () =>
            import('./modules/countries-shell.module').then(
              esModule => esModule.CountriesShellModule
            )
        },
        {
          path: USERS_URL,
          loadChildren: () =>
            import('./modules/users-shell.module').then(
              esModule => esModule.UsersShellModule
            )
        },
        {
          path: LOCAL_INFO_URL,
          loadChildren: () =>
            import('./modules/local-info-shell.module').then(
              esModule => esModule.LocalInfoShellModule
            )
        },
        {
          path: BLOCKS_URL,
          loadChildren: () =>
            import('./modules/blocks-shell.module').then(
              esModule => esModule.BlocksShellModule
            )
        },
        {
          path: BIDS_URL,
          canActivate: [ExternalUrlGuard],
          component: ExternalUrlGuard,
          data: {
            externalUrl: 'auctions/bids'
          }
        },
        {
          path: TRANSLATOR_URL,
          canActivate: [ExternalUrlGuard],
          component: ExternalUrlGuard,
          data: {
            externalUrl: 'auctions/translations'
          }
        },
        {
          path: '**',
          redirectTo: ''
        }
      ],
      { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload' }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
