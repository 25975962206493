import { AbstractControl, ValidatorFn } from '@angular/forms';
import { foldW } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';

import { UserPassword } from '../../domain';

export const userPasswordValidator = (): ValidatorFn => {
  return (control: AbstractControl) => {
    const value = control.value as string;

    return pipe(
      value,
      password => UserPassword.create({ password }),
      foldW(
        e => ({
          userPassword: true,
          message: e.message
        }),
        () => null
      )
    );
  };
};
