import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { Nullable } from '@carvector/shared/util-type-fest';

import { MenuItem } from '../../navigation/navigation.component';

@Component({
  selector: 'carvector-header-user-button',
  templateUrl: './header-user-button.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderUserButtonComponent {
  constructor(private router: Router) {}

  @Input()
  userInfo: Nullable<{ username: string; userId: number }> = null;
  items: MenuItem[] = [
    {
      label: 'Выйти',
      routerLink: '/logout'
    }
  ];

  navigate(): void {
    void this.router.navigate(['/user']);
  }
}
