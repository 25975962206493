<div class="layout">
  <carvector-header
    [enableSidebarToggle]="
      (isScreenSmall$ | async) === true && (isUserAuth$ | async) === true
    "
    [menu]="menu"
    [userInfo]="userInfo$ | async"
    (toggleSidenav)="toggleSidenav()"
  ></carvector-header>

  <p-sidebar
    *ngIf="(isScreenSmall$ | async) && (isUserAuth$ | async)"
    [(visible)]="isSidebarVisible"
    [baseZIndex]="10000"
    styleClass="layout__sidebar"
    position="left"
  >
    <carvector-navigation [menu]="menu"></carvector-navigation>
  </p-sidebar>

  <main class="content">
    <section class="content__inner" [class.bids]="(title$ | async) === 'BIDS'">
      <h1 *ngIf="title$ | async as title">{{ title }}</h1>

      <ng-content></ng-content>
    </section>
  </main>
</div>
