import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LogoutFacade } from '../../application';

import { LogoutPageComponent } from './logout-page.component';

@NgModule({
  declarations: [LogoutPageComponent],
  imports: [CommonModule],
  providers: [LogoutFacade]
})
export class LogoutPageModule {}
