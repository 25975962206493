import { AbstractControl, ValidatorFn } from '@angular/forms';
import { foldW } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';

import { UserName } from '../../domain';

export const userNameValidator = (): ValidatorFn => {
  return (control: AbstractControl) => {
    const value = control.value as string;

    return pipe(
      value,
      name => UserName.create({ name }),
      foldW(
        e => ({
          userName: true,
          message: e.message
        }),
        () => null
      )
    );
  };
};
