import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map as eMap } from 'fp-ts/Either';
import { matchW as bMatchW } from 'fp-ts/boolean';
import { pipe as fPipe } from 'fp-ts/function';
import { map } from 'rxjs/operators';

import { againstNullOrUndefined } from '@carvector/shared/util-guards';
import { notEmpty } from '@carvector/shared/util-strings';

import { UserQuery, UserService } from './+state/user';
import { REDIRECT_PARAM } from './guards';
import { userNameValidator, userPasswordValidator } from './validators';

@Injectable()
export class LoginFacade {
  private error$ = this.userQuery.error$;

  public errorMessage$ = this.error$.pipe(
    map(error => (againstNullOrUndefined(error) ? error.message : ''))
  );

  constructor(
    private userQuery: UserQuery,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) {}

  public login(login: string, password: string) {
    this.userService.login({ login, password }).subscribe(result =>
      fPipe(
        result,
        eMap(() =>
          fPipe(
            this.activatedRoute.snapshot.queryParams[REDIRECT_PARAM],
            notEmpty,
            bMatchW(
              () => window.location.replace('/'),
              () =>
                window.location.replace(
                  this.activatedRoute.snapshot.queryParams[REDIRECT_PARAM]
                )
            )
          )
        )
      )
    );
  }

  public userNameValidator() {
    return userNameValidator();
  }

  public userPasswordValidator() {
    return userPasswordValidator();
  }
}
