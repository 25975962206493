<div class="p-grid p-col-6 p-m-auto">
  <form class="p-col-12" [formGroup]="loginForm">
    <p-card class="p-col-12">
      <i class="pi pi-user"></i>

      <div class="p-fluid">
        <div class="p-field">
          <span class="p-float-label">
            <input pInputText id="login" type="text" formControlName="name" />
            <label for="login">Логин</label>
          </span>

          <small *ngIf="userNameError" class="p-error">{{ userNameError }}</small>
        </div>

        <div class="p-field">
          <span class="p-float-label">
            <input pPassword id="password" type="password" formControlName="password" />
            <label for="password">Пароль</label>
          </span>

          <small *ngIf="userPasswordError" class="p-error">{{ userPasswordError }}</small>
        </div>
      </div>

      <button
        pButton
        pRipple
        class="p-d-block p-col-6 p-m-auto"
        type="submit"
        label="Логин"
        [disabled]="!loginForm.valid"
        (click)="login()"
      ></button>

      <p-message
        *ngIf="errorMessage$ | async"
        styleClass="p-col-12 p-mt-3"
        severity="error"
        [text]="(errorMessage$ | async) || ''"
      ></p-message>
    </p-card>
  </form>
</div>
