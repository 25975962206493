import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { PanelMenuModule } from 'primeng/panelmenu';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ToolbarModule } from 'primeng/toolbar';

import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { HeaderUserButtonComponent } from './header/header-user-button/header-user-button.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';
import { NavigationComponent } from './navigation/navigation.component';
import { PopoverModule } from './popover/popover.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BrowserAnimationsModule,
    RippleModule,
    SidebarModule,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    PanelMenuModule,
    PopoverModule
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    NavigationComponent,
    HeaderMenuComponent,
    HeaderUserButtonComponent
  ],
  exports: [LayoutComponent]
})
export class LayoutModule {}
