import { left, right } from 'fp-ts/Either';

import { BaseError, ValueObject } from '@carvector/shared/seedwork';
import { againstAtLeast, notEmpty } from '@carvector/shared/util-strings';

export class UserPasswordValidationError extends BaseError {
  public tag: 'UserPasswordValidationError';

  private constructor(message: string) {
    super(message);

    this.tag = 'UserPasswordValidationError';
  }

  public static of(message: string): UserPasswordValidationError {
    return new UserPasswordValidationError(message);
  }
}

export type UserPasswordProps = {
  password: string;
};

export class UserPassword extends ValueObject<UserPasswordProps> {
  public static minLength = 6;

  get value() {
    return this.props.password;
  }

  private constructor(props: UserPasswordProps) {
    super(props);
  }

  public static create(props: UserPasswordProps) {
    if (
      !notEmpty(props.password) ||
      !againstAtLeast(props.password.trim(), this.minLength)
    ) {
      return left(
        UserPasswordValidationError.of(
          `Пароль пользователя должно быть не менее ${this.minLength} символов`
        )
      );
    }

    return right(new UserPassword(props));
  }
}
