import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  }
};

@NgModule({
  imports: [],
  exports: [],
  providers: []
})
export class SharedCarvectorApiModule {
  static forRoot(uri: string, connectToDevTools?: boolean) {
    return {
      ngModule: SharedCarvectorApiModule,
      providers: [
        {
          provide: APOLLO_OPTIONS,
          useFactory: (httpLink: HttpLink) => {
            return {
              connectToDevTools,
              cache: new InMemoryCache(),
              link: httpLink.create({ uri, useMultipart: true }),
              defaultOptions
            };
          },
          deps: [HttpLink]
        }
      ]
    };
  }
}
