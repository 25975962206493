<div *ngFor="let navigatorMenu of menu">
  <ul
    class="header-menu-list"
    *ngIf="!isTranslator || navigatorMenu === menu[menu.length - 1]"
  >
    <carvector-popover #navigatorPopover>
      <li carvectorPopoverTrigger>
        <a (click)="navigate(navigatorMenu)">
          {{ navigatorMenu?.label }}
        </a>
      </li>
      <ng-container *ngIf="navigatorMenu.items?.length">
        <div class="carvector-popover" *carvectorPopoverContent>
          <div
            class="carvector-popover-content-list"
            *ngFor="let menu of navigatorMenu.items"
          >
            <carvector-popover #menuPopover>
              <div carvectorPopoverTrigger class="carvector-popover-content-trigger">
                <a (click)="navigate(menu, navigatorPopover)">{{ menu.label }}</a>
                <div *ngIf="menu?.items">
                  <svg
                    width="8"
                    height="12"
                    viewBox="0 0 8 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.54"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.599976 1.4L1.99998 0L7.99998 6L1.99998 12L0.599976 10.6L5.19998 6L0.599976 1.4Z"
                      fill="black"
                    />
                  </svg>
                  <div class="carvector-popover right-popover" *carvectorPopoverContent>
                    <div
                      class="carvector-popover-content-list"
                      *ngFor="let submenu of menu?.items"
                    >
                      <a (click)="navigate(submenu, navigatorPopover)">{{
                        submenu?.label
                      }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </carvector-popover>
          </div>
        </div>
      </ng-container>
    </carvector-popover>
  </ul>
</div>
