import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { UserQuery } from './+state/user';

@Injectable({ providedIn: 'root' })
export class UserSettingsFacade {
  private context$ = this.userQuery.context$;

  public userId$ = this.context$.pipe(map(context => context?.userId ?? 0));

  public allowedActions$ = this.context$.pipe(
    map(context => context?.allowActions ?? [])
  );

  constructor(private userQuery: UserQuery) {}
}
